import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Accueil from './Accueil';
import reportWebVitals from './reportWebVitals';
import { Routes, Route } from "react-router-dom";
import Signup from "./Signup";
import Login from "./Login";
import Passrecovery from "./Passrecovery";
import { BrowserRouter as Router } from "react-router-dom";
import Contact from "./Contact";
import Documentation from "./Documentation";
import Homedash from "./Dashboard/Homedash";
import Updateplan from './Dashboard/Updateplan';
import Sendsms from './Dashboard/Sendsms';
import ReSendsms from './Dashboard/ReSendsms';
import ReSendsmscampagne from './Dashboard/ReSendsmscampagne';
import Campagnesms from './Dashboard/Campagnesms';
import Mescontacts from './Dashboard/Mescontacts';
import Activityhistory from './Dashboard/Activityhistory';
import Notreboutique from './Dashboard/Notreboutique';
import Historiquesms from './Dashboard/Historiquesms';
import Historiquecampagnesms from "./Dashboard/Historiquecampagnesms";
import Profil from './Dashboard/Profil';
import Notifications from './Dashboard/Notifications';
import Statistiques from './Dashboard/Statistiques';
import Integrationwoo from './Dashboard/Integrationwoo';
import Integrationapi from "./Dashboard/Integrationapi";
import Affiliation from "./Dashboard/Affiliation";
import Invoicesms from './Dashboard/Invoicesms';
import ValidationCode from './ValidationCode';
import Commandes from './Dashboard/Commandes';
import ConfidCooky from './ConfidCooky';
import ConditionsGeneralesVentes from './ConditionsGeneralesVentes';
import Panier from './Dashboard/Panier';
import Panier1 from "./Dashboard/Panier1";
import ContactSupport from './Dashboard/ContactSupport';
import Endpoint from './Dashboard/Endpoint';
import Admindash from './Dashboard/Admindash';
import Utilisateurs from './Dashboard/Utilisateurs';
import Allpayments from './Dashboard/Allpayments';
import Userupdate from './Dashboard/Userupdate';
import Paymentlink from './Dashboard/Paymentlink';
import Totalcampagnes from './Dashboard/Totalcampagnes';
import Allsendersms from './Dashboard/Allsendersms';
import Promosms from './Promosms';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <div>
        <section>
          <Routes>
            {" "}
            <Route path="/" element={<Accueil />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Signup" element={<Signup />} />
            <Route path="/Passrecovery" element={<Passrecovery />} />
            <Route path="/ConfidCooky" element={<ConfidCooky />} />
            <Route path="/Promosms" element={<Promosms />} />
            <Route
              path="/ConditionsGeneralesVentes"
              element={<ConditionsGeneralesVentes />}
            />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Validationcode" element={<ValidationCode />} />
            <Route path="/Documentation" element={<Documentation />} />
            <Route path="/dashboard/Homedash" element={<Homedash />} />
            <Route path="/dashboard/Updateplan" element={<Updateplan />} />
            <Route path="/dashboard/Sendsms" element={<Sendsms />} />
            <Route path="/dashboard/ReSendsms" element={<ReSendsms />} />
            <Route path="/dashboard/Campagnesms" element={<Campagnesms />} />
            <Route
              path="/dashboard/ContactSupport"
              element={<ContactSupport />}
            />
            <Route path="/dashboard/Endpoint" element={<Endpoint />} />
            <Route
              path="/dashboard/ReSendsmscampagne"
              element={<ReSendsmscampagne />}
            />
            <Route path="/dashboard/Mescontacts" element={<Mescontacts />} />
            <Route path="/dashboard/Mescontacts" element={<Mescontacts />} />
            <Route path="/dashboard/Commandes" element={<Commandes />} />
            <Route
              path="/dashboard/Notreboutique"
              element={<Notreboutique />}
            />
            <Route
              path="/dashboard/Historiquesms"
              element={<Historiquesms />}
            />
            <Route
              path="/dashboard/Historiquecampagnesms"
              element={<Historiquecampagnesms />}
            />
            <Route path="/dashboard/Profil" element={<Profil />} />
            <Route
              path="/dashboard/Notifications"
              element={<Notifications />}
            />
            <Route path="/dashboard/Statistiques" element={<Statistiques />} />
            <Route
              path="/dashboard/Integrationwoo"
              element={<Integrationwoo />}
            />
            <Route
              path="/dashboard/Integrationapi"
              element={<Integrationapi />}
            />
            <Route path="/dashboard/Affiliation" element={<Affiliation />} />
            <Route path="/dashboard/Invoicesms" element={<Invoicesms />} />
            <Route
              path="/dashboard/Activityhistory"
              element={<Activityhistory />}
            />
            <Route path="/dashboard/Panier" element={<Panier />} />
            <Route path="/dashboard/Panier1" element={<Panier1 />} />
            <Route path="/dashboard/Admindash" element={<Admindash />} />
            <Route path="/dashboard/Utilisateurs" element={<Utilisateurs />} />
            <Route path="/dashboard/Allpayments" element={<Allpayments />} />
            <Route path="/dashboard/Userupdate" element={<Userupdate />} />
            <Route path="/dashboard/Paymentlink" element={<Paymentlink />} />
            <Route
              path="/dashboard/Totalcampagnes"
              element={<Totalcampagnes />}
            />
            <Route path="/dashboard/Allsendersms" element={<Allsendersms />} />
          </Routes>
        </section>
      </div>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

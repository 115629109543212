import axios from "axios";

const NewCommandeEmail = async (email, object, message) => {
  try {
    // const axios = require("axios");
    let data = JSON.stringify({
      email: email,
      subject: object,
      body: message,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.React_App_base_url_Email,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        //  console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        //  console.log(error);
      });
  } catch (error) {
    // console.error("Failed to send email. Error: ", error);
    //  console.log(error);
  }
};

export default NewCommandeEmail;

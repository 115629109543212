import Dasheader from "./Dasheader";
import Dashfooter from "./Dashfooter";
import Sidebar from "./Sibebar";
import axios from "axios";
import { useEffect, useState } from "react";
import Papa from "papaparse";

function Campagnesms() {
  const [errormsg, setErrormsg] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));

  const [formData, setFormData] = useState({
    senderT: "",
    sendeDate: "",
    message: "",
  });
  const { senderT, sendeDate, message, file } = formData;
  const [texteSender, setTexteSender] = useState("");
  const [dataFile, setDataFile] = useState("");
  const [totalCreditSms, setTotalCreditSms] = useState("");

  // Perform operation and then set loading to false
  function handleClick() {
    setLoading(true);
    // Perform operation and then set loading to false
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));

    // console.log(formData, texteSender);
    function NumberField() {
      if (e.target.value === "Autre") {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
    NumberField();
  };

  const GetDataCsv = (DataCsv) => {
    const lines = DataCsv.match(/[^\s]+/g);

    // console.log(lines);
    const numbers = lines.map((line) => line.trim());
    let CreditSms = numbers.length * 40 - 40;
    setTotalCreditSms(CreditSms);

    return numbers;
  };
  //Methode to read .Csv
  const changeHandler = (e) => {
    //console.log(event.target.files[0]);
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    // Papa.parse(event.target.files[0], {
    //   header: true,
    //   skipEmptyLines: true,
    //   complete: function (results) {
    //     console.log(results.data);
    //     setDataFile(results.data);
    //   },
    // });
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const contents = event.target?.result; //Controle null data
      if (contents) {
        const fileNumbers = GetDataCsv(contents);
        const allNumbers = fileNumbers.join(";");
        setDataFile(allNumbers);
        // console.log(dataFile);
      }
    };
    reader.readAsText(file);
    // setFileSelected(true);
  };

  async function onSubmit(e) {
    e.preventDefault();
    handleClick();
    const _data = JSON.stringify(dataFile);

    // console.log(formData, texteSender, dataFile);

    if (formData.senderT === "") {
      setErrormsg("Veuillez choisir le texte d'envoi svp !");
      return null;
    }

    if (formData.message === "") {
      setErrormsg("Veuillez saisir le texte du message svp !");
      return null;
    }
    if (senderT && senderT === "") {
      setErrormsg("Veuillez saisir le texte du message svp !");
      return null;
    }

    if (dataFile === "") {
      setErrormsg(
        "Veuillez charger le ficher csv pour importer les numéros des destinataires !"
      );
      return null;
    }

    if (sendeDate === "") {
      setErrormsg("Veuillez choisir la date et l'heure d'envoi !");
      return null;
    }

    if (message === "") {
      setErrormsg("Veuillez saisir votre message !");
      return null;
    }

    if (
      formData.sendeDate !== "" &&
      formData.dataFile !== "" &&
      formData.message !== ""
    ) {
      const senderTextValue = [];

      if (texteSender === "") {
        senderTextValue.push(senderT);
      }
      if (texteSender !== "") {
        senderTextValue.push(texteSender);
      }

      // console.log(formData, dataFile, texteSender);
      axios
        .get(process.env.React_App_base_url + `users/` + userID, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticated}`,
          },
        })
        .then(async (res) => {
          //console.log(res);
          const first = res.data.data.firstname;
          const last = res.data.data.lastname;
          const email = res.data.data.email;
          const keyOn = res.data.data.userkey;
          const Credisms = res.data.data.cauris;
          const FreeCauris = res.data.data.freecauris;

          const Créditsms =
            parseInt(Credisms) + parseInt(FreeCauris) - totalCreditSms;

          //  console.log(Credisms,parseInt(FreeCauris),parseInt(Credisms), Créditsms);
          if (Créditsms > 0) {
            var paymentData = JSON.stringify({
              phoneto: dataFile,
              senderkey: keyOn,
              outemail: email,
              cauris: totalCreditSms,
              message: message,
              campagnetitle: senderTextValue[0],
              programDate: sendeDate,
              createdDate: new Date().toLocaleString(),
            });
            // console.log(paymentData);
            await axios
              .post(
                process.env.React_App_base_url + `campagnesms/`,
                paymentData,
                {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": true,
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${isAuthenticated}`,
                  },
                }
              )
              .then((res) => {
                // console.log(res);

                const success = res.data.message;
                if (success === "Campagne created successfully") {
                  let NewCauris = "";

                  if (parseInt(Credisms) >= totalCreditSms) {
                    NewCauris = parseInt(Credisms) - totalCreditSms;
                    //  NewCauris.push(New2, Credisms);
                  }
                  // console.log(NewCauris);

                  const data = JSON.stringify({
                    cauris: NewCauris,
                  });
                  let userID = atob(localStorage.getItem("isLoggedIn"));
                  axios
                    .patch(
                      process.env.React_App_base_url + `users/` + userID,
                      data,
                      {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Access-Control-Allow-Credentials": true,
                          "Content-Type": "application/json",
                          Authorization: `Bearer ${isAuthenticated}`,
                        },
                      }
                    )
                    .then((res) => {
                      // console.log(res);
                      setMsg(
                        "Votre campagne a été soumis pour envoi avec succès ! 👌 ! "
                      );
                    });
                }
              });
          } else {
            setErrormsg(
              "Votre crédit sms est insuffisant. Veuillez créditer votre Compte pour continuer 🔔!"
            );
            return null;
          }
        });
      // alert("Yes !");
    }
  }

  return (
    <>
      <Dasheader />

      <div className="bg-yellow-400 pt-2 -mt-8 mb-0">
        <Sidebar />
        <body class="font-mono bg-gay-100">
          <div class="container mx-auto">
            <div class="flex justify-center px-2 my-8 ">
              <div class="w-full xl:w-3/4 lg:w-11/12 flex -mt-6 mb-2">
                <div class="w-full h-auto bg-gray-400  hidden lg:block lg:w-5/12 bg-cover rounded-l-lg">
                  <img
                    className=" inset-0 h-full w-full object-cover"
                    src=".././profil.webp"
                    alt=""
                  />
                </div>

                <div class="w-full lg:w-5/12 bg-white p-5 rounded-lg lg:rounded-l-none">
                  <img
                    class="w-auto h-16 sm:h-16  mx-auto"
                    src=".././5logo2.png"
                    loading="lazy"
                    width="202"
                    height="40"
                  />
                  <p className="text-center text-2xl">
                    {" "}
                    FAIRE UNE CAMPAGNE SMS
                  </p>
                  <div class="max-w-xl mx-auto mt-8 flex w-full flex-col border rounded-lg bg-white p-8">
                    <h2 class="title-font mb-1 text-lg font-medium text-gray-900">
                      SMS MARKETING
                    </h2>
                    <p class="mb-5 leading-relaxed text-gray-600">
                      Exploitez le plein potentiel des SMS MARKETING
                    </p>
                    <p class="mb-5 leading-relaxed text-gray-600 italic text-justify text-xs">
                      NB: Dans le fichier csv, il doit y avoir une seule colonne
                      avec un numéro par ligne plus l'indicatif du pays |
                      <a href=".././Modèle.csv" className="text-blue-400">
                        {" "}
                        Voir le modèle de Csv
                      </a>
                    </p>
                    <p className="text-green-600 p-2 text-center">{msg}</p>
                    <p class="text-center p-2 text-red-700">{errormsg} </p>
                    <div class="mb-4">
                      <label
                        for="title"
                        class="text-sm leading-7 text-gray-600"
                      >
                        Rappel échéance paiement de mai...
                      </label>
                      <select
                        type="text"
                        id="senderT"
                        name="senderT"
                        value={senderT}
                        onChange={onChange}
                        class="w-full rounded border border-gray-300 bg-white py-1 px-3 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
                      >
                        <option selected>
                          Sélectionnez le type de message
                        </option>
                        <option value="Note de classe">Note !!!</option>
                        <option value="Rappel">Rappel</option>
                        {/* <option value="Confirmation">Confirmation</option> */}
                        <option value="Infos">Infos</option>
                        <option value="Promo">Promo !!!</option>
                        <option value="Anniv !!!">Anniv !!!</option>
                        <option value="Urgent">Urgent !!!</option>
                        <option value="Evenement">Event !!!</option>
                        <option value="Surprise">Surprise !!!</option>
                        <option value="Amour">Je t'aime </option>
                        <option value="Autre">Autre</option>
                      </select>
                    </div>{" "}
                    {isVisible ? (
                      <div id="box" class="col-span-2 mt-2 sm:col-span-1">
                        <label
                          for="card-holder"
                          class="block text-sm font-medium text-gray-700 mb-2"
                        >
                          Votre texte d'envoi peronsonnalisé ici !
                        </label>
                        <input
                          type="text"
                          onChange={(e) => {
                            setTexteSender(e.target.value);
                          }}
                          maxLength={12}
                          placeholder="Invitation"
                          class="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                        />
                      </div>
                    ) : null}
                    <div class="mb-4">
                      <label
                        for="title"
                        class="text-sm leading-7 text-gray-600"
                      >
                        Date et Heure d'envoi
                      </label>
                      <input
                        id="sendeDate"
                        name="sendeDate"
                        onChange={onChange}
                        value={sendeDate}
                        type="datetime-local"
                        class="w-full rounded border border-gray-300 bg-white py-1 px-3 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
                      />
                    </div>{" "}
                    <div class="mb-4">
                      <label
                        for="email"
                        class="text-sm leading-7 text-gray-600"
                      >
                        Charger le fichier Csv
                      </label>
                      <input
                        type="file"
                        name="file"
                        accept=".csv"
                        onChange={changeHandler}
                        class="w-full rounded border border-gray-300 bg-white py-1 px-3 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
                      />
                    </div>
                    <div class="mb-4">
                      <label
                        for="message"
                        class="text-sm leading-7 text-gray-600"
                      >
                        Message
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        value={message}
                        onChange={onChange}
                        maxlength="150"
                        class="h-32 w-full resize-none rounded border border-gray-300 bg-white py-1 px-3 text-base leading-6 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
                      ></textarea>
                    </div>
                    <button
                      onClick={onSubmit}
                      enable={loading}
                      class="rounded border-0 bg-indigo-500 py-2 px-6 text-lg text-white hover:bg-indigo-600 focus:outline-none"
                    >
                      {" "}
                      {loading ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 animate-spin mx-auto"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                          />
                        </svg>
                      ) : (
                        "Confirmer l'envoi "
                      )}
                    </button>
                    <p class="mt-3 text-xs ">
                      150 caractères au maximum pour le message et une liste de
                      tél. illimité de diffusion
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </body>
      </div>

      <Dashfooter />
    </>
  );
}

export default Campagnesms;

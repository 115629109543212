import Dasheader from "./Dasheader";
import Dashfooter from "./Dashfooter";
import Sidebar from "./Sibebar";
import axios from "axios";
import { useEffect, useState } from "react";
import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

function Utilisateurs() {
  const [userKEY, setUerKEY] = useState("");
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  //JSdataTable
  $(document).ready(function () {
    setTimeout(function () {
      $("#example").DataTable({
        paging: true,
        searching: true,
        retrieve: true,
        language: {
          processing: "Traitement en cours...",
          search: "Recherche&nbsp;:",
          lengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
          info: "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
          infoEmpty:
            "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
          infoFiltered:
            "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
          infoPostFix: "",
          loadingRecords: "Chargement en cours...",
          zeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
          emptyTable: "Aucune donnée disponible dans le tableau",
          paginate: {
            first: "Premier",
            previous: "Pr&eacute;c&eacute;dent",
            next: "Suivant",
            last: "Dernier",
          },
        },
      });
    }, 1000);
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(process.env.React_App_base_url + `users/` ,{
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          "Authorization": `Bearer ${isAuthenticated}`,
        },
      })
      .then((res) => {
        //  console.log(res.data.users);       
        const UtData = res.data.users;       
               
        setUserData(res.data.users);
        
      })
      
    
       
  }, []);

  return (
    <>
      <Dasheader />
      <div class="p-4 bg-yellow-400 mb-16 text-white -mt-8">
        {/* <Sidebar /> */}
        <h1 class="text-4xl mt-24 text-center font-semibold mb-2">
          LISTES DES UTILISATEURS
        </h1>
        <div class="w-10/12 mx-auto mb-64">
          <div className="bg-gray-900">
            <div className="mx-auto max-w-7xl">
              <div className="bg-gray-900 py-10">
                <div className="px-4 sm:px-6 lg:px-8">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-base font-semibold leading-6 text-white">
                        Les comptes actifs
                      </h1>
                      <p className="mt-2 text-sm text-gray-300">
                        La liste des achats effectués
                      </p>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                      <a
                        href="./admindash"
                        type="button"
                        className="block rounded-md bg-indigo-500 px-3 py-2 text-center text-sm font-semibold text-white hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                      >
                        ↲ Retour
                      </a>
                    </div>
                  </div>
                  <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table
                          id="example"
                          className="min-w-full divide-y divide-gray-700 text-white"
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                              >
                                Nom et prénom(s)
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                              >
                                Email
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                              >
                                Tél.
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                              >
                                Credisms
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                              >
                                Commission
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                              >
                                Date de création
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-800">
                            {userData.map((userData) => (
                              <tr key={userData.email}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                  {userData.lastname +
                                    " " +
                                    " " +
                                    userData.firstname}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                  {userData.email}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                  {userData.phone}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                  {userData.cauris}
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                  {userData.commissionsponsor}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                  {userData.createdDate}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                  <a
                                    href={`./userupdate/?regex=${userData.id}`}
                                    className="text-yellow-400 hover:text-gray-100"
                                  >
                                    Editer →
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dashfooter />
    </>
  );
}

export default Utilisateurs;

import Dasheader from "./Dasheader";
import Dashfooter from "./Dashfooter";
import Sidebar from "./Sibebar";
import axios from "axios";
import { useEffect, useState } from "react";
import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link, useNavigate, useLocation } from "react-router-dom";

function Allsendersms() {
  const [msgData, setMsgData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userKEY, setUerKEY] = useState("");
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const navigate = useNavigate();
  //JSdataTable
  $(document).ready(function () {
    setTimeout(function () {
      $("#example").DataTable({
        paging: true,
        searching: true,
        retrieve: true,
        language: {
          processing: "Traitement en cours...",
          search: "Recherche&nbsp;:",
          lengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
          info: "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
          infoEmpty:
            "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
          infoFiltered:
            "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
          infoPostFix: "",
          loadingRecords: "Chargement en cours...",
          zeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
          emptyTable: "Aucune donnée disponible dans le tableau",
          paginate: {
            first: "Premier",
            previous: "Pr&eacute;c&eacute;dent",
            next: "Suivant",
            last: "Dernier",
          },
        },
      });
    }, 1000);
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(process.env.React_App_base_url + `users/` + userID, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${isAuthenticated}`,
        },
      })
      .then(async (res) => {
        //console.log(res);
        const first = res.data.data.firstname;
        const last = res.data.data.lastname;
        const email = res.data.data.email;
        const keyOn = res.data.data.userkey;
        const Credisms = res.data.data.cauris;
        const FreeCauris = res.data.data.freecauris;
        setUerKEY(email);

        setTimeout(() => {
          if (res.data.data.role !== "admin") {
            localStorage.clear();
            navigate("../login");
          }
        }, 500);

        // console.log("key", email);
        axios
          .get(process.env.React_App_base_url + `sendersms/`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              Authorization: `Bearer ${isAuthenticated}`,
            },
          })
          .then((res) => {
            const smsData = res.data.sms;
            //  DataFilter;

            // const filteredSms = smsData.filter(
            //   (sms) => sms["outemail"] === email || sms["senderkey"] === keyOn
            // );
            setMsgData(res.data.sms);

            //console.log(filteredCountries, smsData);
            //  console.log(filtered);
          })
          .finally(() => {
            setLoading(false);
          });
      });
  }, []);

  return (
    <>
      <Dasheader />

      <div className="bg-yellow-400  pt-2 -mt-8 text-white">
        <Sidebar />
        <div class="p-4 mt-16 mb-80 ">
          <h1 class="flex mx-auto justify-center text-4xl text-center font-semibold mb-6">
            Historiques des sms express{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7c1.3 3 4.1 4.8 7.3 4.8c66.3 0 116-31.8 140.6-51.4c32.7 12.3 69 19.4 107.4 19.4c141.4 0 256-93.1 256-208S397.4 32 256 32zM128.2 304H116c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h12.3c6 0 10.4-3.5 10.4-6.6c0-1.3-.8-2.7-2.1-3.8l-21.9-18.8c-8.5-7.2-13.3-17.5-13.3-28.1c0-21.3 19-38.6 42.4-38.6H156c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8h-12.3c-6 0-10.4 3.5-10.4 6.6c0 1.3.8 2.7 2.1 3.8l21.9 18.8c8.5 7.2 13.3 17.5 13.3 28.1c.1 21.3-19 38.6-42.4 38.6zm191.8-8c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8v-68.2l-24.8 55.8c-2.9 5.9-11.4 5.9-14.3 0L224 227.8V296c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8V192c0-8.8 7.2-16 16-16h16c6.1 0 11.6 3.4 14.3 8.8l17.7 35.4l17.7-35.4c2.7-5.4 8.3-8.8 14.3-8.8h16c8.8 0 16 7.2 16 16v104zm48.3 8H356c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h12.3c6 0 10.4-3.5 10.4-6.6c0-1.3-.8-2.7-2.1-3.8l-21.9-18.8c-8.5-7.2-13.3-17.5-13.3-28.1c0-21.3 19-38.6 42.4-38.6H396c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8h-12.3c-6 0-10.4 3.5-10.4 6.6c0 1.3.8 2.7 2.1 3.8l21.9 18.8c8.5 7.2 13.3 17.5 13.3 28.1c.1 21.3-18.9 38.6-42.3 38.6z"
              />
            </svg>
          </h1>
          <div class="w-7/12 mx-auto mytable">
            <div className="bg-gray-900 text-white">
              <div className="mx-auto max-w-7xl">
                <div className="bg-gray-900 py-10">
                  <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-white ">
                          Messages envoyés
                        </h1>
                        <p className="mt-2 text-sm text-gray-300">
                          La liste des messages de votre compte
                        </p>
                      </div>
                      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none ">
                        <a
                          href="./totalcampagnes"
                          type="button"
                          className="block rounded-md bg-indigo-500 px-3 py-2 text-center text-sm font-semibold text-white hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                        >
                          Historique des campagnes →
                        </a>
                      </div>
                    </div>
                    <div className="mt-8 flow-root ">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table
                            id="example"
                            className="min-w-full divide-y divide-gray-700 "
                          >
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                                >
                                  Nom du destinataire
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                                >
                                  Title du sms
                                </th>

                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                                >
                                  Numéro du destinataire
                                </th>
                                <th
                                  scope="col"
                                  className="relative py-3.5 pl-3 pr-4 sm:pr-0 text-white"
                                >
                                  Date d'envoi
                                </th>
                                <th
                                  scope="col"
                                  className="relative py-3.5 pl-3 pr-4 sm:pr-0 text-white"
                                >
                                  Status
                                </th>
                                <th
                                  scope="col"
                                  className="relative py-3.5 pl-3 pr-4 sm:pr-0 text-white"
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-800">
                              {msgData.map((msgData) => (
                                <tr key={msgData.outemail}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                    {msgData.fullnameto}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                    {msgData.title}
                                  </td>

                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                    {msgData.phoneto}
                                  </td>
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                    <a
                                      href="#"
                                      className="text-indigo-400 hover:text-indigo-300"
                                    >
                                      {msgData.createdDate}
                                    </a>
                                  </td>
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                    <button className="text-indigo-400 hover:text-indigo-300">
                                      Livré
                                    </button>
                                  </td>
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                    <a
                                      href={`./resendsms/?smsi=${msgData.id}`}
                                      target="_blank"
                                      className="flex text-indigo-400 bg-white rounded-lg p-2 hover:text-indigo-300"
                                    >
                                      Renvoyer{" "}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 48 48"
                                        className="text-indigo-500 "
                                      >
                                        <path
                                          fill="none"
                                          stroke="currentColor"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M40.5 5.697h-33a2 2 0 0 0-2 2v28.68a2 2 0 0 0 2 2h3.656a2.16 2.16 0 0 1 1.527.633l3.293 3.293l3.294-3.293a2.16 2.16 0 0 1 1.527-.633H40.5a2 2 0 0 0 2-2V7.697a2 2 0 0 0-2-2Z"
                                        />
                                        <path
                                          fill="none"
                                          stroke="currentColor"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M19.978 32.712a11.407 11.407 0 0 0 12.086-2.61c4.454-4.455 4.454-11.676 0-16.13a11.58 11.58 0 0 0-.954-.854m-3.088-1.756a11.407 11.407 0 0 0-12.086 2.61c-4.454 4.454-4.454 11.676 0 16.13c.305.305.624.59.954.854"
                                        />
                                        <path
                                          fill="none"
                                          stroke="currentColor"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="m12.77 31.419l4.123-.466l-.466-4.123M35.23 12.655l-4.123.466l.466 4.123m-7.946 7.407v-7.407m5.051 8.997l-5.051-1.59"
                                        />
                                      </svg>
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dashfooter />
      </div>
    </>
  );
}

export default Allsendersms;

import Dasheader from "./Dasheader";
import Dashfooter from "./Dashfooter";
import React, { useState, useEffect } from "react";
import axios from "axios";
// import Sidebar from "./Sibebar";
import { useNavigate } from "react-router-dom";
import Wasender from "./Wasender";
import NewCommandeEmail from './NewCommandeEmail';


function Endpoint() {
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const [ref, setRef] = useState("");
  const [notifications, setNotifications] = useState("");
  const [email_cos, setEmail_cos] = useState("");
  const [userKEY, setUserKEY] = useState("");
  const [isCallBackVisible, setIsCallBackVisible] = useState();
  //  console.log(InvoiceRef);
   const navigate = useNavigate();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const paymentStatusPage = params.get("status");
  const paymentRefPage = params.get("transref");

  let VisaPayment =
    "Customer par carte visa." +
    " " +
    "Code de la transaction est :" +
    " " +
    paymentRefPage +
    ". " +
    " Veuillez faire le checking pour confirmer le paiement et créditer le compte du client. Merci ";

  // console.log(paymentStatusPage, paymentRefPage);


  function DisplayStatus() {
    try {
      if (paymentStatusPage && paymentStatusPage === "SUCCESS") {
        axios
          .get(process.env.React_App_base_url + `users/` + userID, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              Authorization: `Bearer ${isAuthenticated}`,
            },
          })
          .then(async (res) => {
            //  console.log(res);
            const first = res.data.data.firstname;
            const last = res.data.data.lastname;
            const email = res.data.data.email;
            const keyOn = res.data.data.userkey;
            const Credisms = res.data.data.cauris;
            const phone = res.data.data.phone;
            const Sponsor = res.data.data.sponsorkey;
            setEmail_cos(res.data.data.email);
            setUserKEY(res.data.data.userkey);

            if (res) {
              var payID = localStorage.getItem("opr");
              // var Credisms = atob(localStorage.getItem("userCredit"));
              var price = localStorage.getItem("theprice");
              // console.log(payID, price, Credisms);

              var newCauris = [];

              if (parseInt(price) === 140) {
                newCauris.push(parseInt(Credisms) + 40);
              }

              if (parseInt(price) === 7000) {
                newCauris.push(parseInt(Credisms) + 2000);
              }

              if (parseInt(price) === 66500) {
                newCauris.push(parseInt(Credisms) + 20000);
              }
              // console.log(newCauris, newCauris[0]);

              const data = JSON.stringify({
                cauris: newCauris[0],
              });
              // console.log(data);

              axios
                .patch(
                  process.env.React_App_base_url + `users/` + userID,
                  data,
                  {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Credentials": true,
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${isAuthenticated}`,
                    },
                  }
                )
                .then((res) => {
                  var confirmData = JSON.stringify({
                    paymentStatus: "confirmed",
                  });
                  //  console.log(confirmData);
                  //alert("Yes");
                  axios
                    .patch(
                      process.env.React_App_base_url + `payments/` + payID,
                      confirmData,
                      {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Access-Control-Allow-Credentials": true,
                          "Content-Type": "application/json",
                          Authorization: `Bearer ${isAuthenticated}`,
                        },
                      }
                    )
                    .then((res) => {
                      const notifydata = JSON.stringify({
                        notificationtitle: "Votre achat de crédit sms",
                        lienRef: "./invoicesms/?facture=" + payID,
                        useremail: email,
                        refpay: payID,
                        userkey: keyOn,
                        viewstatus: "1",
                        createdDate: new Date().toLocaleString(),
                      });

                      axios
                        .post(
                          process.env.React_App_base_url + `notifications/`,

                          notifydata,
                          {
                            headers: {
                              "Access-Control-Allow-Origin": "*",
                              "Access-Control-Allow-Credentials": true,
                              "Content-Type": "application/json",
                              Authorization: `Bearer ${isAuthenticated}`,
                            },
                          }
                        )
                        .then((res) => {
                          //  console.log(res);
                        });
                    });
                });
                const VendorEmail="lynoshk@yahoo.fr";
                const objet="Nouvelle achat de SMS";
                const message = "Paiement via VisaCard, réf:" + paymentRefPage;

                NewCommandeEmail(
                  VendorEmail,objet
                  ,message);
              Wasender(VisaPayment);
            }
          });

        // setLoading(false);
        // clearInterval(refreshIntervalId);

        // setNotifications();
        setIsCallBackVisible(true);
        // alert("Yes")
        // setIsCallBackVisible((prevState) => !prevState);
        // Wasender(VisaPayment);
      }
    } catch (error) {
      // console.log("Error", error);
      navigate("/");
    }
  }

  useEffect(() => {
    if (document.write) {
      DisplayStatus();
    }
  }, []);

  return (
    <>
      <Dasheader />
      <div className="bg-yellow-400 -mt-8">
        <body>
          <div class="flex items-center justify-center h-screen -mb-8">
            <div>
              {isCallBackVisible ? (
                <div class="flex flex-col items-center -mt-24  space-y-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-green-600 w-28 h-28"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <h1 class="text-4xl font-bold">
                    Merci pour la confirmation de votre paiement !
                  </h1>
                  <p>
                    Votre compte sera mise à jour avec un nouveau solde de
                    crédit sms ! Vous pouvez revenir dans votre tableau de bord
                    en cliquant sur le bouton ci-dessous. Nous vous souhaitons
                    une excellente journée !
                  </p>
                  <p>Nous vous souhaitons une excellente journée 👋 !</p>
                  <a
                    href="../dashboard/homedash"
                    class="inline-flex items-center px-4 py-2 text-white bg-indigo-600 border border-indigo-600 rounded rounded-full hover:bg-indigo-700 focus:outline-none focus:ring"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-3 h-3 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M7 16l-4-4m0 0l4-4m-4 4h18"
                      />
                    </svg>
                    <span class="text-sm font-medium">Tableau de bord</span>
                  </a>
                </div>
              ) : (
                <div class="flex flex-col items-center -mt-24  mb-24 space-y-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-28 h-28 mr-2"
                    viewBox="0 0 2048 2048"
                  >
                    <path
                      fill="red"
                      d="M1024 0q141 0 272 36t244 104t207 160t161 207t103 245t37 272q0 141-36 272t-104 244t-160 207t-207 161t-245 103t-272 37q-141 0-272-36t-244-104t-207-160t-161-207t-103-245t-37-272q0-141 36-272t104-244t160-207t207-161T752 37t272-37zm0 1920q124 0 238-32t214-90t181-140t140-181t91-214t32-239q0-124-32-238t-90-214t-140-181t-181-140t-214-91t-239-32q-124 0-238 32t-214 90t-181 140t-140 181t-91 214t-32 239q0 124 32 238t90 214t140 181t181 140t214 91t239 32zm443-1249l-352 353l352 353l-90 90l-353-352l-353 352l-90-90l352-353l-352-353l90-90l353 352l353-352l90 90z"
                    />
                  </svg>
                  <h1 class="text-4xl font-bold">Votre paiement a échoué !</h1>
                  <p>
                    Veuillez vérifier le solde de votre compte Mobile ou les
                    informations de votre carte VISA et réessayer !
                  </p>
                  <p>Nous vous souhaitons une excellente journée 👋 !</p>
                  <a
                    href="../dashboard/homedash"
                    class="inline-flex items-center px-4 py-2 text-white bg-indigo-600 border border-indigo-600 rounded rounded-full hover:bg-indigo-700 focus:outline-none focus:ring"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-3 h-3 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M7 16l-4-4m0 0l4-4m-4 4h18"
                      />
                    </svg>
                    <span class="text-sm font-medium">Tableau de bord</span>
                  </a>
                </div>
              )}
            </div>
          </div>
        </body>
      </div>
      <Dashfooter />
    </>
  );
}

export default Endpoint;


import Dasheader from "./Dasheader";
import Dashfooter from "./Dashfooter";
import Sidebar from "./Sibebar";
import Papa from "papaparse";
import React, { useState, useEffect } from "react";
import axios from "axios";


function Mescontacts() {
  const [rows, setRows] = React.useState(null);
   const [file, setFile] = useState();
   const fileReader = new FileReader();

   const handleOnChange = (e) => {
     setFile(e.target.files[0]);
   };

    const handleOnSubmit = (event) => {
      event.preventDefault();
      if (file) {
        fileReader.onload = function (event) {
          const csvOutput = event.target.result;
          // console.log(csvOutput);
          setRows(csvOutput);          
        };
        fileReader.readAsText(file);
      }

    };


  // const changeHandler = (event) => {
  //      console.log(event.target.files[0]);
  //     // Passing file data (event.target.files[0]) to parse using Papa.parse
  //     Papa.parse(event.target.files[0], {
  //       header: true,
  //       skipEmptyLines: true,
  //       complete: function (results) {
  //         console.log(results.data);
  //         setRows(results.data);
  //       },
  //     });
  //   }
  

  return (
    <>
      <Dasheader />

      <div className="bg-yellow-400 pt-2  mb-0 ">
        <Sidebar />

        <div class="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <h2 class="text-2xl font-bold mb-4 text-center mt-12">
            Mes contacts
          </h2>
          <table id="example" class="table-auto mx-auto mb-8 w-lg">
            <thead>
              <tr className="border border-1">
                <th class="px-4 py-2">@</th>
                <th class="px-4 py-2">Nom</th>
                <th class="px-4 py-2">Prénom</th>
                <th class="px-4 py-2">Téléphone</th>
                <th class="px-4 py-2">Pays</th>
                <th class="px-4 py-2">Date d'ajout</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border px-4 py-2">@</td>
                <td class="border px-4 py-2">Herrod Chandler</td>
                <td class="border px-4 py-2">Sales Assistant</td>
                <td class="border px-4 py-2">546757568</td>
                <td class="border px-4 py-2">Bénin</td>
                <td class="border px-4 py-2">01/07/22</td>
              </tr>
              <tr>
                <td class="border px-4 py-2">@</td>
                <td class="border px-4 py-2">Herrod Chandler</td>
                <td class="border px-4 py-2">Sales Assistant</td>
                <td class="border px-4 py-2">343553435443</td>
                <td class="border px-4 py-2">Bénin</td>
                <td class="border px-4 py-2">01/07/22</td>
              </tr>
              <tr>
                <td class="border px-4 py-2">@</td>
                <td class="border px-4 py-2">Ashton Cox</td>
                <td class="border px-4 py-2">Junior Technical Author</td>
                <td class="border px-4 py-2">878909</td>
                <td class="border px-4 py-2">Togo</td>
                <td class="border px-4 py-2">01/07/22</td>
              </tr>
              <tr>
                <td class="border px-4 py-2">@</td>
                <td class="border px-4 py-2">Cedric Kelly</td>
                <td class="border px-4 py-2">Senior Javascript Developer</td>
                <td class="border px-4 py-2">89880709870</td>
                <td class="border px-4 py-2">Niger</td>
                <td class="border px-4 py-2">01/07/22</td>
              </tr>
              <tr>
                <td class="border px-4 py-2">@</td>
                <td class="border px-4 py-2">Airi Satou</td>
                <td class="border px-4 py-2">Accountant</td>
                <td class="border px-4 py-2">8979344355</td>
                <td class="border px-4 py-2">Côte d'ivoire</td>
                <td class="border px-4 py-2">01/07/22</td>
              </tr>
            </tbody>
          </table>
        </div>
        <link
          rel="stylesheet"
          href="https://cdn.datatables.net/1.10.25/css/jquery.dataTables.min.css"
        />
        <script src="https://code.jquery.com/jquery-3.5.1.min.js"></script>
        <script src="https://cdn.datatables.net/1.10.25/js/jquery.dataTables.min.js"></script>
      </div>
      <div className="mx-auto text-center bg-yellow-400 -mb-80">
        <button class="bg-blue-500 hover:bg-blue-700 text-white mt-24  font-bold py-2 px-4 rounded">
          Télécharger .CSV
        </button>
        <div class="p-6">
          <div class="flex flex-wrap items-center w-full max-w-7xl p-5 mx-auto text-left border border-gray-500 rounded lg:flex-nowrap md:p-8 dark:border-gray-700">
            <div class="flex-1 w-full mb-5 ">
              <h3 class="mb-2 text-2xl font-bold text-gray-700 dark:text-gray-200">
                Importer votre liste de diffusion
              </h3>
              <p class="text-gray-500 dark:text-gray-400 ">
                Veuillez respecter le format du Csv avant son chargement.
              </p>
            </div>
            <div class="w-full px-1 flex-0 md:w-auto lg:w-2/3">
              <form>
                <div class="flex flex-col sm:flex-row">
                  <input
                    type={"file"}
                    id={"csvFileInput"}
                    accept={".csv"}
                    onChange={handleOnChange}
                    value=""
                    class="flex-1 px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md sm:mr-5 focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-800 dark:text-white dark:placeholder-gray-400 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                  />
                  <button
                    type="button"
                    onClick={(e) => {
                      handleOnSubmit(e);
                    }}
                    class="inline-block mr-4 rounded bg-yellow-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#e4a11b] transition duration-150 ease-in-out hover:bg-warning-600 hover:shadow-[0_8px_9px_-4px_rgba(228,161,27,0.3),0_4px_18px_0_rgba(228,161,27,0.2)] focus:bg-warning-600 focus:shadow-[0_8px_9px_-4px_rgba(228,161,27,0.3),0_4px_18px_0_rgba(228,161,27,0.2)] focus:outline-none focus:ring-0 active:bg-warning-700 active:shadow-[0_8px_9px_-4px_rgba(228,161,27,0.3),0_4px_18px_0_rgba(228,161,27,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(228,161,27,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(228,161,27,0.2),0_4px_18px_0_rgba(228,161,27,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(228,161,27,0.2),0_4px_18px_0_rgba(228,161,27,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(228,161,27,0.2),0_4px_18px_0_rgba(228,161,27,0.1)]"
                  >
                    Téléverser
                  </button>
                  <button
                    type="button"
                    class="w-full px-6 py-4 mt-5 text-white bg-gray-800 rounded-md sm:mt-0 sm:w-auto whitespace-nowrap dark:bg-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                    >
                      <path
                        fill="currentColor"
                        fill-rule="evenodd"
                        d="M1 9.5A1.5 1.5 0 0 0 2.5 11H4v-1H2.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5V4H5.5A1.5 1.5 0 0 0 4 5.5v7A1.5 1.5 0 0 0 5.5 14h7a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 12.5 4H11V2.5A1.5 1.5 0 0 0 9.5 1h-7A1.5 1.5 0 0 0 1 2.5v7Zm4-4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-7Z"
                        clip-rule="evenodd"
                      />
                    </svg>{" "}
                  </button>
                  {alert}{" "}
                </div>
              </form>
              <div className="text-red-700 p-2">
                Veuiller charger votre fichier Csv à 05 colonnes Nom | Prénom |
                Tél. +33... | Pays
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dashfooter />
    </>
  );
}

export default Mescontacts;

import Footer from "./Footer";
import Header from "./Header";

function ConfidCooky() {
  return (
    <>
      <Header />

      <div class="">
        <div class="container mx-auto px-4 py-8">
          <h1 class="text-3xl font-bold mb-4">Politique de confidentialité</h1>

          <p class="mb-4 text-justify">
            Cette politique de confidentialité définit la manière dont notre
            site web utilise et protège les informations que vous nous
            fournissez lorsque vous utilisez ce site web.
          </p>

          <h2 class="text-2xl font-bold mb-2">I- Informations collectées</h2>

          <p class="mb-4">
            Nous pouvons recueillir les informations suivantes :
          </p>

          <ul class="list-disc list-inside mb-4">
            <li>Votre nom et prénom </li>
            <li>E-mail</li>
            <li>Téléphone</li>
            <li>
              Autres informations relatives aux enquêtes et/ou aux offres
              destinées aux clients
            </li>
          </ul>

          <h2 class="text-2xl font-bold mb-2">
            II- Comment nous utilisons les informations
          </h2>

          <p class="mb-4">
            Nous avons besoin de ces informations pour comprendre vos besoins et
            vous fournir un meilleur service et en particulier pour les raisons
            suivantes :
          </p>

          <ul class="list-disc list-inside mb-4">
            <li>Tenue de registres internes de nos clients</li>
            <li>Améliorer nos produits et services</li>
            <li className="text-justify">
              Envoi de courriels promotionnels sur les nouveaux produits, les
              offres spéciales ou d'autres informations susceptibles de vous
              intéresser
            </li>
            <li className="text-justify">
              De temps à autre, nous pouvons également utiliser vos informations
              pour vous contacter à des fins d'études de marché. Nous pouvons
              vous contacter par courrier électronique, téléphone, ou par
              courrier. Nous pouvons utiliser les informations pour
              personnaliser le site web en fonction de vos intérêts.
            </li>
          </ul>

          <h2 class="text-2xl font-bold mb-2">III- Sécurité</h2>

          <p class="mb-4 text-justify">
            Nous nous engageons à garantir la sécurité de vos informations. Afin
            d'empêcher tout accès ou toute divulgation non autorisés, nous avons
            mis en place des procédures physiques, électroniques et de gestion
            appropriées pour empêcher tout accès ou toute divulgation non
            autorisés.
          </p>

          <h2 class="text-2xl font-bold mb-2">IV- Cookies</h2>

          <p class="mb-4 text-justify">
            Le cookie aide à analyser le trafic sur le web ou vous permet de
            savoir quand vous visitez un site particulier. Les cookies
            permettent aux applications web de vous répondre en tant
            qu'individu. L'application web peut adapter ses opérations à vos
            besoins, à ce que vous aimez et à ce que vous n'aimez pas, en
            recueillant et en mémorisant des informations sur vos préférences.
          </p>

          <p class="mb-4 text-justify">
            D'une manière générale, les cookies nous aident à vous fournir un
            meilleur site web en nous permettant d'identifier les pages que vous
            trouvez utiles et celles qui ne le sont pas. Un cookie ne nous donne
            en aucun cas accès à votre ordinateur ou à des informations vous
            concernant, à l'exception des données que vous choisissez de
            partager avec nous.
            <p className="font-bold">
              NB: Les cookies sur notre site sont automatiquement supprimés à la
              déconnexion de l'utilisateur.
            </p>
          </p>

          <h2 class="text-2xl font-bold mb-2">
            V- Liens vers d'autres sites web
          </h2>

          <p class="mb-4 text-justify">
            Notre site web peut contenir des liens vers d'autres sites web
            intéressants. Cependant, une fois que vous avez utilisé ces liens
            pour quitter notre site, vous devez savoir que nous n'avons aucun
            contrôle sur cet autre site.Par conséquent, nous ne pouvons être
            tenus responsables de la protection et de la confidentialité des
            informations que vous fournissez en visitant ces sites et que ces
            sites ne sont pas régis par la présente politique de
            confidentialité. Vous devez faire preuve de prudence et consulter la
            déclaration de confidentialité applicable au site web en question.
          </p>

          <h2 class="text-2xl font-bold mb-2">
            VI- Contrôle de vos informations personnelles
          </h2>

          <p class="mb-4">
            Vous pouvez choisir de restreindre la collecte ou l'utilisation de
            vos informations personnelles de la manière suivante:
          </p>

          <ul class="list-disc list-inside mb-4 text-justify">
            <li>
              Si vous avez déjà accepté que nous utilisions vos données
              personnelles à des fins de marketing direct, vous pouvez modifier
              votre consentement. vous pouvez changer d'avis à tout moment en
              nous écrivant ou en nous envoyant un courriel à l'adresse suivante 
              <strong> cookies@5sender.com</strong> avec pour objet changer d'avis pour les règles de
              Cookies et de Confidentialité
            </li>
            <li className="text-justify">
              Nous ne vendrons pas, ne distribuerons pas et ne louerons pas vos
              informations personnelles à des tiers, sauf si nous avons votre
              permission ou si nous sommes obligés de le faire ou si la loi nous
              y oblige.
            </li>
            <li className="text-justify">
              Vous pouvez demander des détails sur les informations personnelles
              que nous détenons à votre sujet. Si vous souhaitez obtenir une
              copie des informations détenues à votre sujet, veuillez écrire
            </li>
            <li className="text-justify">
              Si vous pensez que les informations que nous détenons sur vous
              sont incorrectes ou incomplètes, veuillez nous écrire ou nous
              envoyer un courriel dans les plus brefs délais à l'adresse
              cookies@wa.com. Nous corrigerons rapidement toute information
              jugée incorrecte
            </li>
          </ul>

          <p class="mb-4 ">
            Cette politique de confidentialité peut être modifiée sans préavis.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ConfidCooky;

import Header from "./Header";
import Footer from "./Footer";
import { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import InscriptionEmail from "./Dashboard/InscriptionEmail";

function Signup() {
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [userkey, setUserkey] = useState("");
  const [isActive, setIsActive] = useState("");
  const [sponsorID, setSponsorID] = useState("");
  const [codeOtp, setCodeOtp] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [updatedDate, setUpdatedDate] = useState("");
  const [passwordConfirme, setPasswordConfirme] = useState("");
  const [password, setPassword] = useState("");
  const [cgv, setCgv] = useState(false);
  const [codeSponsor, setCodeSponsor] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const codeAffiliation = params.get("affiliation");

  function togglePasswordVisibility() {
    setIsPasswordVisible((password) => !password);
  }

  //Validation Email
  var mailformat =
    /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  // /\S+@\S+\.\S+/
  function isValidEmail(email) {
    return mailformat.test(email);
  }

  function validateForm() {
    setLoading(true);
    // Perform operation and then set loading to false
    setTimeout(() => {
      setLoading(false);
    }, 500);

    if (
      firstname === "" ||
      lastname === "" ||
      email === "" ||
      value === "" ||
      cgv === "" ||
      password === "" ||
      passwordConfirme === ""
    ) {
      setErrormsg("Veuillez remplir tous les champs ! *");
    }

    if (password.length < 8 || password.length > 12) {
      setErrormsg(
        "Attention, votre mot de passe doit être compris entre 8 et 12 caractères y compris des chiffres et des symboles !"
      );
    }

    if (cgv === "") {
      setErrormsg(
        "Veuillez accepter les conditions générales de ventes et d'utilisation de la plateforme ! *"
      );
      // console.log(formData.cgv);
    }

    if (!isValidEmail(email)) {
      setErrormsg("Attention, Votre adresse E-mail est invalide !");
      //console.log(isValidEmail(email));
    }

    if (password !== passwordConfirme) {
      setErrormsg("Les mots de passe ne sont pas identiques*");
    }

    var Emailconfirm = isValidEmail(email);

    if (
      firstname !== "" &&
      lastname !== "" &&
      email !== "" &&
      value !== "" &&
      cgv !== "" &&
      password !== "" &&
      passwordConfirme !== "" &&
      Emailconfirm !== false
    ) {
      //User Compte ID
      var length = 25;
      var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

      const userkey = Array.from({ length })
        .map(
          (e, i) =>
            characters[Math.floor(Math.random() * characters.length)] +
            (!((i + 1) % 5) ? "-" : "")
        )
        .join("")
        .slice(0, -1);

      //User Compte ID

      var apitoken = "Cliquez pour générer votre API Token";

      var sponsorkey;

      if (sponsorID === "" && codeAffiliation === null) {
        sponsorkey = "none";
      }

      if (sponsorID !== "") {
        sponsorkey = sponsorID;
      }

      if (codeAffiliation !== null) {
        sponsorkey = codeAffiliation;
      }

      if (sponsorID !== "" && codeAffiliation !== null) {
        sponsorkey = codeAffiliation;
      }

      var cauris = 0;

      const timestamp = new Date().toLocaleString();
      // var createdDate = new Intl.DateTimeFormat("fr-FR", {
      //   year: "numeric",
      //   month: "2-digit",
      //   day: "2-digit",
      //   hour: "2-digit",
      //   minute: "2-digit",
      //   second: "2-digit",
      // }).format(timestamp);

      var createdDate = new Date().toLocaleString();

      var codeOtp = 11111;
      var freecauris = 80;

      var commissionsponsor = 0;
      var role = "membre";
      var isActive = cgv;

      var updatedDate = createdDate;

      //  console.log(FomData);
      //   console.log(FomData[0].firstname);

      const axios = require("axios");
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firstname: firstname,
        lastname: lastname,
        email: email,
        phone: value,
        userkey: userkey,
        apitoken: apitoken,
        isActive: isActive,
        role: role,
        sponsorkey: sponsorkey,
        password: password,
        cauris: cauris,
        freecauris: freecauris,
        commissionsponsor: commissionsponsor,
        codeOtp: codeOtp,
        createdDate: createdDate,
        updatedDate: updatedDate,
      });
      // console.log(raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(process.env.React_App_base_url + "users", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          // console.log(result);
          let resdata = JSON.parse(result);
          const resp = resdata.statusCode;
          //console.log(resdata);
          // console.log(resp);
          if (resp === 200) {
            // console.log(sponsorkey);
            InscriptionEmail(
              firstname + " " + lastname,
              email,value,
              "Nous sommes heureux de vous avoir parmi nos partenaires !",
              "Votre compte a été créé avec succès 👌." +
                "\n" +
                " " +
                "Nous avons reçu votre demande de création de compte sur 5Sender.com et nous sommes ravis de vous informer que votre compte a été déjà crée et est accéssible." +
                "\n" +
                " Merci de vous connecter et profiter de nos nombreux services. "
            );
            
            setTimeout(() => {
              setLoading(false);
              navigate("../login");
            }, "1000");
            
          }

          if (resp === 302) {
            setLoading(false);
            setErrormsg(
              "Attention 🧐, un compte est déjà enrégistré avec cette addresse E-mail ou le numéro whatsApp !"
            );
          }
        })
        .catch((error) =>        
         error
         );
    }
  }
  return (
    <>
      <Header />
      <script src="https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/intlTelInput.min.js"></script>
      <div className="flex min-h-full flex-1 bg-yellow-400 ">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6  lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h2 className="mt-8 text-2xl font-bold leading-9 text-center tracking-tight text-gray-900">
                S'inscrire
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500"></p>
              <div className="mt-10">
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-white px-6 text-gray-900">
                      Avez-vous un compte déjà ? Oui,{" "}
                      <a
                        href="./login"
                        className="font-semibold text-blue-500 hover:text-yellow-400"
                      >
                        Se connecter
                      </a>
                    </span>
                  </div>
                </div>
                <p class="text-center p-2 -mb-4 text-red-700">{errormsg} </p>
              </div>
            </div>

            <div className="mt-10">
              <div>
                <div className="space-y-6">
                  <div class="mb-4 md:flex md:justify-between">
                    <div class="mb-4 md:mr-2 md:mb-0">
                      <label
                        class="block mb-2 text-sm font-bold text-gray-700"
                        for="firstName"
                      >
                        Prénom(s)
                      </label>
                      <input
                        class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        id="firstName"
                        type="text"
                        placeholder="Prénom(s)"
                        onChange={(e) => setFirstname(e.target.value)}
                      />
                    </div>
                    <div class="md:ml-2">
                      <label
                        class="block mb-2 text-sm font-bold text-gray-700"
                        for="lastName"
                      >
                        Nom
                      </label>
                      <input
                        class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        id="lastName"
                        type="text"
                        placeholder="Nom de famille"
                        onChange={(e) => setLastname(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="mb-4 md:flex md:justify-between">
                    <div class="mb-4 md:mr-2 md:mb-0">
                      <label
                        class="block mb-2 text-sm font-bold text-gray-700"
                        for="email"
                      >
                        E-mail
                      </label>
                      <input
                        class="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border  rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        id="password"
                        type="email"
                        placeholder="exemple@exemple.com"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <p class="text-xs italic text-red-500"></p>
                    </div>

                    <div class="md:ml-2 ">
                      <label
                        class="block mb-2 text-sm font-bold text-gray-700"
                        for="phone"
                      >
                        Numéro WhathSApp
                      </label>
                      <PhoneInput
                        class="w-full px-3 py-2 mb-3 h-8 outline-none text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        value={value}
                        onChange={setValue}
                        placeholder="+22901020304"
                      />
                    </div>
                  </div>
                  <div class="mb-4 md:flex md:justify-between">
                    <div class="mb-4 md:mr-2 md:mb-0">
                      <label
                        class="block mb-2 text-sm font-bold text-gray-700"
                        for="password"
                      >
                        Mot de passe
                      </label>
                      <span class="absolute  mt-2 ml-40 mr-4 cursor-pointer">
                        <img
                          onClick={togglePasswordVisibility}
                          src="https://tuk-cdn.s3.amazonaws.com/can-uploader/sign_in-svg5.svg"
                          alt="viewport"
                        />
                      </span>
                      <input
                        class="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        id="password"
                        type={isPasswordVisible ? "text" : "password"}
                        // value={password}
                        placeholder="******************"
                        onChange={(e) => setPassword(e.target.value)}
                      />

                      <p class="text-xs italic text-red-500"></p>
                    </div>
                    <div class="md:ml-2">
                      <label
                        class="block mb-2 text-sm font-bold text-gray-700"
                        for="c_password"
                      >
                        Confirmer le mot de passe
                      </label>
                      <span class="absolute  mt-2 ml-40 mr-4 cursor-pointer">
                        <img
                          onClick={togglePasswordVisibility}
                          src="https://tuk-cdn.s3.amazonaws.com/can-uploader/sign_in-svg5.svg"
                          alt="viewport"
                        />
                      </span>
                      <input
                        class="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        id="c_password"
                        type={isPasswordVisible ? "text" : "password"}
                        placeholder="******************"
                        onChange={(e) => setPasswordConfirme(e.target.value)}
                      />
                    </div>
                  </div>

                  <div class="md:ml-2">
                    <label
                      class="block mb-2 text-sm font-bold text-gray-700"
                      for="c_password"
                    >
                      Code d'affiliation
                    </label>
                    <input
                      class="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="code_affiliation"
                      type="text"
                      placeholder="ERTS-FJFD-LKRKD-NJDDK"
                      onChange={(e) => setSponsorID(e.target.value)}
                    />
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="cgv"
                        name="cgv"
                        value="true"
                        type="checkbox"
                        onChange={(e) => setCgv(true)}
                        className="h-4 w-4 rounded border-blue-500 text-blue-500 focus:ring-blue-500"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-3 block text-sm leading-6 text-blue-500"
                      >
                        <a
                          href="/conditionsgeneralesventes"
                          target="_blank"
                          className="font-semibold text-blue-500 hover:text-yellow-400"
                        >
                          J'accepte les conditions générales de ventes
                        </a>
                      </label>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      enable={loading}
                      onClick={() => {
                        validateForm();
                      }}
                      className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      {" "}
                      {loading ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 animate-spin mx-auto"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                          />
                        </svg>
                      ) : (
                        "Valider"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className=" inset-0 h-full w-full object-cover"
            src=".././jd_a.jpg"
            alt=""
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Signup;

// import Dasheader from "./Dasheader";
import Dashfooter from "./Dashfooter";
import React, { useState, useEffect } from "react";
// import Sidebar from "./Sibebar";
import axios from "axios";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { QRCodeCanvas } from "qrcode.react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

function Invoicesms() {
  const [notifications, setNotifications] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const InvoiceRef = params.get("facture");
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const [ref, setRef] = useState("");
  const [notificationsData, setNotificationsData] = useState([]);
   const [loggedIn, setLoggedIn] = useState(
     localStorage.getItem("uuid") !== null
   );
  //  console.log(InvoiceRef);
  let navigate = useNavigate();

  function Userinactivity() {
    var time;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(handleLogout, 100000000);
    }
  }
  if (!isAuthenticated) {
    navigate("../login", { replace: true });
  }

  Userinactivity();


  const handleLogout = () => {
    // clear the token from local storage and set the user as logged out
    // localStorage.removeItem("uuid");
    localStorage.clear();
    setLoggedIn(false);
    window.location.href = "../login";
    return null;
  };

  
     if (InvoiceRef) {
       // let userID = atob(localStorage.getItem("isLoggedIn"));
       axios
         .get(process.env.React_App_base_url + `notifications/`, {
           headers: {
             "Access-Control-Allow-Origin": "*",
             "Access-Control-Allow-Credentials": true,
             "Content-Type": "application/json",
             Authorization: `Bearer ${isAuthenticated}`,
           },
         })
         .then((res) => {
           //  console.log(res);
           if(res){
             const NotificationsData = res.data.Notification;
             //  DataFilter;
             const filteredNotifications = NotificationsData.filter(
               (notifications) =>
                 notifications["useremail"] === paymentData.useremail &&
                 notifications["viewstatus"] === "1" &&
                 notifications["refpay"] === InvoiceRef
             );
             setNotificationsData(filteredNotifications);

            //  console.log(filteredNotifications);
           }
         });
         
     }
  

  setTimeout(() => {
     if (notificationsData.length > 0) {
       try {
         let notifyID = notificationsData[0].id;
         const updatedata = JSON.stringify({
           viewstatus: "0",
         });
         // let userID = atob(localStorage.getItem("isLoggedIn"));
         axios
           .patch(
             process.env.React_App_base_url + `notifications/` + notifyID,
             updatedata,
             {
               headers: {
                 "Access-Control-Allow-Origin": "*",
                 "Access-Control-Allow-Credentials": true,
                 "Content-Type": "application/json",
                 Authorization: `Bearer ${isAuthenticated}`,
               },
             }
           )
           .then((res) => {
            //  console.log(res);
           });
       } catch (e) {
        //  console.log(e);
       }
     }
  }, "1000");





   let StatusPayment;
   if (paymentData.paymentStatus==="PENDING"){
    StatusPayment = "Non confirmé ❌";

   }
     if (paymentData.paymentStatus === "confirmed") {
       StatusPayment = "confirmé ✔️";
     }

     useEffect(() => {
       // console.log(InvoiceRef);
       setLoading(true);
       axios
         .get(process.env.React_App_base_url + `payments/` + InvoiceRef, {
           headers: {
             "Access-Control-Allow-Origin": "*",
             "Access-Control-Allow-Credentials": true,
             "Content-Type": "application/json",
             Authorization: `Bearer ${isAuthenticated}`,
           },
         })
         .then((res) => {
           const paymentData = res.data.data;

           setPaymentData(paymentData);

           //  console.log(paymentData);
           //  console.log(filtered);
         })
         .finally(() => {
           setLoading(false);
         });
     }, []);
// console.log(JSON.stringify(paymentData));
  /*Qr */
  const qrcode = (
    <QRCodeCanvas
      id="qrCode"
      value={
        "Facture N°:" +
        "" +
        paymentData.userRef +
        "\n" +
        "Montant à payer:" +
        "" +
        paymentData.montant +
        "\n" +
        "Règlement : " +
        StatusPayment +
        "\n " +
        "Moyen de paiement:" +
        " " +
        paymentData.paymentMode +
        " \n" +
        "Date:" +
        " " +
        paymentData.createdDte
      }
      size={150}
      bgColor={"#0000"}
      level={"H"}
    />
  );
  /*Qr */

  // PDF GENERATE B
  const printRef = React.useRef();
  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    var pdf = new jsPDF("p", "mm", [210, 297]);

    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("Facture 5Sender" + "_" + InvoiceRef + "_" + "_" + ".pdf");
  };

  const handleClick = () => {
    setLoading(true);
    // Perform operation and then set loading to false
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <>
      {/* <Dasheader /> */}
      <div className="bg-yellow-400 -mt-8">
        <div>
          {isMobile ? (
            <div>
              {/* Mobile */}
              <div class="bg-white border rounded-lg shadow-lg px-6 py-8 max-w-md mx-auto mt-0">
                <h1 class="font-bold text-2xl my-4 text-center text-blue-600">
                  <img
                    class="object-cover mx-auto w-32"
                    src="../.././5Logo2.png"
                  />
                </h1>
                <hr class="mb-2" />
                <div class="flex justify-between mb-6">
                  <h1 class="text-lg font-bold">Reçu</h1>
                  <div class="text-gray-700">
                    <div>Date: {paymentData.createdDte}</div>
                    <div>Réf: {paymentData.userRef}</div>
                    <p>Paiement via : {paymentData.paymentMode} </p>
                    <div className="font-bold bg-green-200 ">
                      Règlement: {StatusPayment}
                    </div>
                  </div>
                </div>
                <div class="mb-8">
                  <h2 class="text-lg font-bold mb-4">Client:</h2>
                  <div class="text-gray-700 mb-2">
                    {paymentData.firstname} {paymentData.lastname}
                  </div>
                  <div class="text-gray-700 mb-2">
                    Tél.: {paymentData.userphone}
                  </div>
                  <div class="text-gray-700 mb-2">
                    Email:{paymentData.useremail}
                  </div>
                  <div class="text-gray-700">
                    Code client: {paymentData.userkey}
                  </div>
                </div>
                <table class="w-full mb-8">
                  <thead>
                    <tr>
                      <th class="text-left font-bold text-gray-700">
                        Description
                      </th>
                      <th class="text-right font-bold text-gray-700">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-left text-gray-700">
                        {paymentData.productname}
                      </td>
                      <td class="text-right text-gray-700">
                        FCFA{paymentData.montant}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="text-left font-bold text-gray-700">Total</td>
                      <td class="text-right font-bold text-gray-700">
                        FCFA{paymentData.montant}
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <div class="text-gray-700 mb-2">Merci de votre confiance !</div>
                <div class="text-gray-700 text-sm">
                  Le numérique notre passion...
                </div>
                <p className="font-bold">Produit de FISA BENIN</p>
                <p className="font-sm">
                  Tél.:+22943214032 | E-mail: contact:contact@5sender.com
                </p>
              </div>
            </div>
          ) : (
            <div>
              {/* Desktop */}
              <div class="bg-yellow-400 p-16 mb-0  ">
                <div class="flex flex-col gap-3 w-1/2   mx-auto ">
                  {/* <Sidebar /> */}
                  <button
                    onClick={() => {
                      handleClick();
                      handleDownloadPdf();
                    }}
                    enable={loading}
                    class="inline-block w-48 mx-auto px-12 py-3 text-sm font-medium text-white bg-blue-300 border border-bleu-300 rounded active:text-violet-500 hover:bg-transparent hover:text-blue-300 focus:outline-none focus:ring"
                    href="/download"
                  >
                    {" "}
                    {loading ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 animate-spin mx-auto"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                        />
                      </svg>
                    ) : (
                      "Télécharger"
                    )}
                  </button>

                  <div
                    class="invoice max-w-5xl mx-auto py-16 bg-white"
                    ref={printRef}
                  >
                    <article class="overflow-hidden">
                      <div class="bg-[white] rounded-b-md">
                        <div class="p-9">
                          <div class="space-y-6 -mt-8 text-slate-700">
                            <img
                              class="object-cover h-12"
                              src="../.././5Logo2.png"
                            />
                            <p class="text font-extrabold tracking-tight uppercase font-body">
                              Solution Web | Marketing Digital| DEVELOPPEMENT
                              D'APPLICATION WEB & MOBILE
                            </p>
                            <p className="text-center w-96 py-4 my-auto  bg-yellow-400 text-white font-bold rounded-r-full">FACTURE | Règlement: {StatusPayment}</p>
                          </div>
                        </div>
                        <div class="p-9">
                          <div class="flex w-full -mb-24">
                            <div class="grid grid-cols-4 gap-12">
                              <div class="text-sm font-light text-slate-500">
                                <p class="text-sm font-normal text-slate-700">
                                  Détails:
                                </p>
                                <p>5SENDER</p>
                                <p></p>
                                <p></p>
                                <p></p>
                              </div>
                              <div class="text-sm font-light text-slate-500">
                                <p class="text-sm font-normal text-slate-700">
                                  Client :
                                </p>
                                <p>
                                  {paymentData.firstname} {paymentData.lastname}{" "}
                                </p>
                                <p>Tél.: {paymentData.userphone} </p>
                                <p>Email:{paymentData.useremail} </p>
                                {/* <p>France</p> */}
                              </div>
                              <div class="text-sm font-light text-slate-500">
                                <p class="text-sm font-normal text-slate-700">
                                  Réf:
                                </p>
                                <p>{paymentData.userRef} </p>

                                <p class="mt-2 text-sm font-normal text-slate-700">
                                  Date :
                                </p>
                                <p>{paymentData.createdDte} </p>
                              </div>
                              <div class="text-sm font-light text-slate-500">
                                <p class="text-sm font-normal text-slate-700">
                                  Mode:
                                </p>
                                <p>{paymentData.paymentMode} </p>

                                <p class="mt-2 text-sm font-normal text-slate-700">
                                  Code Client
                                </p>
                                <p>{paymentData.userkey} </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className=" w-32 mb-4 px-8 -mt-8  mx-auto text-center">
                          ----------Facture : ----------
                        </div>
                        <div className="font-bold bg-green-200 w-32 rounded-lg px-8 mx-auto text-sm w-64 text-center">
                          Règlement: {StatusPayment}
                        </div> */}
                        <div class="p-9">
                          <div class="flex flex-col mx-0 mt-8">
                            <table class="min-w-full divide-y divide-slate-500">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-slate-700 sm:pl-6 md:pl-0"
                                  >
                                    Désignation
                                  </th>
                                  <th
                                    scope="col"
                                    class="hidden py-3.5 px-3 text-right text-sm font-normal text-slate-700 sm:table-cell"
                                  >
                                    Quantitté
                                  </th>
                                  <th
                                    scope="col"
                                    class="hidden py-3.5 px-3 text-right text-sm font-normal text-slate-700 sm:table-cell"
                                  >
                                    P.u
                                  </th>
                                  <th
                                    scope="col"
                                    class="py-3.5 pl-3 pr-4 text-right text-sm font-normal text-slate-700 sm:pr-6 md:pr-0"
                                  >
                                    Total
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr class="border-b border-slate-200">
                                  <td class="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                    <div class="flex font-medium text-slate-700">
                                      <img
                                        class=" w-16  -mt-2 "
                                        src="../.././5Logo2.png"
                                      />{" "}
                                      <span> {paymentData.productname}</span>
                                    </div>
                                    <div class="mt-0.5 text-slate-500 sm:hidden">
                                      1 unit at $0.00
                                    </div>
                                  </td>
                                  <td class="hidden px-3 py-4 text-sm text-right text-slate-500 sm:table-cell">
                                    1
                                  </td>
                                  <td class="hidden px-3 py-4 text-sm text-right text-slate-500 sm:table-cell">
                                    {paymentData.montant}
                                  </td>
                                  <td class="py-4 pl-3 pr-4  font-bold text-right text-slate-500 sm:pr-6 md:pr-0">
                                    FCFA {paymentData.montant}
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <th
                                    scope="row"
                                    colspan="3"
                                    class="hidden pt-6 pl-6 pr-3 text-sm font-light text-right text-slate-500 sm:table-cell md:pl-0"
                                  >
                                    Sous-total
                                  </th>
                                  <th
                                    scope="row"
                                    class="pt-6 pl-4 pr-3 text-sm font-light text-left text-slate-500 sm:hidden"
                                  >
                                    Sous-total
                                  </th>
                                  <td class="pt-6 pl-3 pr-4 text-sm text-right text-slate-500 sm:pr-6 md:pr-0">
                                    FCFA {paymentData.montant}
                                  </td>
                                </tr>
                                <tr>
                                  <th
                                    scope="row"
                                    colspan="3"
                                    class="hidden pt-6 pl-6 pr-3 text-sm font-light text-right text-slate-500 sm:table-cell md:pl-0"
                                  >
                                    Réduction
                                  </th>
                                  <th
                                    scope="row"
                                    class="pt-6 pl-4 pr-3 text-sm font-light text-left text-slate-500 sm:hidden"
                                  >
                                    Réduction
                                  </th>
                                  <td class="pt-6 pl-3 pr-4 text-sm text-right text-slate-500 sm:pr-6 md:pr-0">
                                    FCFA 0.00
                                  </td>
                                </tr>
                                <tr>
                                  <th
                                    scope="row"
                                    colspan="3"
                                    class="hidden pt-4 pl-6 pr-3 text-sm font-light text-right text-slate-500 sm:table-cell md:pl-0"
                                  >
                                    Tax
                                  </th>
                                  <th
                                    scope="row"
                                    class="pt-4 pl-4 pr-3 text-sm font-light text-left text-slate-500 sm:hidden"
                                  >
                                    Tax
                                  </th>
                                  <td class="pt-4 pl-3 pr-4 text-sm text-right text-slate-500 sm:pr-6 md:pr-0">
                                    FCFA 0.00
                                  </td>
                                </tr>
                                <tr>
                                  <th
                                    scope="row"
                                    colspan="3"
                                    class="hidden pt-4 pl-6 pr-3 text-sm font-normal text-right text-slate-700 sm:table-cell md:pl-0"
                                  >
                                    Total
                                  </th>
                                  <th
                                    scope="row"
                                    class="pt-4 pl-4 pr-3 text-sm font-normal text-left text-slate-700 sm:hidden"
                                  >
                                    Total
                                  </th>
                                  <td class="pt-4 pl-3 pr-4 font-bold  text-right text-slate-700 sm:pr-6 md:pr-0">
                                    FCFA {paymentData.montant}
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                        <div className=" ml-64 -mt-44 -mb-8"> {qrcode}</div>
                        <div class="mt-8 p-9">
                          <div class="border-t pt-9 border-slate-200">
                            <div class="text-sm font-light text-center text-slate-700">
                              <p>
                                Le numérique notre passion ! Merci de nous
                                choisir pour vos compagnes marketing
                              </p>
                              {/* <p className="font-bold">Produit de FISA BENIN</p>
                              <p className="font-bold text-gray-400">
                                {" "}
                                COTONOU N° RCCM RB/ABC/20 A 20037
                              </p>
                              <p className="font-sm">
                                Abomey-Calavi, Arconville Maison
                              </p> */}
                              <p className="font-sm mb-8">
                                 E-mail: support@5sender.com
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <Dashfooter /> */}
    </>
  );
}

export default Invoicesms;

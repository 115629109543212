import Dashfooter from "./Dashfooter";
import Dasheader from "./Dasheader";
import Sidebar from "./Sibebar";
import React from "react";
import { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import Wasender from "./Wasender";
import NewCommandeEmail from "./NewCommandeEmail";

function Updateplan() {
  const [loading, setLoading] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  
  //Onsubmit
  //creating a formData state to hold each state for email and password
  const [formData, setFormData] = useState({
    planAbonne: "",
    paymentMode: "",
  });

  const [confirmationPay, setConfirmationPay] = useState("");
  const [payref, SetPayref] = useState("");
  const [number, setNumber] = useState("");
  const [commande, setCommande] = useState("");


  // Perform operation and then set loading to false
  function handleClick() {
    setLoading(true);
    // Perform operation and then set loading to false
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  //Destructuring email and password from formData
  const { planAbonne, paymentMode } = formData;
  //Declaring the onChange function
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));

    function NumberField() {
      if (
        e.target.value === "Mtn Benin" ||
        e.target.value === "Moov Benin" ||
        e.target.value === "Moov Togo"
      ) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
    NumberField();
  };

  const isAuthenticated = localStorage.getItem("uuid");
  let userID = atob(localStorage.getItem("isLoggedIn"));

  async function onSubmit(e) {
    e.preventDefault();
    handleClick();

    if (formData.planAbonne === "") {
      setErrormsg("Veuillez choisir un plan de crédit !");
      return null;
    }

    if (formData.paymentMode === "") {
      setErrormsg("Veuillez choisir un moyen de paiement pour continuer !");
      return null;
    }

    if (formData.planAbonne !== "" && formData.paymentMode !== "") {
      var price = "";
      var cauris = "";
      var sms = "";
      var newCauris = "";

      if (formData.planAbonne === "ByPass") {
        price = 140;
        cauris = 40;
        sms = 1;
      }

      if (formData.planAbonne === "Business") {
        price = 7000;
        cauris = 2000;
        sms = 50;
      }

      if (formData.planAbonne === "Affaire") {
        price = 66500;
        cauris = 200000;
        sms = 500;
      }

      

      //Mtn Bénin Payment
      if (number !== "" && formData.paymentMode === "Mtn Benin") {
        axios
          .get(process.env.React_App_base_url + `users/` + userID, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              Authorization: `Bearer ${isAuthenticated}`,
            },
          })
          .then(async (res) => {
            //console.log(res);
            let YourSponsor = "";
            const first = res.data.data.firstname;
            const last = res.data.data.lastname;
            const email = res.data.data.email;
            const keyOn = res.data.data.userkey;
            const Credisms = res.data.data.cauris;
            const Sponsor = res.data.data.sponsorkey;
            if (Sponsor === "none") {
              YourSponsor = "none";
            }
            if (Sponsor !== "none") {
              YourSponsor = Sponsor;
            }

            //console.log(first, last, keyOn);
            if (keyOn) {
              // setCommande( " " + res.data.data.firstname +" " + "sur 5sender ");
              var paymentData = JSON.stringify({
                firstname: first,
                lastname: last,
                useremail: email,
                userkey: keyOn,
                userphone: number,
                productname: "Crédit sms FiveSender",
                montant: price,
                userRef: "5Sender-" + Math.random().toString().slice(-5),
                sponkey: YourSponsor,
                paymentStatus: "PENDING",
                paymentMode: formData.paymentMode,
                createdDte: new Date().toLocaleString(),
              });

              await axios
                .post(
                  process.env.React_App_base_url + `payments/mtnbj`,
                  paymentData,
                  {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Credentials": true,
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${isAuthenticated}`,
                    },
                  }
                )
                .then((res) => {
                  // console.log(res);
                  let respData = res.data.message;
                  // const NewData = JSON.parse(res.config.data);
                  if (respData) {
                    setMsg(respData);
                    let paymentRef = res.data.payment.userRef;
                    let paymentID = res.data.payment.id;
                    setConfirmationPay(paymentID);
                    SetPayref(paymentRef);
                    //console.log(paymentRef);
                    //console.log(paymentID, paymentRef);

                    function Checking() {}
                    setLoading(true);
                    var refreshIntervalId = setInterval(() => {
                      setLoading(true);
                      //console.log(payref);
                      var confirmData = JSON.stringify({
                        id: paymentID,
                        userRef: paymentRef,
                        paymentStatus: "confirmed",
                      });
                      //  console.log(confirmData);
                      //alert("Yes");
                      axios
                        .post(
                          process.env.React_App_base_url + `payments/mtnstatus`,
                          confirmData,
                          {
                            headers: {
                              "Access-Control-Allow-Origin": "*",
                              "Access-Control-Allow-Credentials": true,
                              "Content-Type": "application/json",
                              "Authorization": `Bearer ${isAuthenticated}`,
                            },
                          }
                        )
                        .then((res) => {
                          //console.log(res);
                          const resMessage = res.data.message;
                          if (resMessage) {
                            setMsg(resMessage);

                            // navigate("./Updateplan");
                          }
                          if (resMessage === "PENDING...") {
                            //alert("Yes");
                            // console.log("credit");

                            setMsg(
                              "Merci de valider votre paiement et créditer votre compte ⌚️ !"
                            );
                          }

                          if (resMessage === "null") {
                            setMsg("Votre paiement n'a pas été validé ⚠️❌ !");
                            setLoading(false);
                            clearInterval(refreshIntervalId);
                            //navigate("../dashboard/homedash");
                          }

                          if (resMessage === "Low Balance") {
                            setMsg(
                              "Veuillez vérifier votre solde et rééssayer  💰 !"
                            );
                            setLoading(false);
                            clearInterval(refreshIntervalId);
                          }
                          if (resMessage === "Réseau") {
                            setMsg(
                              "Sorry, Un problème de réseau MTN Mobile Money. Veuillez choisir un autre moyen de paiement ou patientez réessayer plus tard 🛠 !"
                            );
                            setLoading(false);
                            clearInterval(refreshIntervalId);
                          }

                          if (resMessage === "Rejeter") {
                            setMsg(
                              "La validation de votre paiement a échoue 🔐!"
                            );
                            setLoading(false);
                            clearInterval(refreshIntervalId);
                          }

                          if (resMessage === "No account") {
                            setMsg(
                              "Ce muméro de paiement n'a pas de compte Mtn Mobile Money 🕹 !"
                            );
                            setLoading(false);
                            clearInterval(refreshIntervalId);
                          }

                          if (resMessage === "Failed") {
                            setMsg(
                              "Délai d'attente de validation de votre paiment est déjà expiré ⌛️ !"
                            );
                            setLoading(false);
                            clearInterval(refreshIntervalId);
                          }

                          if (resMessage === "SUCCESSFULL") {
                            var newCauris = [];

                            if (price === 140) {
                              newCauris.push(Credisms + 40);
                            }

                            if (price === 7000) {
                              newCauris.push(Credisms + 2000);
                            }

                            if (price === 66500) {
                              newCauris.push(Credisms + 20000);
                            }
                            // console.log(newCauris, newCauris[0]);

                            const data = JSON.stringify({
                              cauris: newCauris[0],
                            });

                            let userID = atob(
                              localStorage.getItem("isLoggedIn")
                            );
                            axios
                              .patch(
                                process.env.React_App_base_url +
                                  `users/` +
                                  userID,
                                data,
                                {
                                  headers: {
                                    "Access-Control-Allow-Origin": "*",
                                    "Access-Control-Allow-Credentials": true,
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${isAuthenticated}`,
                                  },
                                }
                              )
                              .then((res) => {
                                // console.log(res);

                                setMsg(
                                  "Votre paiement a été effectué avec succès ! Votre crésdit sms a été mise à jour avec succès 👌 ! . Veuillez le constater dans le tableau de board"
                                );
                              });

                            // NewCommande Email

                            const VendorEmail = "lynoshk@yahoo.fr";
                            const objet = "Nouvelle achat de SMS";
                            const message =
                              "Paiement via mobile, :" +
                              first +
                              " " +
                              " pour " +
                              " " +
                              price +
                              " " +
                              "FCFA";

                            NewCommandeEmail(VendorEmail, objet, message);

                            Wasender("" + first + " " + " pour " + " " + price);

                            const notifydata = JSON.stringify({
                              notificationtitle: "Votre achat de crédit sms",
                              lienRef: "./invoicesms/?facture=" + paymentID,
                              useremail: email,
                              refpay: paymentID,
                              userkey: keyOn,
                              viewstatus: "1",
                              createdDate: new Date().toLocaleString(),
                            });

                            axios
                              .post(
                                process.env.React_App_base_url +
                                  `notifications/`,

                                notifydata,
                                {
                                  headers: {
                                    "Access-Control-Allow-Origin": "*",
                                    "Access-Control-Allow-Credentials": true,
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${isAuthenticated}`,
                                  },
                                }
                              )
                              .then((res) => {
                                // console.log(res);
                              });
                            setLoading(false);
                            clearInterval(refreshIntervalId);

                            navigate("../dashboard/homedash");
                          }
                        });
                    }, 5000);
                    //  clearInterval(refreshIntervalId);
                  }

                  if (!respData) {
                    setErrormsg(
                      "*Erreur de connexion. Veuillez vérifier le solde de votre compte, le format de numéro accépté (22501020304), le réseau choisi et rééssayer !"
                    );
                    //navigate("./dashboard/updateplan");
                    return null;
                  }
                });
            }
          });
      }

      //  Moov TOGO

      if (number !== "" && formData.paymentMode === "Moov Togo") {
         axios
           .get(process.env.React_App_base_url + `users/` + userID, {
             headers: {
               "Access-Control-Allow-Origin": "*",
               "Access-Control-Allow-Credentials": true,
               "Content-Type": "application/json",
               Authorization: `Bearer ${isAuthenticated}`,
             },
           })
           .then(async (res) => {
             //console.log(res);
             const first = res.data.data.firstname;
             const last = res.data.data.lastname;
             const email = res.data.data.email;
             const keyOn = res.data.data.userkey;
             const Credisms = res.data.data.cauris;
             const Sponsor = res.data.data.sponsorkey;

             //console.log(first, last, keyOn);
             if (keyOn) {
               setCommande("" + first + " " + " pour " + " " + price);
               let YourSponsor = "";
               if (Sponsor === "none") {
                 YourSponsor = "none";
               }
               if (Sponsor !== "none") {
                 YourSponsor = Sponsor;
               }
               var paymentData = JSON.stringify({
                 firstname: first,
                 lastname: last,
                 useremail: email,
                 userkey: keyOn,
                 userphone: number,
                 productname: "Crédit sms FiveSender",
                 montant: price,
                 userRef: "5Sender-" + Math.random().toString().slice(-5),
                 sponkey: YourSponsor,
                 paymentStatus: "PENDING",
                 paymentMode: formData.paymentMode,
                 createdDte: new Date().toLocaleString(),
               });

               await axios
                 .post(
                   process.env.React_App_base_url + `payments/moovtg`,
                   paymentData,
                   {
                     headers: {
                       "Access-Control-Allow-Origin": "*",
                       "Access-Control-Allow-Credentials": true,
                       "Content-Type": "application/json",
                       Authorization: `Bearer ${isAuthenticated}`,
                     },
                   }
                 )
                 .then((res) => {
                   setLoading(true);
                    // console.log(res);
                   let respData = res.data.message;
                   // const NewData = JSON.parse(res.config.data);

                   if (
                     respData ||
                     respData.message !== "" ||
                     respData.message === ""
                   ) {
                     setErrormsg(respData);
                   }

                   if (
                     
                     respData.message === "PENDING..."
                   ) {
                     setErrormsg("Merci de valider votre paiement !");
                   }
                   if (respData || respData.message === "") {
                     setMsg(respData);
                     let paymentRef = res.data.payment.userRef;
                     let paymentID = res.data.payment.id;
                     setConfirmationPay(paymentID);
                     SetPayref(paymentRef);
                     //console.log(paymentRef);
                     //console.log(paymentID, paymentRef);

                     function Checking() {}
                     setLoading(true);
                     var refreshIntervalId = setInterval(() => {
                      //  setLoading(false);
                       //console.log(payref);

                       var confirmData = JSON.stringify({
                         id: paymentID,
                         userRef: paymentRef,
                         paymentStatus: "confirmed",
                       });
                       //  console.log(confirmData);
                       //alert("Yes");
                       axios
                         .post(
                           process.env.React_App_base_url +
                             `payments/moovtgstatus`,
                           confirmData,
                           {
                             headers: {
                               "Access-Control-Allow-Origin": "*",
                               "Access-Control-Allow-Credentials": true,
                               "Content-Type": "application/json",
                               Authorization: `Bearer ${isAuthenticated}`,
                             },
                           }
                         )
                         .then((res) => {
                          //  console.log(res);
                           const resMessage = res.data.message;
                           if (resMessage) {
                             setMsg(resMessage);
                             setLoading(true);
                             // navigate("./Updateplan");
                           }
                           if (
                             resMessage === "PENDING..." ||
                             resMessage === ""
                           ) {
                             //alert("Yes");
                             // console.log("credit");
                             // Wasender(commande);
                             setMsg("Merci de valider votre paiement ⌚️ !");
                           }

                           if (resMessage === "null") {
                             setMsg("Votre paiement n'a pas été validé ⚠️❌ !");
                             setLoading(false);
                             clearInterval(refreshIntervalId);
                             //navigate("../dashboard/homedash");
                           }

                           if (resMessage === "Low Balance") {
                             setMsg(
                               "Veuillez vérifier votre solde et rééssayer  💰 !"
                             );
                             setLoading(false);
                             clearInterval(refreshIntervalId);
                           }
                           if (resMessage === "Réseau") {
                             setMsg(
                               "Sorry, Un problème de réseau Moov Money. Veuillez choisir un autre moyen de paiement ou patientez réessayer plus tard 🛠 !"
                             );
                             setLoading(false);
                             clearInterval(refreshIntervalId);
                           }

                           if (resMessage === "Rejeter") {
                             setMsg(
                               "La validation de votre paiement a échoue 🔐!"
                             );
                             setLoading(false);
                             clearInterval(refreshIntervalId);
                           }

                           if (resMessage === "No account") {
                             setMsg(
                               "Ce muméro de paiement n'a pas de compte Mtn Mobile Money 🕹 !"
                             );
                             setLoading(false);
                             clearInterval(refreshIntervalId);
                           }

                           if (resMessage === "Failed") {
                             setMsg(
                               "Délai d'attente de validation de votre paiment est déjà expiré. ⌛️ !"
                             );
                             setLoading(false);
                             clearInterval(refreshIntervalId);
                           }

                           if (resMessage === "SUCCESSFULL") {
                             var newCauris = [];

                             if (price === 140) {
                               newCauris.push(Credisms + 40);
                             }

                             if (price === 7000) {
                               newCauris.push(Credisms + 2000);
                             }

                             if (price === 66500) {
                               newCauris.push(Credisms + 20000);
                             }
                             // console.log(newCauris, newCauris[0]);

                             const data = JSON.stringify({
                               cauris: newCauris[0],
                             });
                             let userID = atob(
                               localStorage.getItem("isLoggedIn")
                             );
                             axios
                               .patch(
                                 process.env.React_App_base_url +
                                   `users/` +
                                   userID,
                                 data,
                                 {
                                   headers: {
                                     "Access-Control-Allow-Origin": "*",
                                     "Access-Control-Allow-Credentials": true,
                                     "Content-Type": "application/json",
                                     Authorization: `Bearer ${isAuthenticated}`,
                                   },
                                 }
                               )
                               .then((res) => {
                                 // console.log(res);

                                 const notifydata = JSON.stringify({
                                   notificationtitle:
                                     "Votre achat de crédit sms",
                                   lienRef:
                                     "./invoicesms/?facture=" + paymentID,
                                   useremail: email,
                                   userkey: keyOn,
                                   refpay: paymentID,
                                   viewstatus: "1",
                                   createdDate: new Date().toLocaleString(),
                                 });

                                 axios
                                   .post(
                                     process.env.React_App_base_url +
                                       `notifications/`,

                                     notifydata,
                                     {
                                       headers: {
                                         "Access-Control-Allow-Origin": "*",
                                         "Access-Control-Allow-Credentials": true,
                                         "Content-Type": "application/json",
                                         Authorization: `Bearer ${isAuthenticated}`,
                                       },
                                     }
                                   )
                                   .then((res) => {
                                     // console.log(res);
                                   });
                                 // Wasender(commande);
                                 setMsg(
                                   "Votre paiement a été effectué avec succès ! VVotre crésdit sms a été mise à jour avec succès 👌 ! . Veuillez le constater dans le tableau de board"
                                 );
                                 Wasender(
                                   "" + first + " " + " pour " + " " + price
                                 );
                               });

                             setLoading(false);
                             clearInterval(refreshIntervalId);

                             navigate("../dashboard/homedash");
                           }
                         });
                     }, 5000);
                     //  clearInterval(refreshIntervalId);
                   }

                   if (!respData) {
                     setErrormsg(
                       "*Erreur de connexion. Veuillez vérifier le solde de votre compte, le format de numéro accépté (22501020304), le réseau choisi et rééssayer !"
                     );
                     //navigate("./dashboard/updateplan");
                     return null;
                   }
                 });
             }
           });


      }


      //  Moov Bénin Payment

      if (number !== "" && formData.paymentMode === "Moov Benin") {
        axios
          .get(process.env.React_App_base_url + `users/` + userID, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              Authorization: `Bearer ${isAuthenticated}`,
            },
          })
          .then(async (res) => {
            //console.log(res);
            const first = res.data.data.firstname;
            const last = res.data.data.lastname;
            const email = res.data.data.email;
            const keyOn = res.data.data.userkey;
            const Credisms = res.data.data.cauris;
            const Sponsor = res.data.data.sponsorkey;

            //console.log(first, last, keyOn);
            if (keyOn) {
              setCommande("" + first +" "+" pour "+" "+ price);
              let YourSponsor = "";
              if (Sponsor === "none") {
                YourSponsor = "none";
              }
              if (Sponsor !== "none") {
                YourSponsor = Sponsor;
              }
              var paymentData = JSON.stringify({
                firstname: first,
                lastname: last,
                useremail: email,
                userkey: keyOn,
                userphone: number,
                productname: "Crédit sms FiveSender",
                montant: price,
                userRef: "5Sender-" + Math.random().toString().slice(-5),
                sponkey: YourSponsor,
                paymentStatus: "PENDING",
                paymentMode: formData.paymentMode,
                createdDte: new Date().toLocaleString(),
              });

              await axios
                .post(
                  process.env.React_App_base_url + `payments/moovbj`,
                  paymentData,
                  {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Credentials": true,
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${isAuthenticated}`,
                    },
                  }
                )
                .then((res) => {
                   setLoading(true);
                  //  console.log(res);
                  let respData = res.data.message;
                  // const NewData = JSON.parse(res.config.data);

                  if (
                    respData ||
                    respData.message !== "" ||
                    respData.message === ""
                  ) {
                    setErrormsg(respData);
                  }
                    if (respData || respData.message === "") {
                      setMsg(respData);
                      let paymentRef = res.data.payment.userRef;
                      let paymentID = res.data.payment.id;
                      setConfirmationPay(paymentID);
                      SetPayref(paymentRef);
                      //console.log(paymentRef);
                      //console.log(paymentID, paymentRef);

                      function Checking() {}
                      setLoading(true);
                      var refreshIntervalId = setInterval(() => {
                        setLoading(false);
                        //console.log(payref);

                        var confirmData = JSON.stringify({
                          id: paymentID,
                          userRef: paymentRef,
                          paymentStatus: "confirmed",
                        });
                        //  console.log(confirmData);
                        //alert("Yes");
                        axios
                          .post(
                            process.env.React_App_base_url +
                              `payments/moovbjstatus`,
                            confirmData,
                            {
                              headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Credentials": true,
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${isAuthenticated}`,
                              },
                            }
                          )
                          .then((res) => {
                            // console.log(res);
                            const resMessage = res.data.message;
                            if (resMessage) {
                              setMsg(resMessage);
                              setLoading(true);
                              // navigate("./Updateplan");
                            }
                            if (resMessage === "PENDING..." || resMessage === "") {
                              //alert("Yes");
                              // console.log("credit");
                              // Wasender(commande);
                              setMsg(
                                "Merci de valider votre paiement ⌚️ !"
                              );
                            }

                            if (resMessage === "null") {
                              setMsg(
                                "Votre paiement n'a pas été validé ⚠️❌ !"
                              );
                              setLoading(false);
                              clearInterval(refreshIntervalId);
                              //navigate("../dashboard/homedash");
                            }

                            if (resMessage === "Low Balance") {
                              setMsg(
                                "Veuillez vérifier votre solde et rééssayer  💰 !"
                              );
                              setLoading(false);
                              clearInterval(refreshIntervalId);
                            }
                            if (resMessage === "Réseau") {
                              setMsg(
                                "Sorry, Un problème de réseau Moov Money. Veuillez choisir un autre moyen de paiement ou patientez réessayer plus tard 🛠 !"
                              );
                              setLoading(false);
                              clearInterval(refreshIntervalId);
                            }

                            if (resMessage === "Rejeter") {
                              setMsg(
                                "La validation de votre paiement a échoue 🔐!"
                              );
                              setLoading(false);
                              clearInterval(refreshIntervalId);
                            }

                            if (resMessage === "No account") {
                              setMsg(
                                "Ce muméro de paiement n'a pas de compte Mtn Mobile Money 🕹 !"
                              );
                              setLoading(false);
                              clearInterval(refreshIntervalId);
                            }

                            if (resMessage === "Failed") {
                              setMsg(
                                "Délai d'attente de validation de votre paiment est déjà expiré. ⌛️ !"
                              );
                              setLoading(false);
                              clearInterval(refreshIntervalId);
                            }

                            if (resMessage === "SUCCESSFULL") {
                              var newCauris = [];

                              if (price === 140) {
                                newCauris.push(Credisms + 40);
                              }

                              if (price === 7000) {
                                newCauris.push(Credisms + 2000);
                              }

                              if (price === 66500) {
                                newCauris.push(Credisms + 20000);
                              }
                              // console.log(newCauris, newCauris[0]);

                              const data = JSON.stringify({
                                cauris: newCauris[0],
                              });
                              let userID = atob(
                                localStorage.getItem("isLoggedIn")
                              );
                              axios
                                .patch(
                                  process.env.React_App_base_url +
                                    `users/` +
                                    userID,
                                  data,
                                  {
                                    headers: {
                                      "Access-Control-Allow-Origin": "*",
                                      "Access-Control-Allow-Credentials": true,
                                      "Content-Type": "application/json",
                                      Authorization: `Bearer ${isAuthenticated}`,
                                    },
                                  }
                                )
                                .then((res) => {
                                  // console.log(res);

                                  const notifydata = JSON.stringify({
                                    notificationtitle:
                                      "Votre achat de crédit sms",
                                    lienRef:
                                      "./invoicesms/?facture=" + paymentID,
                                    useremail: email,
                                    userkey: keyOn,
                                    refpay: paymentID,
                                    viewstatus: "1",
                                    createdDate: new Date().toLocaleString(),
                                  });

                                  axios
                                    .post(
                                      process.env.React_App_base_url +
                                        `notifications/`,

                                      notifydata,
                                      {
                                        headers: {
                                          "Access-Control-Allow-Origin": "*",
                                          "Access-Control-Allow-Credentials": true,
                                          "Content-Type": "application/json",
                                          Authorization: `Bearer ${isAuthenticated}`,
                                        },
                                      }
                                    )
                                    .then((res) => {
                                      // console.log(res);
                                    });
                                  // Wasender(commande);
                                  setMsg(
                                    "Votre paiement a été effectué avec succès ! VVotre crésdit sms a été mise à jour avec succès 👌 ! . Veuillez le constater dans le tableau de board"
                                  );
                                  Wasender(commande);
                                });

                              setLoading(false);
                              clearInterval(refreshIntervalId);

                              navigate("../dashboard/homedash");
                            }
                          });
                      }, 5000);
                      //  clearInterval(refreshIntervalId);
                    }

                  if (!respData) {
                    setErrormsg(
                      "*Erreur de connexion. Veuillez vérifier le solde de votre compte, le format de numéro accépté (22501020304), le réseau choisi et rééssayer !"
                    );
                    //navigate("./dashboard/updateplan");
                    return null;
                  }
                });
            }
          });
      }
      
      //Visa payment
      if (number === "" && formData.paymentMode === "Visa Carte") {
        axios
          .get(process.env.React_App_base_url + `users/` + userID, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              Authorization: `Bearer ${isAuthenticated}`,
            },
          })
          .then(async (res) => {
            
            //console.log(res);
            let YourSponsor = "";
            const first = res.data.data.firstname;
            const last = res.data.data.lastname;
            const email = res.data.data.email;
            const keyOn = res.data.data.userkey;
            const Credisms = res.data.data.cauris;
            const phone = res.data.data.phone;
            const Sponsor = res.data.data.sponsorkey;
            if (Sponsor === "none") {
              YourSponsor = "none";
            }
            if (Sponsor !== "none") {
              YourSponsor = Sponsor;
            }

            //console.log(first, last, keyOn);
            if (keyOn) {
              setCommande(
                " " + first + " " + " pour " + " " + price + " " + "FCFA"
              );
              var paymentData = JSON.stringify({
                firstname: first,
                lastname: last,
                useremail: email,
                userkey: keyOn,
                userphone: phone,
                productname: "Crédit sms FiveSender",
                montant: price,
                userRef: "5Sender-" + Math.random().toString().slice(-5),
                sponkey: YourSponsor,
                paymentStatus: "PENDING",
                paymentMode: formData.paymentMode,
                createdDte: new Date().toLocaleString(),
              });
              //console.log(paymentData);

              await axios
                .post(
                  process.env.React_App_base_url + `payments/visacard`,
                  paymentData,
                  {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Credentials": true,
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${isAuthenticated}`,
                    },
                  }
                )
                .then((res) => {
                  // console.log(res);
                  let respData = res.data.message;
                  let PaymentUrl = res.data.paymentRediref.url;
                  let Payment_Id = res.data.paymentId;
                  let Payment_Ref=res.data.paymentRef
                  // const NewData = JSON.parse(res.config.data);
                  if (respData) {
                    setMsg(respData);
                    setLoading(true);
                    //  window.location.href = PaymentUrl;
                    let commandeID = (Payment_Id);
                    localStorage.setItem("opr", commandeID);
                    let statusCredit=(Credisms);
                    localStorage.setItem("userCredit", statusCredit);
                    let commandePrice = (price);
                    localStorage.setItem("theprice", commandePrice);

                    window.location.href = PaymentUrl;

                    // window.open(
                    //   PaymentUrl
                    //   , "_blank" // <- This is what makes it open in a new window.
                    // );
                    // setLoading(true);
                    var refreshIntervalId = setInterval(() => {
                      setLoading(true);
                      //console.log(payref);
                      var confirmData = JSON.stringify({
                        id: Payment_Id,
                        userRef: Payment_Ref,
                        paymentStatus: "confirmed",
                      });
                      //  console.log(confirmData);
                      //alert("Yes");
                      axios.post(
                          process.env.React_App_base_url +
                            `payments/visacardstatus`,
                          confirmData,
                          {
                            headers: {
                              "Access-Control-Allow-Origin": "*",
                              "Access-Control-Allow-Credentials": true,
                              "Content-Type": "application/json",
                              Authorization: `Bearer ${isAuthenticated}`,
                            },
                          }
                        )
                        .then((res) => {
                          //console.log(res);
                          const resMessage = res.data.message;
                          if (resMessage) {
                            setMsg(resMessage);

                            // navigate("./Updateplan");
                          }
                          if (resMessage === "PENDING...") {
                            //alert("Yes");
                            // console.log("credit");

                            setMsg(
                              "Merci de valider votre paiement et créditer votre compte ⌚️ !"
                            );
                          }

                          if (resMessage === "null") {
                            setMsg("Votre paiement n'a pas été validé ⚠️❌ !");
                            setLoading(false);
                            clearInterval(refreshIntervalId);
                            //navigate("../dashboard/homedash");
                          }

                          if (resMessage === "Low Balance") {
                            setMsg(
                              "Veuillez vérifier votre solde et rééssayer  💰 !"
                            );
                            setLoading(false);
                            clearInterval(refreshIntervalId);
                          }
                          if (resMessage === "Réseau") {
                            setMsg(
                              "Sorry, Un problème de réseau MTN Mobile Money. Veuillez choisir un autre moyen de paiement ou patientez réessayer plus tard 🛠 !"
                            );
                            setLoading(false);
                            clearInterval(refreshIntervalId);
                          }

                          if (resMessage === "Rejeter") {
                            setMsg(
                              "La validation de votre paiement a échoue 🔐!"
                            );
                            setLoading(false);
                            clearInterval(refreshIntervalId);
                          }

                          if (resMessage === "No account") {
                            setMsg(
                              "Ce muméro de paiement n'a pas de compte Mtn Mobile Money 🕹 !"
                            );
                            setLoading(false);
                            clearInterval(refreshIntervalId);
                          }

                          if (resMessage === "Failed") {
                            setLoading(true);
                            setMsg(
                              "Votre paiement est toujours en attente de validation sur la page à côté de celle-ci ⌛️ !"
                            );                            
                            setTimeout(() => {
                               clearInterval(refreshIntervalId);
                               setMsg(
                                 "Votre paiement..."
                               );
                               navigate("../dashboard/homedash")
                               setLoading(false);
                            }, "80000");
                            
                          }

                          if (resMessage === "SUCCESSFULL") {
                            var newCauris = [];

                            if (price === 140) {
                              newCauris.push(Credisms + 40);
                            }

                            if (price === 7000) {
                              newCauris.push(Credisms + 2000);
                            }

                            if (price === 66500) {
                              newCauris.push(Credisms + 20000);
                            }
                            // console.log(newCauris, newCauris[0]);

                            const data = JSON.stringify({
                              cauris: newCauris[0],
                            });
                            let userID = atob(
                              localStorage.getItem("isLoggedIn")
                            );
                            axios
                              .patch(
                                process.env.React_App_base_url +
                                  `users/` +
                                  userID,
                                data,
                                {
                                  headers: {
                                    "Access-Control-Allow-Origin": "*",
                                    "Access-Control-Allow-Credentials": true,
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${isAuthenticated}`,
                                  },
                                }
                              )
                              .then((res) => {
                                // console.log(res);

                                setMsg(
                                  "Votre paiement a été effectué avec succès ! Votre crésdit sms a été mise à jour avec succès 👌 ! . Veuillez le constater dans le tableau de board"
                                );
                              });

                             

                            Wasender(
                              " " +
                                first +
                                " " +
                                " pour " +
                                " " +
                                price +
                                " " +
                                "FCFA"
                            );

                            const notifydata = JSON.stringify({
                              notificationtitle: "Votre achat de crédit sms",
                              lienRef: "./invoicesms/?facture=" + Payment_Id,
                              useremail: email,
                              refpay: Payment_Id,
                              userkey: keyOn,
                              viewstatus: "1",
                              createdDate: new Date().toLocaleString(),
                            });

                            axios
                              .post(
                                process.env.React_App_base_url +
                                  `notifications/`,

                                notifydata,
                                {
                                  headers: {
                                    "Access-Control-Allow-Origin": "*",
                                    "Access-Control-Allow-Credentials": true,
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${isAuthenticated}`,
                                  },
                                }
                              )
                              .then((res) => {
                                // console.log(res);
                              });
                            setLoading(false);
                            clearInterval(refreshIntervalId);

                            navigate("../dashboard/homedash");
                          }
                        });
                    }, 5000);
                    //  clearInterval(refreshIntervalId);
                  
                  }

                  if (!respData) {
                    setErrormsg(
                      "*Erreur de connexion. Veuillez choisir un autre moyen de paiement !"
                    );
                    //navigate("./dashboard/updateplan");
                    return null;
                  }
                });
            }
          });
      }
    }
  }

  return (
    <>
      <Dasheader />
      <div className="bg-yellow-400 -mt-8 ">
        <Sidebar />
        <div className="p-8 bg-yellow-400 h-screen mb-48 ">
          <form class="checkout w-8/12  p-16   mx-auto  bg-white rounded-lg shadow border-2">
            <span class="relative mx-auto text-4xl font-bold text-indigo-500">
              <svg
                aria-hidden="true"
                viewBox="0 0 418 42"
                class="absolute top-2/3 left-0 h-[0.58em] w-full fill-orange-300/70"
                preserveAspectRatio="none"
              >
                <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
              </svg>
              <span class=" mx-auto">PLAN D'ACHAT</span>
            </span>
            <div class="flex flex-col sm:flex-row items-start sm:items-center">
              <label class="w-full sm:w-1/3 block sm:flex sm:flex-row sm:flex-col sm:mr-2 mb-2 sm:mb-0  font-bold text-left sm:text-right">
                Plan <p class="text-blue-500 font-bold  underline">5Sender</p>
              </label>

              <div class="w-full sm:w-2/3 flex flex-col sm:ml-2 mb-4 p-4 bg-indigo-500 rounded-lg  border-2 rounded">
                <label for="plan-free" class="my-1 mt-0" />
                {/* 14O FCFA  */}

                {/* <div class="flex items-center">
                  <input
                    id="planAbonne"
                    value="ByPass"
                    onChange={onChange}
                    type="radio"
                    name="default-radio"
                    class="w-4 h-4  "
                  />
                  <label
                    for="default-radio-2"
                    class="ml-2 text-sm font-medium text-white "
                  >
                    ByPass 140 /FCFA, 40 Crédits, 01 sms
                  </label>
                </div> */}
                {/* 14O FCFA  */}
                <div class="flex items-center mt-4">
                  <input
                    id="planAbonne"
                    value="Business"
                    onChange={onChange}
                    type="radio"
                    name="default-radio"
                    class="w-4 h-4  "
                  />
                  <label
                    for="default-radio-2"
                    class="ml-2 text-sm font-medium text-white"
                  >
                    Business 7.000 /FCFA, 2000 Crédits, 50 sms
                  </label>
                </div>
                <div class="flex items-center mt-4">
                  <input
                    id="planAbonne"
                    value="Affaire"
                    onChange={onChange}
                    type="radio"
                    name="default-radio"
                    class="w-4 h-4  "
                  />
                  <label
                    for="default-radio-2"
                    class="ml-2 text-sm font-medium text-white"
                  >
                    Affaire 66.500 /FCFA, 200000 Crédits, 500 sms
                  </label>
                </div>
              </div>
            </div>

            <div class="flex flex-col sm:flex-row items-center mb-4">
              <label
                for="card-element"
                class="w-full sm:w-1/3 sm:mr-2 mb-2 sm:mb-0 sm:text-right"
              >
                Moyens de paiement
              </label>
              <div id="card-element" class="w-full sm:w-2/3 sm:ml-2">
                <select
                  id="paymentMode"
                  value={paymentMode}
                  onChange={onChange}
                  name="card-element"
                  placeholder="Card number"
                  class="w-full p-2 rounded shadow-inner border"
                >
                  <option selected>Choisir le moyen de paiement</option>
                  <option value="Mtn Benin">Mtn Mobile Money Bénin</option>
                  <option value="Moov Togo">Moov Money Togo</option>
                  {/* <option value="Moov Benin">Moov Money</option> */}
                  <option value="Visa Carte">Carte Visa</option>
                </select>
              </div>
            </div>
            <div className="numbercase items-center pl-80 ">
              {isVisible ? (
                <div id="box" class="col-span-2 mt-2 sm:col-span-1">
                  <label
                    for="card-holder"
                    class="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Téléphone de paiement (pas de (+) pour l'indicatif du pays:
                    22501020304)
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setNumber(e.target.value);
                    }}
                    placeholder="229998080800"
                    class="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                  />
                </div>
              ) : null}
            </div>
            <div class="mt-2 text-right">
              <button
                type="submit"
                onClick={onSubmit}
                enable={loading}
                class="py-2 px-4 bg-indigo-500 hover:bg-black text-gray-200 rounded-lg"
              >
                {loading ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 animate-spin mx-auto"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                ) : (
                  "Valider"
                )}
              </button>
            </div>
            <p className="text-green-600 p-2 text-2xl text-center">{msg}</p>
            <p class="text-center p-2 text-2xl text-red-700">{errormsg} </p>
          </form>
        </div>
        <Dashfooter />
      </div>
    </>
  );
}

export default Updateplan;

import Dasheader from "./Dasheader";
import Dashfooter from "./Dashfooter";
import Sidebar from "./Sibebar";

function Notreboutique() {
  return (
    <>
      <Dasheader />

      <div className="bg-yellow-400 pt-2 -mt-8">
        <Sidebar />

        <div class="p-4 mt-16 mb-0">
          <h1 class="text-4xl text-center text-white font-semibold mb-6">
            Nos solutions digitales pour booster votre business
          </h1>
          <div class="service flex">
            <div class="boutique max-w-md mx-auto mt-16 ml-64 bg-white rounded-lg overflow-hidden md:max-w-lg border border-gray-400">
              <div class="px-4 py-2 border-b border-gray-200 ">
                <h2 class="font-semibold text-gray-800">PLUGIN WOOCOMMERCE</h2>
              </div>

              <div class="flex flex-col divide-y divide-gray-200 ">
                <div class="flex items-center py-4 px-6 ">
                  <img
                    class="w-16 h-16 object-cover rounded"
                    src=".././5Logo2.png"
                    alt="Product Image"
                  />
                  <div class="ml-3 ">
                    <h3 class="text-gray-900 font-semibold">Woo5sender</h3>
                    <p class="text-gray-700 mt-1">FCFA 0</p>
                  </div>
                  <a
                    href=".././woo5Sender.zip"
                    // target="_blank"
                    class="ml-auto py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white rounded-lg"
                  >
                    Télécharger →
                  </a>
                </div>
                {/* <div class="flex items-center py-4 px-6">
                  <img
                    class="w-16 h-16 object-cover rounded"
                    src=".././5Logo2.png"
                    alt="Product Image"
                  />
                  <div class="ml-3">
                    <h3 class="text-gray-900 font-semibold">Wha5sender</h3>
                    <p class="text-gray-700 mt-1">FCFA 0</p>
                  </div>
                  <button class="ml-auto py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white rounded-lg">
                    Télécharger →
                  </button>
                </div> */}
              </div>
              {/* <div class="flex items-center justify-between px-6 py-3 bg-gray-100">
                <h3 class="text-gray-900 font-semibold">Total: $29.98</h3>
                <button class="py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white rounded-lg">
                  Checkout
                </button> 
              </div> */}
            </div>
            <div class="max-w-sm  flex flex-col rounded-3xl bg-white dark:bg-gray-950 shadow-xl ring-1 ring-black/10">
              <div class="p-8 sm:p-10">
                <h3
                  id="tier-basic"
                  class="text-lg font-semibold leading-8 tracking-tight text-indigo-600"
                >
                  Création de site Pro
                </h3>
                A partir de
                <div class="mt-4 flex items-baseline text-5xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                  <span> 249.999 </span>
                  <span class="text-lg font-semibold leading-8 tracking-normal text-gray-500">
                    /FCFA
                  </span>
                </div>
                <p class="mt-6 text-base leading-7 text-gray-600">
                  Un site Pro qui concurence le marché de votre secteur
                  d'activité
                </p>
              </div>
              <div class="flex flex-1 flex-col p-2">
                <div class="flex flex-1 flex-col justify-between rounded-2xl bg-gray-50 dark:bg-gray-900 p-6 sm:p-2">
                  <ul role="list" class="space-y-6">
                    <li class="flex items-start">
                      <p class="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-400">
                        Faites le pas et nous vous recontacterons pour rédiger
                        le cahier de charge et passer à l'action
                      </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                      <div class="flex-shrink-0">
                        <svg
                          class="w-5 h-5 text-indigo-600"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <p class="ml-3 leading-5 text-gray-600">
                        Fonctionnalités interactives.
                      </p>
                    </li>
                    <li class="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                      <div class="flex-shrink-0">
                        <svg
                          class="w-5 h-5 text-indigo-600"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <p class="ml-3 leading-5 text-gray-600">
                        Un nom de domaine + hébergement (01 an).
                      </p>
                    </li>
                    <li class="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                      <div class="flex-shrink-0">
                        <svg
                          class="w-5 h-5 text-indigo-600"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <p class="ml-3 leading-5 text-gray-600">
                        Boite mail professionnelle.
                      </p>
                    </li>
                    <li class="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                      <div class="flex-shrink-0">
                        <svg
                          class="w-5 h-5 text-indigo-600"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <p class="ml-3 leading-5 text-gray-600">
                        Une formation pour vous faciliter l'utilisation.
                      </p>
                    </li>
                    <li class="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                      <div class="flex-shrink-0">
                        <svg
                          class="w-5 h-5 text-indigo-600"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <p class="ml-3 leading-5 text-gray-600">
                        Assistante support 24H/24
                      </p>
                    </li>
                    <li class="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                      <div class="flex-shrink-0">
                        <svg
                          class="w-5 h-5 text-indigo-600"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <p class="ml-3 leading-5 text-gray-600">
                        Délai maximum 01 mois à compter de la date de signature
                        du contrat
                      </p>
                    </li>
                  </ul>
                  <div class="mt-8">
                    <a
                      href="./contactsupport"
                      aria-describedby="tier-basic"
                      class="inline-block w-full rounded-lg bg-indigo-600 px-4 py-2.5 text-center text-sm font-semibold leading-5 text-white shadow-md hover:bg-indigo-700"
                    >
                      Contacter le service techique →
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="max-w-sm mr-4 ml-2  flex flex-col rounded-3xl bg-white dark:bg-gray-950 shadow-xl ring-1 ring-black/10">
              <div class="p-8 sm:p-10">
                <h3
                  id="tier-basic"
                  class="text-lg font-semibold leading-8 tracking-tight text-indigo-600"
                >
                  Assistance et Conseils Marketing
                </h3>
                A partir de
                <div class="mt-4 flex items-baseline text-5xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                  <span>149.999 </span>
                  <span class="text-lg font-semibold leading-8 tracking-normal text-gray-500">
                    /mois
                  </span>
                </div>
                <p class="mt-2 text-base leading-7 text-gray-600">
                  Nous faisons la veille commerciale et la communication pour
                  vous
                </p>
              </div>
              <div class="flex flex-1 flex-col p-2">
                <div class="flex flex-1 flex-col justify-between rounded-2xl bg-gray-50 dark:bg-gray-900 p-6 sm:p-2">
                  <ul role="list" class="space-y-6">
                    <li class="flex items-start">
                      <p class="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-400">
                        Nous analysons l'existant et nous vous faisons des
                        propositions pour impacter votre marché
                      </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                      <div class="flex-shrink-0">
                        <svg
                          class="w-5 h-5 text-indigo-600"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <p class="ml-3 leading-5 text-gray-600">
                        Nous proposons des outils pour votre secteur d'activité
                      </p>
                    </li>
                    <li class="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                      <div class="flex-shrink-0">
                        <svg
                          class="w-5 h-5 text-indigo-600"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <p class="ml-3 leading-5 text-gray-600">
                        Rédaction d'articles de ventes de vos produits
                      </p>
                    </li>
                    <li class="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                      <div class="flex-shrink-0">
                        <svg
                          class="w-5 h-5 text-indigo-600"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <p class="ml-3 leading-5 text-gray-600">
                        Publicité sponsorisée
                      </p>
                    </li>
                    <li class="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                      <div class="flex-shrink-0">
                        <svg
                          class="w-5 h-5 text-indigo-600"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <p class="ml-3 leading-5 text-gray-600">
                        Production d'Affiche pour les réseaux sociaux
                      </p>
                    </li>
                    <li class="flex items-start mt-5 lg:col-span-1 lg:mt-0">
                      <div class="flex-shrink-0">
                        <svg
                          class="w-5 h-5 text-indigo-600"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <p class="ml-3 leading-5 text-gray-600">
                        Promotion de vos évènements sur web.
                      </p>
                    </li>
                  </ul>
                  <div class="mt-8">
                    <a
                      href="./contactsupport"
                      aria-describedby="tier-basic"
                      class="inline-block w-full rounded-lg bg-indigo-600 px-4 py-2.5 text-center text-sm font-semibold leading-5 text-white shadow-md hover:bg-indigo-700"
                    >
                      Contacter le service techique →
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dashfooter />
    </>
  );
}

export default Notreboutique;

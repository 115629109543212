import Dasheader from "./Dasheader";
import Dashfooter from "./Dashfooter";
import Sidebar from "./Sibebar";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

function Allpayments() {
  const [userKEY, setUerKEY] = useState("");
  const [payConfirm, setPayConfirm] = useState("");
  const [payCommission, setPayCommission] = useState("");
  const [totalPay, setTotalPay] = useState();
  const [paymentData, setPaymentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated) {
        let StateId = atob(localStorage.getItem("isLoggedIn"));
        // console.log(StateId, isAuthenticated);

        if (StateId) {
          axios
            .get(process.env.React_App_base_url + `users/` + StateId, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Content-Type": "application/json",
                Authorization: `Bearer ${isAuthenticated}`,
              },
            })
            .then((res) => {
              // console.log(res);
              setTimeout(() => {
                if (res.data.data.role !== "admin") {
                  localStorage.clear();
                  navigate("../login");
                }
              }, 500);

              let ActivationCompte = res.data.data.isActive;

              if (ActivationCompte !== true) {
                navigate(".././login");
              }

              //console.log(res.data);
              // let getOpt = res.data.NewData.codeOtp;
              // console.log(getOpt);
            });
        }
      }
    }, 1000);
  }, []);
  //JSdataTable
  $(document).ready(function () {
    setTimeout(function () {
      $("#example").DataTable({
        paging: true,
        searching: true,
        retrieve: true,
        language: {
          processing: "Traitement en cours...",
          search: "Recherche&nbsp;:",
          lengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
          info: "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
          infoEmpty:
            "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
          infoFiltered:
            "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
          infoPostFix: "",
          loadingRecords: "Chargement en cours...",
          zeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
          emptyTable: "Aucune donnée disponible dans le tableau",
          paginate: {
            first: "Premier",
            previous: "Pr&eacute;c&eacute;dent",
            next: "Suivant",
            last: "Dernier",
          },
        },
      });
    }, 1000);
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(process.env.React_App_base_url + `payments/`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${isAuthenticated}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        const UtData = res.data.payment;
        setPaymentData(res.data.payment);
        let confpay = UtData.filter(
          (confirm) => confirm["paymentStatus"] === "confirmed"
        ).length;

        let availableConfirm = UtData.filter(
          (confirm) => confirm["paymentStatus"] === "confirmed"
        )
          .map((item) => item.montant)
          .reduce((a, b) => a + b, 0);

        setPayConfirm(availableConfirm);

        setUerKEY(UtData.length);
        let available = UtData.map((item) => item.montant).reduce(
          (a, b) => a + b,
          0
        );
        setTotalPay(available);
        setPayConfirm(availableConfirm);
        //  console.log(availableConfirm);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(process.env.React_App_base_url + `users/`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${isAuthenticated}`,
        },
      })
      .then((res) => {
        let allUsers = res.data.users;

        let availableComm = allUsers
          .map((item) => item.commissionsponsor)
          .reduce((a, b) => a + b, 0);
        //  console.log(availableComm);
        setPayCommission(availableComm);
      });
  }, []);

  return (
    <>
      <Dasheader />
      <div class="p-4 bg-yellow-400 -mt-8 text-white ">
        {/* <Sidebar /> */}
        <h1 class="text-4xl mt-24 text-center font-semibold mb-2">
          REGISTRE DES VENTES ET PAIEMENTS
        </h1>
        <div class="grid grid-cols-1 gap-8 p-10 mt-4 lg:grid-cols-2 xl:grid-cols-4">
          <div class="flex items-center shadow justify-between p-4 bg-white rounded-md dark:bg-darker">
            <div>
              <h6 class="text-xs font-medium leading-none tracking-wider text-gray-500 uppercase dark:text-primary-light">
                TOTAL PAIEMENTS INITIES
              </h6>
              {/* <span class="text-xl font-semibold">$30,000</span> */}
              <span class="inline-block px-2 py-px ml-2 text-xs font-bold text-indigo-500 bg-indigo-100 rounded-md">
                {totalPay} FCFA
              </span>
            </div>
            <div>
              <span>
                <svg
                  class="w-12 h-12 text-gray-300 dark:text-primary-dark"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
              </span>
            </div>
          </div>

          <div class="flex items-center shadow justify-between p-4 bg-white rounded-md dark:bg-darker">
            <div>
              <h6 class="text-xs font-medium leading-none tracking-wider text-gray-500 uppercase dark:text-primary-light">
                TOTAL DES CLIENTS
              </h6>
              {/* <span class="text-xl font-semibold">50,021</span> */}
              <span class="inline-block px-2 py-px ml-2 text-xs text-gray-100 bg-yellow-400 rounded-md">
                {userKEY}
              </span>
            </div>
            <div>
              <span>
                <svg
                  class="w-12 h-12 text-gray-300 dark:text-primary-dark"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                  ></path>
                </svg>
              </span>
            </div>
          </div>

          <div class="flex items-center shadow justify-between p-4 bg-white rounded-md dark:bg-darker">
            <div>
              <h6 class="text-xs font-medium leading-none tracking-wider text-gray-500 uppercase dark:text-primary-light">
                TOTAL PAIEMENT CONFIRMES
              </h6>
              {/* <span class="text-xl font-semibold">45,021</span> */}
              <span class="inline-block px-2 py-px ml-2 text-xs text-gray-100 bg-green-500 rounded-md">
                {payConfirm}
              </span>
            </div>
            <div>
              <span>
                <svg
                  class="w-12 h-12 text-gray-300 dark:text-primary-dark"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                  ></path>
                </svg>
              </span>
            </div>
          </div>

          <div class="flex items-center shadow justify-between p-4 bg-white rounded-md dark:bg-darker">
            <div>
              <h6 class="text-xs font-medium leading-none tracking-wider text-gray-500 uppercase dark:text-primary-light">
                TOTAL DES COMMISSIONS PAYEES
              </h6>
              {/* <span class="text-xl font-semibold">20,516</span> */}
              <span class="inline-block px-2 py-px ml-2 text-xs text-red-500 bg-red-100 rounded-md">
                {payCommission + "FCFA"}
              </span>
            </div>
            <div>
              <span>
                <svg
                  class="w-12 h-12 text-gray-300 dark:text-primary-dark"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
                  ></path>
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div class="w-10/12 mx-auto mb-64">
          <div className="bg-gray-900">
            <div className="mx-auto max-w-7xl">
              <div className="bg-gray-900 py-10">
                <div className="px-4 sm:px-6 lg:px-8">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-base font-semibold leading-6 text-white">
                        Les comptes actifs
                      </h1>
                      <p className="mt-2 text-sm text-gray-300">
                        La liste des achats effectués
                      </p>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                      <a
                        href="./admindash"
                        type="button"
                        className="block rounded-md bg-indigo-500 px-3 py-2 text-center text-sm font-semibold text-white hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                      >
                        ↲ Retour
                      </a>
                    </div>
                  </div>
                  <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table
                          id="example"
                          className="min-w-full divide-y divide-gray-700 text-white"
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                              >
                                Nom et prénom(s)
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                              >
                                Email
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                              >
                                Tél.
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                              >
                                Credisms
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                              >
                                Moyen de paiement
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                              >
                                Date
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-800">
                            {paymentData.map((paymentData) => (
                              <tr key={paymentData.email}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                  {paymentData.lastname +
                                    " " +
                                    " " +
                                    paymentData.firstname}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                  {paymentData.useremail}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                  {paymentData.userphone}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                  {paymentData.montant}
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                  {paymentData.paymentMode}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                  {paymentData.createdDte}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                                  <a
                                    href={`./invoicesms/?facture=${paymentData.id}`}
                                    target="_blank"
                                    className="text-yellow-400 hover:text-gray-100"
                                  >
                                    Facture →
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dashfooter />
    </>
  );
}

export default Allpayments;


import Dasheader from "./Dasheader";
import Dashfooter from "./Dashfooter";
import Sidebar from "./Sibebar";
import { useState } from "react"
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import axios from "axios";

function Sendsms() {
  const [value, setValue] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const [msg, setMsg] = useState("");
   const [senderTexte, setSenderTexte] = useState("");
   const [texteSender, setTexteSender] = useState("");
   const [formData, setFormData] = useState({
     sender: "",    
     message: "",
     fullname:""
   });

  // Perform operation and then set loading to false
  function handleClick() {
    setLoading(true);
    // Perform operation and then set loading to false
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }
const { sender, message, fullname } = formData;
    const onChange = (e) => {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.value,
      }));
      //console.log(formData);
      function NumberField() {
        if (e.target.value === "Autre") {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
      NumberField();
    }
 const isAuthenticated = localStorage.getItem("uuid");
 let userID = atob(localStorage.getItem("isLoggedIn"));

  async function onSubmit(e) {
     e.preventDefault();
           handleClick();


  if (formData.fullname === "") {
   setErrormsg("Veuillez saisir le nom du destinataire svp !");
   return null;
 }
           
    if (formData.sender === "") {
    setErrormsg("Veuillez choisir le texte d'envoi svp !");
       return null;
    }  

     if (formData.message === "") {
       setErrormsg("Veuillez saisir le texte du message svp !");
       return null;
     }

     if (formData.message && formData.message.length > 150) {
       setErrormsg("Votre texte ne doit pas dépasser 150 caractères !");
       return null;
     }


    if (senderTexte && senderTexte===""){
       setErrormsg("Veuillez saisir le texte du message svp !");
        return null;
      }
       if (senderTexte && senderTexte.length> 11) {
         setErrormsg("Votre texte ne doit pas dépasser 11 caractères !");
         return null;
       }


    if (value && value === "") {
    setErrormsg("Veuillez saisir le numéro de réception du sms !");
    return null;
  }   
     
      if (
        formData.sender !== "" &&
        value !== "" &&
        formData.message !== "" &&
         formData.fullname !=="") {

          
          const senderTextValue=[];
          if (senderTexte === ""){
            senderTextValue.push(sender);
          }
          if (senderTexte !==""){
            senderTextValue.push(senderTexte);
          }          
        
          // console.log(formData, value, senderTexte, fullname);
                         axios
                           .get(
                             process.env.React_App_base_url + `users/` + userID,
                             {
                               headers: {
                                 "Access-Control-Allow-Origin": "*",
                                 "Access-Control-Allow-Credentials": true,
                                 "Content-Type": "application/json",
                                 Authorization: `Bearer ${isAuthenticated}`,
                               },
                             }
                           )
                           .then(async (res) => {
                             //console.log(res);
                             const first = res.data.data.firstname;
                             const last = res.data.data.lastname;
                             const email = res.data.data.email;
                             const keyOn = res.data.data.userkey;
                             const Credisms = res.data.data.cauris;
                             const FreeCauris = res.data.data.freecauris;

                             const Créditsms =
                               Number(Credisms) + Number(FreeCauris) - 40;

                             //  console.log(Credisms,parseInt(FreeCauris),parseInt(Credisms), Créditsms);
                             if (
                               Créditsms >= 40 ||
                               FreeCauris >= 40
                             ) {
                               var paymentData = JSON.stringify({
                                 fullnameto: fullname,
                                 phoneto: value,
                                 title: senderTextValue[0],
                                 senderkey: keyOn,
                                 outemail: email,
                                 cauris: 40,
                                 message: message,
                                 createdDate: new Date().toLocaleString(),
                               });
                               //  console.log(paymentData);
                               await axios
                                 .post(
                                   process.env.React_App_base_url +
                                     `sendersms/`,
                                   paymentData,
                                   {
                                     headers: {
                                       "Access-Control-Allow-Origin": "*",
                                       "Access-Control-Allow-Credentials": true,
                                       "Content-Type": "application/json",
                                       Authorization: `Bearer ${isAuthenticated}`,
                                     },
                                   }
                                 )
                                 .then((res) => {
                                   // console.log(res);

                                   const success = res.data.sms.message;

                                      if (
                                        success === "Message send Successfull"
                                      ) {
                                        if (Number(FreeCauris) >= 40) {
                                          const New1 = Number(FreeCauris) - 40;

                                          //  console.log(New1);

                                          const data = JSON.stringify({
                                            freecauris: New1,
                                          });
                                          let userID = atob(
                                            localStorage.getItem("isLoggedIn")
                                          );
                                          axios
                                            .patch(
                                              process.env.React_App_base_url +
                                                `users/` +
                                                userID,
                                              data,
                                              {
                                                headers: {
                                                  "Access-Control-Allow-Origin":
                                                    "*",
                                                  "Access-Control-Allow-Credentials": true,
                                                  "Content-Type":
                                                    "application/json",
                                                  Authorization: `Bearer ${isAuthenticated}`,
                                                },
                                              }
                                            )
                                            .then((res) => {
                                              // console.log(res);
                                              setMsg(
                                                "Votre sms a été envoyé avec succès ! 👌 ! "
                                              );
                                            });
                                        }

                                        if (
                                          Number(FreeCauris) < 40 &&
                                          Number(Credisms) >= 40
                                        ) {
                                          const New2 = Number(Credisms) - 40;
                                          // console.log(New2);

                                          const data = JSON.stringify({
                                            cauris: New2,
                                          });
                                          let userID = atob(
                                            localStorage.getItem("isLoggedIn")
                                          );
                                          axios
                                            .patch(
                                              process.env.React_App_base_url +
                                                `users/` +
                                                userID,
                                              data,
                                              {
                                                headers: {
                                                  "Access-Control-Allow-Origin":
                                                    "*",
                                                  "Access-Control-Allow-Credentials": true,
                                                  "Content-Type":
                                                    "application/json",
                                                  Authorization: `Bearer ${isAuthenticated}`,
                                                },
                                              }
                                            )
                                            .then((res) => {
                                              // console.log(res);
                                              setMsg(
                                                "Votre sms a été envoyé avec succès ! 👌 ! "
                                              );
                                            });
                                        }
                                      }
                                 });
                             } else {
                               setErrormsg(
                                 "Votre crédit sms est insuffisant. Veuillez créditer votre Compte pour continuer 🔔!"
                               );
                               return null;
                             }
                           });
                           // alert("Yes !");
        return null;
      }  
          }

  return (
    <>
      <Dasheader />
      <div className="bg-yellow-400 mb-0 -mt-8">
        <div className="bg-yellow-400 pt-2 ">
          <Sidebar />
          <body class="font-mono bg-gay-100 ">
            <div class="container mx-auto">
              <div class="flex justify-center px-2 my-8 ">
                <div class="w-full xl:w-3/4 lg:w-11/12 flex -mt-6 mb-2">
                  <div class="w-full h-auto bg-gray-400  hidden lg:block lg:w-5/12 bg-cover rounded-l-lg">
                    <img
                      className=" inset-0 h-full w-full object-cover"
                      src=".././Bien-etre-au-travail.png"
                      alt=""
                    />
                  </div>

                  <div class="w-full lg:w-5/12 bg-white p-5 rounded-lg lg:rounded-l-none">
                    <img
                      class="w-auto h-16 sm:h-16  mx-auto"
                      src=".././5logo2.png"
                      loading="lazy"
                      width="202"
                      height="40"
                    />
                    <p className="text-center text-2xl">
                      {" "}
                      ENVOYEZ UN SMS RAPPEL FACILEMENT
                    </p>
                    <div class="max-w-xl mx-auto mt-8 flex w-full flex-col border rounded-lg bg-white p-8">
                      <h2 class="title-font mb-1 text-lg font-medium text-gray-900">
                        FLASH SMS
                      </h2>
                      <p class="mb-5 leading-relaxed text-gray-600">
                        Exploitez le plein potentiel des SMS
                      </p>
                      <p className="text-green-600 p-2 text-center">{msg}</p>
                      <p class="text-center p-2 text-red-700">{errormsg} </p>
                      <div class="mb-4">
                        <label
                          for="title"
                          class="text-sm leading-7 text-gray-600"
                        >
                          Rappel échéance paiement de mai...
                        </label>
                        <select
                          id="sender"
                          name="sender"
                          onChange={onChange}
                          value={sender}
                          class="w-full rounded border border-gray-300 bg-white py-1 px-3 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
                        >
                          <option selected>
                            Sélectionnez le type de message
                          </option>
                          <option value="Note de classe">
                           Note !!! 
                          </option>
                          <option value="Rappel">Rappel</option>
                          {/* <option value="Confirmation">Confirmation</option> */}
                          <option value="Infos">Infos</option>
                          <option value="Promo">Promo !!!</option>
                          <option value="Anniv !!!">
                           Anniv !!!
                          </option>
                          <option value="Urgent">Urgent !!!</option>
                          <option value="Evenement">Event !!!</option>
                          <option value="Surprise">Surprise !!!</option>
                          <option value="Amour">Je t'aime </option>
                          <option value="Autre">Autre</option>
                        </select>
                      </div>{" "}
                      {isVisible ? (
                        <div id="box" class="col-span-2 mt-2 sm:col-span-1">
                          <label
                            for="card-holder"
                            class="block text-sm font-medium text-gray-700 mb-2"
                          >
                            Votre texte d'envoi peronsonnalisé ici !
                          </label>
                          <input
                            type="text"
                            onChange={(e) => {
                              setSenderTexte(e.target.value);
                            }}
                            maxLength={11}
                            placeholder="Invitation"
                            class="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                          />
                        </div>
                      ) : null}
                      <div class="mb-4 ">
                        <label
                          for="email"
                          class="text-sm leading-7 text-gray-600 "
                        >
                          Tél.+22901020304
                        </label>
                        <PhoneInput
                          className="w-full rounded border border-gray-300 bg-white py-1 px-3 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500  focus:ring-2 focus:ring-indigo-200"
                          value={value}
                          onChange={setValue}
                          placeholder="+22901020304"
                          class="w-full rounded border border-2  border-gray-300 bg-white py-1 px-3 text-base h-8 leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
                        />
                      </div>
                      <div class="mb-4 ">
                        <label
                          for="email"
                          class="text-sm leading-7 text-gray-600 "
                        >
                          Nom et Prénom du Destinataire du sms
                        </label>
                        <input
                          className="w-full rounded border border-gray-300 bg-white py-1 px-3 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500  focus:ring-2 focus:ring-indigo-200"
                          id="fullname"
                          name="fullname"
                          value={fullname}
                          onChange={onChange}
                          placeholder="Claudia SENYO"
                          class="w-full rounded border border-2  border-gray-300 bg-white py-1 px-3 text-base h-8 leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
                        />
                      </div>
                      <div class="mb-4">
                        <label
                          for="message"
                          class="text-sm leading-7 text-gray-600"
                        >
                          Message
                        </label>
                        <textarea
                          onChange={onChange}
                          id="message"
                          name="message"
                          value={message}
                          maxLength={150}
                          class="h-32 w-full resize-none rounded border border-gray-300 bg-white py-1 px-3 text-base leading-6 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
                        ></textarea>
                      </div>
                      <button
                        type="submit"
                        onClick={onSubmit}
                        enable={loading}
                        class="rounded border-0 bg-indigo-500 py-2 px-6 text-lg text-white hover:bg-indigo-600 focus:outline-none"
                      >
                        {" "}
                        {loading ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 animate-spin mx-auto"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                            />
                          </svg>
                        ) : (
                          "Envoyer maintenant "
                        )}
                      </button>
                      <p class="mt-3 text-xs ">
                        150 caractères au maximum pour le message
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </body>
        </div>
      </div>
      <Dashfooter />
    </>
  );
}

export default Sendsms;

import Footer from "./Footer";
import Header from "./Header";
import React from "react";


function ConditionsGeneralesVentes() {

     

  
  return (
    <>
      <Header />

      <div class="">
        <div class="container mx-auto px-4 py-8">
          <h1 class="text-3xl font-bold mb-4">
            Conditions Générales de ventes
          </h1>

          <p class="mb-4 text-justify">
            En acceptant les présentes Conditions Générales de Ventes (CGV) ,
            vous déclarez que vous avez au moins l'âge de la majorité dans votre
            État ou province de résidence. Vous ne pouvez pas utiliser nos
            produits à des fins illégales ou non autorisées et vous ne pouvez
            pas, dans le cadre de l'utilisation du service, violer les lois de
            votre juridiction (y compris, mais sans s'y limiter, les lois sur
            les droits d'auteur).
          </p>

          <h2 class="text-2xl font-bold mb-2">
            I- Modification des Conditions Generales de Vente
          </h2>

          <p class="mb-4">
            5sender se réserve le droit de modifier les termes, conditions et
            avis en vertu desquels ce site web est offert:
          </p>

          <ul class="list-disc list-inside mb-4">
            <li>Modification de tarifs</li>
            <li>Conditions de remboursement</li>
            <li>Reglèment de litiges</li>
            <li>Violation des des droits et en cas de fraude</li>
          </ul>

          <h2 class="text-2xl font-bold mb-2">II- Mentions légales</h2>

          <p class="mb-4">
            Notre site web est édité par la société FISA BENIN:
          </p>

          <ul class="list-disc list-inside mb-4 ">
            <li>Conception</li>
            <li>Déploiement</li>
            <li className="text-justify">
              Sauf indication contraire, FISA BENIN et / ou ses concédants de
              licence détiennent les droits de propriété intellectuelle et le
              contenu du site Web soumis à la licence ci-dessous. Nous ne vous
              concédons aucun droit, licence, titre ou intérêt sur l'un de nos
              droits de propriété intellectuelle auxquels vous pouvez ou non
              avoir accès. Vous acceptez de prendre de telles mesures, y compris
              tout document légal ou officiel ou tout autre document pouvant
              être nécessaire pour affirmer davantage nos droits de propriété
              intellectuelle.
            </li>
            <li className="text-justify">
              Si vous ne respectez pas toutes les dispositions, vous serez alors
              responsable de tous les dommages en résultant pour vous-même, FISA
              BENIN et tous les tiers. Sauf disposition contraire des lois
              applicables, vous acceptez de ne pas modifier, redéfinir,
              reproduire, adapter, afficher, distribuer, traduire, désassembler,
              faire de l'ingénierie inverse ou autrement tenter de créer un code
              source dérivé du logiciel. Tous les commentaires ou suggestions
              que vous pourrez nous fournir ainsi que nos services sont
              entièrement volontaires et nous serons libres de les utiliser, à
              notre convenance, sans obligation de votre part.
            </li>
          </ul>

          <h2 class="text-2xl font-bold mb-2">
            III- Qui peut utiliser nos services ?
          </h2>

          <p class="mb-4">
            Si vous acceptez les présentes Conditions et utilisez nos Services à
            titre personnel ou au nom d'une entreprise ou organisation, vous
            déclarez et garantissez que vous êtes autorisé à le faire.
          </p>

          <h2 class="text-2xl font-bold mb-2">IV- Déclaration de garantie</h2>

          <p class="mb-4 text-justify">
            FISA BENIN s'assurera toujours que la plateforme soit disponible
            sauf en cas de panne technique de son fournisseur d'hébergement ou
            en cas d'incident indépendemment de sa volonté.
          </p>

          <p class="mb-4 text-justify">
            Sans préjudice des autres droits de FISA BENIN en vertu des
            présentes Conditions, si vous ne respectez pas ces Conditions,
            pourra prendre les mesures qu'il jugera appropriées pour remédier à
            la violation, notamment en suspendant votre accès à la plateforme,
            en vous interdisant l'accès au site Web, en bloquant les ordinateurs
            utilisant votre adresse IP pour accéder au site Web, en contactant
            votre fournisseur de services Internet pour lui demander de bloquer
            votre accès au site Web et / ou en entamant des poursuites
            judiciaires à votre encontre.
          </p>

          <h2 class="text-2xl font-bold mb-2">V- Restrictions </h2>

          <p class="mb-4 text-justify">
            Vous êtes responsable des données que vous importez et manipulez sur
            notre plateforme. Vous confirmez que les destinataires de vos
            messages ont donné leur accord pour les recevoir . En cas de
            plainte, vous êtes tenu responsable des préjudices. Pour une
            meilleure utilisation de la plateforme, nous vous recommandons
            d'être responsable de vos actes. En cas de plainte, nous serons dans
            l'obligation de suspendre et en suite supprimer votre compte.
          </p>

          <h2 class="text-2xl font-bold mb-2">VI- Moyen de paiement</h2>

          <p class="mb-4 text-justify">
            Les moyens de paiement acceptés sur notre plateforme sont:
            <li>Visa Carte</li>
            <li>Mobile Money</li>
          </p>

          <h2 class="text-2xl font-bold mb-2">VII- Validité du présent CGV</h2>

          <p class="mb-4 text-justify">
            Le présent CGV est valable pour une durée indéterminée. Le début
            d’utilisation des services de la plateforme et des services y
            afférents marque l’application du contrat à l’égard de
            l’Utilisateur:
          </p>

          <ul class="list-disc list-inside mb-4">
            <li>
              Les présentes conditions doivent être interprétées, exécutées et
              régies conformément aux lois de l'espace OHADA. Aussi, vous vous
              soumettez aux juridictions compétentes de ces pays et celles
              étrangères pour la résolution de tout litige.
            </li>
            <li>
              En cas de litige nous seront ouvert aux négociations à l'amiable
              avant t'entamer les procédures judiciaires.
            </li>
            <li>Tout copie et reproduction de ce CGV est strictement interdit.</li>
            <li>
              Merci de faire recours à l'administration de 5sender en cas
              d'incompréhension du présent contrat.
            </li>
          </ul>

          <p class="mb-4">
            Ce présent Coditions Générales de Ventes (CGV) peut être modifiée
            sans préavis.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ConditionsGeneralesVentes;
import Dasheader from "./Dasheader";
import Dashfooter from "./Dashfooter";
import Sidebar from "./Sibebar";
import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import sendEmail from "./SendEmail";

function ContactSupport() {
  const [showModal, setShowModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userFisrtname, setUserFisrtname] = useState("");
  const [userLastname, setUserLastname] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [userObject, setUserObject] = useState("");
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("uuid") !== null
  );
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const isAuthenticated = localStorage.getItem("uuid");
  const [loading, setLoading] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const [succesMsg, setSuccessMsg] = useState("");

  useEffect(() => {
    axios
      .get(process.env.React_App_base_url + `users/` + userID, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${isAuthenticated}`,
        },
      })
      .then(async (res) => {
        //console.log(res);
        setUserEmail(res.data.data.email);
        setUserFisrtname(res.data.data.firstname);
        setUserLastname(res.data.data.lastname);
        setUserPhone(res.data.data.phone);
      });
  }, []);
  const data = [
    userEmail,
    userFisrtname,
    userLastname,
    userPhone,
    userMessage,
    userObject,
  ];
  function Onsubmit() {
    setLoading(true);
    // Perform operation and then set loading to false
    setTimeout(() => {
      setLoading(false);
    }, 500);

    if (userMessage === "" || userObject === "") {
      setErrormsg("Veuillez remplir tous les champs ! *");
      setSuccessMsg("");
    }

    if (userMessage !== "" && userObject !== "") {
      //  console.log(data);
      setSuccessMsg(
        "Votre message a été envoyé avec succès. Nous vous recontacterons sous peu 👋🏽 !"
      );
      sendEmail(
        userFisrtname + " " + userLastname,
        userEmail,
        userPhone,
        userObject,
        userObject + " \n " + userMessage
      );
      setUserObject("");
      setUserMessage("");
      setErrormsg("");
    }
  }

  return (
    <>
      <Dasheader />
      <div className=" h-screen bg-yellow-400 -mt-8 mb-0">
        <Sidebar />
        <div class=" py-8 bg-yellow-400 w-7/12 mx-auto text-gray-800">
          <div class="container mx-auto px-4">
            <div class="sm:text-center lg:text-left mt-16 ">
              <h1 class="text-4xl tracking-tight font-extrabold text-center text-gray-800 sm:text-5xl md:text-4xl">
                <span class="block xl:inline  text-white  ">
                  Contactez-nous pour tout besoin de service numérique
                </span>
              </h1>
            </div>
            <section class="sm:mt-6 lg:mt-8 mt-12 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div class="my-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 flex gap-3 lg:flex-justify lg:flex flex-col lg:flex-row">
                <div class="sm:text-center lg:text-left">
                  <h1 class="text-4xl tracking-tight font-extrabold text-gray-800 sm:text-5xl md:text-6xl">
                    <span class="block xl:inline">Des outils numériques </span>
                    <span class="block text-indigo-600 xl:inline">
                      pour booster votre Business
                    </span>
                  </h1>
                  <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl text-white sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                    Notre service technique est à votre disposition pour tous
                    vos besoins en développement de solution numérique.
                  </p>

                  <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                    <div class="rounded-md shadow">
                      <button
                        onClick={() => setShowModal(true)}
                        class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-600 md:py-4 md:text-lg md:px-10"
                      >
                        Envoyez un mail
                      </button>
                    </div>
                    <div class="mt-3 sm:mt-0 sm:ml-3">
                      <a
                        href="https://wa.me/22943747378"
                        target="_blank"
                        class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-gray-800 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                      >
                        Chat WhathSApp →
                      </a>
                    </div>
                  </div>
                </div>

                <div class="lg:inset-y-0 lg:right-0 lg:w-1/2 my-4">
                  <img
                    class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                    src=".././office.webp"
                    alt=""
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl text-gray-700 mx-auto ">
                      PRISE DE CONTACT POUR UN SERVICE
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <div class="w-full max-w-full mx-auto p-8">
                      <div class="bg-white rounded-lg shadow-lg p-6 border-2">
                        <h2 class="text-lg font-medium text-gray-700 mb-6">
                          INFORMATIONS DU CLIENT
                        </h2>
                        <div>
                          {/* <p class="w-48 p-4 bg-black text-center hover:bg-black text-white font-medium py-3 rounded-lg focus:outline-none">
                            249.999FCFA HT
                          </p> */}
                          <p class="text-center p-2 -mb-4 text-red-700">
                            {errormsg}{" "}
                          </p>
                          <p class="text-center p-2 -mb-4 text-green-500">
                            {succesMsg}{" "}
                          </p>
                          <div class="grid grid-cols-2 gap-6 mt-4">
                            <div class="col-span-2 sm:col-span-1">
                              <label
                                for="card-number"
                                class="block text-sm font-medium text-gray-700 mb-2"
                              >
                                Nom{" "}
                              </label>
                              <input
                                type="text"
                                name="lastname"
                                id="lastname"
                                value={userLastname}
                                placeholder="ZORO"
                                class="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                              />
                            </div>
                            <div class="col-span-2 sm:col-span-1">
                              <label
                                for="expiration-date"
                                class="block text-sm font-medium text-gray-700 mb-2"
                              >
                                Prénom(s)
                              </label>
                              <input
                                type="text"
                                name="firsttname"
                                value={userFisrtname}
                                id="firsttname"
                                placeholder="Andrey "
                                class="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                              />
                            </div>
                            <div class="col-span-2 sm:col-span-1">
                              <label
                                for="cvv"
                                class="block text-sm font-medium text-gray-700 mb-2"
                              >
                                E-mail
                              </label>
                              <input
                                type="email"
                                name="email"
                                id="email"
                                value={userEmail}
                                placeholder="exemple@exemple.com"
                                class="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                              />
                            </div>
                            <div class="col-span-2 sm:col-span-1">
                              <label
                                for="card-holder"
                                class="block text-sm font-medium text-gray-700 mb-2"
                              >
                                Téléphone whatSapp
                              </label>
                              <input
                                name="userPhone"
                                id="userPone"
                                value={userPhone}
                                class="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                              />
                            </div>
                          </div>

                          <div id="box" class="col-span-2 mt-2 sm:col-span-1">
                            <label
                              for="card-holder"
                              class="block text-sm font-medium text-gray-700 mb-2"
                            >
                              Objet
                            </label>
                            <input
                              type="text"
                              name="userObject"
                              value={userObject}
                              onChange={(e) => setUserObject(e.target.value)}
                              id="userObject"
                              placeholder="Demande de création de mon site web"
                              class="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                            />
                          </div>
                          <div id="box" class="col-span-2 mt-2 sm:col-span-1">
                            <label
                              for="card-holder"
                              class="block text-sm font-medium text-gray-700 mb-2"
                            >
                              Message
                            </label>
                            <textarea
                              type="text"
                              name="userMessage"
                              value={userMessage}
                              onChange={(e) => setUserMessage(e.target.value)}
                              id="userMessage"
                              placeholder="Bonjour !"
                              class="w-full py-8 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                            />
                          </div>

                          <div class="mt-8">
                            <button
                              enable={loading}
                              onClick={() => {
                                Onsubmit();
                              }}
                              class="w-full bg-indigo-500 hover:bg-black text-white font-medium py-3 rounded-lg focus:outline-none"
                            >
                              {" "}
                              {loading ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6 animate-spin mx-auto"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                                  />
                                </svg>
                              ) : (
                                "Envoyer"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Fermer
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </div>
      <Dashfooter />
    </>
  );
}

export default ContactSupport;

import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import Notifications from "./Notifications";
import "./Appdash.css";

function Dasheader() {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("uuid") !== null
  );

  const [userfirstname, setUserfirstname] = useState("");
  const [userlastname, setUserlastname] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const isAuthenticated = localStorage.getItem("uuid");
  const [notificationsData, setNotificationsData] = useState([]);

  // console.log(userID, isAuthenticated);
  // Get User
  useEffect(() => {
    setLoading(true);
    axios
      .get(process.env.React_App_base_url + `users/` + userID, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${isAuthenticated}`,
        },
      })
      .then(async (res) => {
        // console.log(res);

        const email = res.data.data.email;

        //  console.log("key", email);
        axios
          .get(process.env.React_App_base_url + `notifications/`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              Authorization: `Bearer ${isAuthenticated}`,
            },
          })
          .then((res) => {
            // console.log(res);
            const NotificationsData = res.data.Notification;
            //  DataFilter;

            const filteredNotifications = NotificationsData.filter(
              (notifications) =>
                notifications["useremail"] === email &&
                notifications["viewstatus"] === "1"
            );
            setNotificationsData(filteredNotifications.length);

            //  console.log(filteredNotifications, notificationsData);
            //  console.log(filtered);
          })
          .finally(() => {
            setLoading(false);
          });
      });
  }, []);

  useEffect(
    () => {
      const timer = setTimeout(() => {
        localStorage.clear();
        setLoggedIn(false);
        window.location.href = "../login";
      }, 1000000);
      return () => clearTimeout(timer);
    },
    1000000,
    []
  );
  //  User inactivity

  function Userinactivity() {
    var time;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(handleLogout, 100000000);
    }
  }

  Userinactivity();

  let TokenStatic = "HDHYI67!UY~@LJHF9H9W6L4";
  let navigate = useNavigate();

  //Check if user Login

  if (!isAuthenticated) {
    navigate("../login", { replace: true });
  }

  if (isAuthenticated) {
    let CheckUserlogged = localStorage.getItem("reactjs");

    if (atob(CheckUserlogged) !== TokenStatic) {
      navigate("../login", { replace: true });
    }
  }

  function checkUserExist() {
    if (!loggedIn) {
      // if the user is not logged in, redirect to the login page
      window.location.href = "../login";
      return null;
    }
  }

  useEffect(() => {
    checkUserExist();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      let StateId = atob(localStorage.getItem("isLoggedIn"));
      // console.log(StateId, isAuthenticated);

      if (StateId) {
        axios
          .get(process.env.React_App_base_url + `users/` + StateId, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              Authorization: `Bearer ${isAuthenticated}`,
            },
          })
          .then((res) => {
            //console.log(res);
            setUserfirstname(res.data.data.firstname);
            setUserlastname(res.data.data.lastname);

            //console.log(res.data);
            // let getOpt = res.data.NewData.codeOtp;
            // console.log(getOpt);
          });
      }
    }
  }, []);

  const handleLogout = () => {
    // clear the token from local storage and set the user as logged out
    // localStorage.removeItem("uuid");
    localStorage.clear();
    setLoggedIn(false);
    window.location.href = "../login";
    return null;
  };

  //Check if user Login

  return (
    <>
      <div class="fixed bg-blue-300 text-blue-800 px-10 py-1 z-10  w-full">
        <div class="flex items-center justify-between py-2 text-5x1">
          <a
            href=".././dashboard/homedash"
            class="flex font-bold text-blue-900 text-xl"
          >
            <img
              className=" inset-0 h-full w-24 object-cover"
              src="https://5sender.com/5Logo2.png"
              alt=""
            />
          </a>
          <div class="flex items-center text-gray-500">
            {/*   TABLEAU<span class="text-yellow-300 ">DE BORD</span>*/}
            <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <a
                href="../../dashboard/notifications"
                class="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span class="sr-only">View notifications</span>
                <svg
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                  />
                </svg>
              </a>
              <a
                href="../../dashboard/notifications"
                class="inset-0 object-right-top -mt-4 mr-6"
              >
                <div class="inline-flex items-center -ml-4 px-1.5 py-0.5 border-2 border-white rounded-full text-xs font-semibold leading-4 bg-red-500 text-white">
                  {notificationsData}
                </div>
              </a>

              <div onMouseLeave={() => setOpen(false)} class="relative ml-3">
                <div>
                  <button
                    onMouseOver={() => setOpen(true)}
                    class="flex bg-none text-sm  focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="rounded-full h-12 w-12 text-yellow-400"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none" stroke="currentColor" stroke-width="1.5">
                        <path d="M11.607 2.342a.6.6 0 0 1 .787 0l1.948 1.692a.6.6 0 0 0 .445.145l2.572-.224a.6.6 0 0 1 .636.463l.582 2.514a.6.6 0 0 0 .275.38l2.212 1.33a.6.6 0 0 1 .243.748l-1.008 2.376a.6.6 0 0 0 0 .468l1.008 2.376a.6.6 0 0 1-.243.749l-2.212 1.33a.6.6 0 0 0-.275.379l-.582 2.514a.6.6 0 0 1-.636.463l-2.572-.224a.6.6 0 0 0-.445.144l-1.949 1.693a.6.6 0 0 1-.787 0l-1.948-1.693a.6.6 0 0 0-.445-.144l-2.572.224a.6.6 0 0 1-.636-.463l-.582-2.514a.6.6 0 0 0-.275-.38l-2.212-1.33a.6.6 0 0 1-.243-.748l1.008-2.376a.6.6 0 0 0 0-.468L2.693 9.39a.6.6 0 0 1 .243-.749l2.212-1.33a.6.6 0 0 0 .275-.379l.582-2.514a.6.6 0 0 1 .636-.463l2.572.224a.6.6 0 0 0 .445-.145l1.949-1.692Z" />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m9 13l2 2l5-5"
                        />
                      </g>
                    </svg>
                    <span className="font-bold px-2 text-white">
                      {userfirstname}
                    </span>
                    <span className="font-bold mt-4 -ml-12 text-white">
                      {" "}
                      {userlastname}
                    </span>
                  </button>
                  <ul
                    className={`absolute right-0 w-40 py-2 mt-2 rounded-lg shadow-xl ${
                      open ? "block" : "hidden"
                    }`}
                  >
                    <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                      <a
                        href="./homedash"
                        class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill="currentColor"
                            d="M4 13h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm10 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 4v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1z"
                          />
                        </svg>
                        <span class="flex-1 ml-3 whitespace-nowrap">
                          Tableau de bord
                        </span>
                      </a>
                    </li>
                    <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                      <a
                        href="./profil"
                        class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                      >
                        <svg
                          class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill="currentColor"
                            d="M13.5 0h-12C.675 0 0 .675 0 1.5v13c0 .825.675 1.5 1.5 1.5h12c.825 0 1.5-.675 1.5-1.5v-13c0-.825-.675-1.5-1.5-1.5zM13 14H2V2h11v12zM4 9h7v1H4zm0 2h7v1H4zm1-6.5a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 5 4.5zM7.5 6h-2C4.675 6 4 6.45 4 7v1h5V7c0-.55-.675-1-1.5-1z"
                          />
                        </svg>
                        <span class="flex-1 ml-3 whitespace-nowrap">
                          Profil
                        </span>
                      </a>
                    </li>
                    <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                      <a
                        href="./historiquesms"
                        class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                      >
                        <svg
                          class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="none"
                            stroke="currentColor"
                            d="M6 8.5h12M6 13h6M23.5 2H23c-3 .5-8 .75-11 .75S4 2.5 1 2H.5v21.5h.25l.154-.154A15.692 15.692 0 0 1 12 18.75c3 0 8 .25 11 .75h.5V2Z"
                          />
                        </svg>
                        <span class="flex-1 ml-3 whitespace-nowrap">
                          Mes envois
                        </span>
                      </a>
                    </li>
                    <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                      <a
                        href="#"
                        onClick={handleLogout}
                        class="flex items-center p-2 text-gray-900 rounded-lg bg-white dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                      >
                        <svg
                          class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 18 16"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
                          />
                        </svg>
                        <span class="flex-1 ml-3 whitespace-nowrap ">
                          Se déconnecter
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="bg-center bg-cover bg-no-repeat rounded-full inline-block h-12 w-12 ml-2  mb-4"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dasheader;

function Dashfooter() {
  return (
    <>
      <footer class="bg-gray-800 text-white py-4 mb-0  px-3 ">
        <div class="container mx-auto flex flex-wrap items-center justify-between">
          <div class="w-full md:w-1/2 md:text-center md:mb-0 mb-8">
            <p class="text-xs text-gray-400 md:text-sm">
              Copyright 2023 &copy; All Rights Reserved 5Sender
            </p>
          </div>
          <div class="w-full md:w-1/2 md:text-center md:mb-0 mb-8">
            <ul class="list-reset flex justify-center flex-wrap text-xs md:text-sm gap-3">
              <li>
                <a href=".././dashboard/profil" class="text-gray-400 hover:text-white">
                  Profil
                </a>
              </li>
              <li class="mx-4">
                <a
                  href="/confidcooky"
                  target="_blank"
                  class="text-gray-400 hover:text-white"
                >
                  Cookies et confidentialité
                </a>
              </li>
              <li>
                <a
                  href="/conditionsgeneralesventes"
                  target="_blank"
                  class="text-gray-400 hover:text-white"
                >
                  Conditions générales de ventes
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Dashfooter;

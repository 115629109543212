
import Header from "./Header";
import Footer from "./Footer";
import { useState } from "react";
import sendEmail from "./Dashboard/SendEmail";
 import Wasender from "./Dashboard/Wasender";

function Promosms() {

  const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errormsg, setErrormsg] = useState("");
    const [succesMsg, setSuccessMsg] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userFisrtname, setUserFisrtname] = useState("");
    const [userLastname, setUserLastname] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [userMessage, setUserMessage] = useState("");
    const [userObject, setUserObject] = useState("Formule Business | 2.400 crédits sms illimité à 7.000 FCFA ");

      function Onsubmit() {
        setLoading(true);
        // Perform operation and then set loading to false
        setTimeout(() => {
          setLoading(false);
        }, 500);

        if (userMessage === "" || userObject === "" || userFisrtname==="" || userLastname==="" || userPhone==="" || userEmail==="" ) {
          setErrormsg("Veuillez remplir tous les champs ! *");
          setSuccessMsg("");
        }

        if (userMessage !== "" && userObject !== "" && userFisrtname !== "" && userLastname !== "" && userPhone !== "" && userEmail !== "" ) {
          // console.log(
          //   userFisrtname,
          //   userLastname,
          //   userPhone,
          //   userEmail,
          //   userObject,
          //   userMessage
          // );
          setSuccessMsg(
            "Votre message a été envoyé avec succès. Nous vous recontacterons sous peu 👋🏽 !"
          );
          // sendEmail(
          //   userFisrtname + " " + userLastname,
          //   userEmail,
          //   userPhone,
          //   userObject,
          //   userObject + " \n " + userMessage
          // );
          let newCommande =
            userFisrtname +
            " " +
            userLastname +" "+
            "Promo 200000 crédits SMS" +
            " , Infos client: " +
            userEmail +
            ", " +
            userPhone +
            " , " +
            userObject +
            " , " +
            userMessage;

           Wasender(newCommande);

          setUserMessage("");
          setErrormsg("");

          setTimeout(() => {
            setUserEmail("");
            setUserFisrtname("");
            setUserLastname("");
            setUserPhone("");
            setSuccessMsg("");
          }, "8000");
        }
      }

  return (
    <>
      <Header />
      <div class="mx-auto w-full  -mb-16 px-4 bg-yellow-400 sm:px-6 lg:px-8 pt-20 pb-16 text-center lg:pt-32">
        <section class="bg-blue-600 py-20 -mt-24">
          <div class="container mx-auto px-4">
            <div class="flex flex-col md:flex-row items-center justify-between">
              <div class="md:w-1/2 mb-8 md:mb-0">
                <span class="text-yellow-500 text-3xl border-b-2 rounded-lg font-bold uppercase">
                  Spéciale Promo SMS illimité
                </span>
                <h2 class="my-4 font-bold   sm:text-xl ">
                  Jusqu'au <span class="text-yellow-400">31 octobre 2023</span>
                </h2>
                <h2 class="my-4 font-bold   sm:text-xl ">
                  Réussir sa{" "}
                  <span class="text-yellow-400">
                    campagne SMS Marketing en un clic !
                  </span>
                </h2>

                <h1 class="text-white font-bold text-5xl leading-tight mb-6 p-8">
                  400 Crédits SMS gratuits à partir de la formule Business 📢🎈
                </h1>
                <p class="w-80 mx-auto px-6  mb-4 -mt-8 text-base font-semibold text-center text-white transition duration-200 ease-in bg-yellow-400 rounded-lg shadow-md hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 ">
                  #Durée de validité des SMS illimité
                </p>
                <p class="text-white text-xl mb-8">
                  Plus de crédits sms encore et encore pour un coût qui défit toute
                  concurrence !
                </p>
                <button
                  onClick={() => setShowModal(true)}
                  class="px-6 py-3 bg-white text-blue-600 font-bold rounded-full hover:bg-blue-700 transition duration-200"
                >
                  Commander maintenant →
                </button>
              </div>
              <div class="md:w-1/2">
                <img
                  src=".././promo.png"
                  alt="Coffee beans"
                  class="w-full rounded-lg shadow-lg"
                />
              </div>
            </div>
          </div>
        </section>

        {showModal ? (
          <>
            <div className="justify-center items-center -mb-24 -mt-24 flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    {/* <h3 className="text-3xl text-gray-700 mx-auto text-center justify-center">
                      🤝
                    </h3> */}
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <div class="bg-white rounded-lg shadow-lg p-6 border-2 mb-8">
                      <h2 class="text-lg font-medium text-gray-700 mb-6 p-4 -mt-8">
                        INFORMATIONS DU CLIENT
                      </h2>
                      <div>
                        {/* <p class="w-48 p-4 bg-black text-center hover:bg-black text-white font-medium py-3 rounded-lg focus:outline-none">
                            249.999FCFA HT
                          </p> */}
                        <p class="text-center mb-8 -mt-8 text-red-700">
                          {errormsg}{" "}
                        </p>
                        <p class="text-center p-2 -mt-12 -mb-4 text-green-500">
                          {succesMsg}{" "}
                        </p>
                        <div class="grid grid-cols-2 gap-6 mt-4">
                          <div class="col-span-2 sm:col-span-1">
                            <label
                              for="card-number"
                              class="block text-sm font-medium text-gray-700 mb-2"
                            >
                              Nom{" "}
                            </label>
                            <input
                              type="text"
                              name="lastname"
                              id="lastname"
                              onChange={(e) => setUserLastname(e.target.value)}
                              value={userLastname}
                              placeholder="ZORO"
                              class="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                            />
                          </div>
                          <div class="col-span-2 sm:col-span-1">
                            <label
                              for="expiration-date"
                              class="block text-sm font-medium text-gray-700 mb-2"
                            >
                              Prénom(s)
                            </label>
                            <input
                              type="text"
                              name="firsttname"
                              onChange={(e) => setUserFisrtname(e.target.value)}
                              value={userFisrtname}
                              id="firsttname"
                              placeholder="Andrey "
                              class="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                            />
                          </div>
                          <div class="col-span-2 sm:col-span-1">
                            <label
                              for="cvv"
                              class="block text-sm font-medium text-gray-700 mb-2"
                            >
                              E-mail
                            </label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              onChange={(e) => setUserEmail(e.target.value)}
                              value={userEmail}
                              placeholder="exemple@exemple.com"
                              class="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                            />
                          </div>
                          <div class="col-span-2 sm:col-span-1">
                            <label
                              for="card-holder"
                              class="block text-sm font-medium text-gray-700 mb-2"
                            >
                              Téléphone whatSapp (ex: 22901020304)
                            </label>
                            <input
                              name="userPhone"
                              id="userPone"
                              onChange={(e) => setUserPhone(e.target.value)}
                              value={userPhone}
                              placeholder="22501020304"
                              class="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                            />
                          </div>
                        </div>

                        <div id="box" class="col-span-2 mt-2 sm:col-span-1">
                          <label
                            for="card-holder"
                            class="block text-sm font-medium text-gray-700 mb-2"
                          >
                            Promo SMS illimité
                          </label>
                          <input
                            type="text"
                            name="userObject"
                            disabled
                            value={userObject}
                            onChange={(e) => setUserObject(e.target.value)}
                            id="userObject"
                            placeholder="Demande de création de mon site web"
                            class="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                          />
                        </div>
                        <div id="box" class="col-span-2 mt-2 sm:col-span-1">
                          <label
                            for="card-holder"
                            class="block text-sm font-medium text-gray-700 mb-2"
                          >
                            Message
                          </label>
                          <input
                            type="text"
                            name="userMessage"
                            value={userMessage}
                            onChange={(e) => setUserMessage(e.target.value)}
                            id="userMessage"
                            placeholder="Je souhaite avoir la promo en cours. Merci 🖐  !"
                            class="w-full py-8 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                          />
                        </div>

                        <div class="mt-8">
                          <button
                            enable={loading}
                            onClick={() => {
                              Onsubmit();
                            }}
                            class="w-full bg-indigo-500 hover:bg-black text-white font-medium py-3 rounded-lg focus:outline-none"
                          >
                            {" "}
                            {loading ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 animate-spin mx-auto"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                                />
                              </svg>
                            ) : (
                              "Envoyer"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center -mt-8 justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="text-red-500 bg-yellow-400 hover:text-white font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}
                      >
                        Fermer
                      </button>
                    </div>
                  </div>
                  {/*footer*/}
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </div>
      <Footer />
    </>
  );
}

export default Promosms;

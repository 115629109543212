import { useEffect, useState } from "react";

function Footer() {
  const [showModal, setShowModal] = useState();
  useEffect(() => {
    setTimeout(() => {
      function checkCookie() {
        let checkCoo = localStorage.getItem("Cookies");
        if (checkCoo === "accepted") {
          setShowModal(false);
        }
        if (checkCoo !== "accepted") {
          setShowModal(true);
        }
      }

      checkCookie();
    }, 1000);
  });

  function Cookies() {
    localStorage.setItem("Cookies", "accepted");
    console.log("Yes");
    setShowModal(false);
  }

  return (
    <>
      <footer class="bg-gray-800 text-white py-4 px-3 mt-16">
        <div class="container mx-auto flex flex-wrap items-center justify-between">
          <div class="flex w-full md:w-1/2 md:text-center md:mb-0 mb-8">
            <img
              class="w-auto h-16 sm:h-16"
              src="https://5sender.com/5Logo.png"
              loading="lazy"
              width="8"
              height="8"
            />

            <p
              id="google_translate_element"
              class="text-xs text-gray-400 md:text-sm mt-8"
            >
              Copyright 2023 &copy; All Rights Reserved@FiveSender
            </p>
          </div>
          <div class="w-full md:w-1/2 md:text-center md:mb-0 mb-8">
            <ul class="list-reset flex justify-center flex-wrap text-xs md:text-sm gap-3">
              <li></li>
              <li>
                <a href="../#contact" class="text-gray-400 hover:text-white">
                  Contact
                </a>
              </li>
              <li class="mx-4">
                <a
                  target="_blank"
                  href="./confidcooky"
                  class="text-gray-400 hover:text-white"
                >
                  Cookies et Confidentialité
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="./conditionsgeneralesventes"
                  class="text-gray-400 hover:text-white"
                >
                  Conditions générales de ventes
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      {showModal ? (
        <div class="fixed bottom-0 right-0 mb-4 mr-4 w-64">
          <div class="bg-white rounded-lg shadow-lg p-4">
            <div class="flex items-center justify-between mb-2">
              <div class="flex items-center">
                <img src=".././cookie.svg" alt="Cookie" class="h-6 w-6 mr-2" />
                <a
                  href="./confidcooky"
                  target="_blank"
                  class="text-gray-700 font-bold text-sm"
                >
                  Politique de cookies
                </a>
              </div>
              <button
                onClick={() => setShowModal(false)}
                class="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <svg
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <p class="text-gray-600 text-justify text-sm">
              Nous utilisons des cookies pour améliorer votre navigation. En
              continuant à visiter ce site, vous acceptez notre utilisation des
              cookies.
            </p>
            <button
              type="button"
              onClick={() => Cookies()}
              class="mt-4 bg-orange-500 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded"
            >
              OK
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Footer;

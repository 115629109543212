import Dasheader from "./Dasheader";
import Dashfooter from "./Dashfooter";
import { useState, useEffect } from "react";
import Sidebar from "./Sibebar";
import axios from "axios";

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const isAuthenticated = localStorage.getItem("uuid");
  const [notificationsData, setNotificationsData] = useState([]);
  const [limit, setLimit] = useState(5);

  // Get Notification
  useEffect(() => {
    setLoading(true);
    axios
      .get(process.env.React_App_base_url + `users/` + userID, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${isAuthenticated}`,
        },
      })
      .then(async (res) => {
        //console.log(res);

        const email = res.data.data.email;

        //  console.log("key", email);
        axios
          .get(process.env.React_App_base_url + `notifications/`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              Authorization: `Bearer ${isAuthenticated}`,
            },
          })
          .then((res) => {
            // console.log(res);
            const NotificationsData = res.data.Notification;
            //  DataFilter;

            const filteredNotifications = NotificationsData.filter(
              (notifications) =>
                notifications["useremail"] === email &&
                notifications["viewstatus"] === "1"
            );
            setNotificationsData(filteredNotifications);

            //  console.log(filteredNotifications, notificationsData);
            //  console.log(filtered);
            //  console.log(filteredNotifications);
          })
          .finally(() => {
            setLoading(false);
          });
      });
  }, []);

  return (
    <>
      <Dasheader />

      <div className="bg-yellow-400 -mt-8">
        <div class="bg-yellow-400 h-screen p-16 mb-0  ">
          <div class="flex flex-col gap-3 w-1/2   mx-auto ">
            <Sidebar />
            <h1 class="flex mx-auto mt-16 text-4xl text-center text-white font-semibold mb-6">
              Notifications
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7c1.3 3 4.1 4.8 7.3 4.8c66.3 0 116-31.8 140.6-51.4c32.7 12.3 69 19.4 107.4 19.4c141.4 0 256-93.1 256-208S397.4 32 256 32zM128.2 304H116c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h12.3c6 0 10.4-3.5 10.4-6.6c0-1.3-.8-2.7-2.1-3.8l-21.9-18.8c-8.5-7.2-13.3-17.5-13.3-28.1c0-21.3 19-38.6 42.4-38.6H156c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8h-12.3c-6 0-10.4 3.5-10.4 6.6c0 1.3.8 2.7 2.1 3.8l21.9 18.8c8.5 7.2 13.3 17.5 13.3 28.1c.1 21.3-19 38.6-42.4 38.6zm191.8-8c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8v-68.2l-24.8 55.8c-2.9 5.9-11.4 5.9-14.3 0L224 227.8V296c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8V192c0-8.8 7.2-16 16-16h16c6.1 0 11.6 3.4 14.3 8.8l17.7 35.4l17.7-35.4c2.7-5.4 8.3-8.8 14.3-8.8h16c8.8 0 16 7.2 16 16v104zm48.3 8H356c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h12.3c6 0 10.4-3.5 10.4-6.6c0-1.3-.8-2.7-2.1-3.8l-21.9-18.8c-8.5-7.2-13.3-17.5-13.3-28.1c0-21.3 19-38.6 42.4-38.6H396c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8h-12.3c-6 0-10.4 3.5-10.4 6.6c0 1.3.8 2.7 2.1 3.8l21.9 18.8c8.5 7.2 13.3 17.5 13.3 28.1c.1 21.3-18.9 38.6-42.3 38.6z"
                />
              </svg>
            </h1>

            {notificationsData
              .slice(0, limit ? limit : notificationsData.length)
              .map((notification) => (
                <div className=" ">
                  <div class="relative border border-white rounded-lg shadow-lg ">
                    {/* {showModal ? ( */}
                    <div class="flex border-2 rounded-lg items-center p-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        className="text-white "
                      >
                        <path
                          fill="currentColor"
                          d="M21 6.5c0 1.93-1.57 3.5-3.5 3.5S14 8.43 14 6.5S15.57 3 17.5 3S21 4.57 21 6.5m-2 5.29c-.5.13-1 .21-1.5.21A5.51 5.51 0 0 1 12 6.5c0-1.47.58-2.8 1.5-3.79A1.93 1.93 0 0 0 12 2c-1.1 0-2 .9-2 2v.29C7.03 5.17 5 7.9 5 11v6l-2 2v1h18v-1l-2-2v-5.21M12 23c1.11 0 2-.89 2-2h-4a2 2 0 0 0 2 2Z"
                        />
                      </svg>

                      <div key={notification.id} class="ml-3 overflow-hidden">
                        <a
                          href={notification.lienRef}
                          target="_blank"
                          class="font-medium text-white hover:text-yellow-500"
                        >
                          {notification.notificationtitle}
                        </a>
                        <p class="max-w-xs text-sm text-gray-500 truncate">
                          Veuillez cliquer pour télécharger votre facture
                          d'achat
                        </p>
                      </div>

                      <button
                        onClick={() => setShowModal(false)}
                        class="absolute p-1 bg-gray-100 border border-gray-300 rounded-full -top-1 -right-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-3 h-3"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                    {/* ) : null} */}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <Dashfooter />
    </>
  );
}

export default Notifications;

import Dasheader from "./Dasheader";
import Dashfooter from "./Dashfooter";
import { useState, useEffect } from "react";
import Sidebar from "./Sibebar";
import axios from "axios";
import copy from "copy-to-clipboard";

function Integrationapi() {
  const [msg, setMsg] = useState("");
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const ONE_DAY = 24 * 60 * 60 * 1000;
  const [token, setToken] = useState("");
  const [userplan, setUserplan] = useState("Bypass");
  const [expireToken, setExpireToken] = useState(Date.now());
  const [expired, setExpired] = useState();
  const [commande, setCommande] = useState();
  const isAuthenticated = localStorage.getItem("uuid");
  let userID = atob(localStorage.getItem("isLoggedIn"));

  useEffect(() => {
    if (isAuthenticated) {
      axios
        .get(process.env.React_App_base_url + `users/` + userID, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticated}`,
          },
        })
        .then(async (res) => {
          // console.log(res);
          const email = res.data.data.email;
          const userToken = res.data.data.apitoken;
          setToken(userToken);
        });
    }
  }, []);

  const copyToClipboard = (e) => {
    copy(e);
    if (e && e === token) {
      //console.log(e);
      setAlert(" ✔️ ");
    }
  };

  function TokenGenerate() {
    var length = 100;
    var expiredIn = 1;
    setLoading(true);
    // Perform operation and then set loading to false
    setTimeout(() => {
      setLoading(false);
    }, 500);

    if (userplan === "ByPass") {
      expiredIn = 365;
    }
    var chars =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
    var charLength = chars.length;
    var result = "";
    var ExpiredDate = "";
    for (var i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * charLength));
      ExpiredDate = Date.now() + expiredIn * ONE_DAY;
      setToken(result);
      //console.log(ExpiredDate);
    }

    if (token != "") {
      const data = JSON.stringify({
        apitoken: result,
      });
      let userID = atob(localStorage.getItem("isLoggedIn"));
      axios
        .patch(process.env.React_App_base_url + `users/` + userID, data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticated}`,
          },
        })
        .then((res) => {
          // console.log(res)
          setLoading(false);
          setMsg(
            "Votre token a été généré avec succès 👌 ! Cliquez sur le bouton noir pour copier. "
          );
        });

      // if (ExpiredDate < Date.now()) {
      //   alert("Your license has been expired!");
      //   console.log("License is expired.");
      // } else {
      //   setMsg("Vous avez une license valide !");
      // }
    }

    //setExpireToken(now);
    //console.log(now);
    // console.log(Date.now());
  }

  return (
    <>
      <Dasheader />
      <div className="bg-yellow-400 -mt-8">
        <div class="bg-yellow-400  mb-0  ">
          <div class="flex flex-col gap-3 w-1/2   mx-80  ">
            <Sidebar />
            <h1 class="text-4xl text-white text-center mt-4 font-semibold mb-6">
              Intégration API
            </h1>
            <div class="api flex flex-col md:flex-row p-8">
              <div class="testapi flex flex-col gap-2 w-full">
                <label class="font-semibold text-lg text-center">
                  Sms Express
                </label>
                <p
                  rows="14"
                  class=" h-96 p-4 border-2 text-gray-100 rounded-lg bg-gray-900 outline-none"
                >
                  <span>
                    {" "}
                    BaseUrl:{" "}
                    {process.env.React_App_base_url +
                      "sendersms/5sender/api"}{" "}
                  </span>
                  <br></br>
                  <br></br>
                  {""}
                  <span> method: 'post' </span> <br></br>
                  <span> Content-Type: application/json </span> <br></br>
                  <span> Cache-Control: no-cache </span> <br></br>
                  <span>
                    fullnameto:FANGA Félix,<br></br> phoneto: "+22780764524",
                    <br></br>
                    "title": "Rappel",<br></br>
                    "senderkey": "AZPUY2R-Y9U5O-OPONE-EERJQ-GLDY4",<br></br>{" "}
                    "message": "Bonjour !",<br></br> "createdDate": "21/07/2023"
                  </span>{" "}
                  <br></br>
                </p>
              </div>

              <div class="api flex justify-center items-center md:flex-col gap-4 flex-wrap mx-auto md:my-auto p-4 shrink-0">
                <button class="h-12 text-white bg-blue-300 rounded-md px-4 py-2 hover:bg-violet-800 font-semibold text-xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <g
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="m11 9l2-.5l.5 2" />
                      <path d="M13 8.5A6.76 6.76 0 0 1 7 13h0a6 6 0 0 1-5.64-3.95M3 5l-2 .5l-.5-2" />
                      <path d="M1 5.5C1.84 3.2 4.42 1 7 1h0a6 6 0 0 1 5.64 4" />
                    </g>
                  </svg>
                </button>
              </div>

              <div class="api flex flex-col gap-2 w-full">
                <label class="font-semibold text-lg text-center">
                  Sms campagne
                </label>
                <p
                  rows="14"
                  class=" h-96 p-4 border-2 text-gray-100 rounded-lg bg-gray-900 outline-none"
                >
                  <span>
                    {" "}
                    BaseUrl:{" "}
                    {process.env.React_App_base_url +
                      "campagnesms/5sender/api/"}{" "}
                  </span>
                  <br></br>
                  <br></br>
                  {""}
                  <span> method: 'post' </span> <br></br>
                  <span> Content-Type: application/json </span> <br></br>
                  <span> Cache-Control: no-cache </span> <br></br>
                  <span>
                    fullnameto:FANGA Félix,<br></br> phoneto: "+22780764524;
                    +22801020304",
                    <br></br>
                    "title": "Rappel",<br></br>
                    "senderkey": "AZPUY2R-Y9U5O-OPONE-EERJQ-GLDY4",<br></br>{" "}
                    "message": "Bonjour !",
                    <br></br> "createdDate": "21/07/2023"
                  </span>{" "}
                  <br></br>
                </p>
              </div>
              <div class="api flex justify-center items-center md:flex-col gap-4 flex-wrap mx-auto md:my-auto p-4 shrink-0">
                <button class="h-12 text-white bg-blue-300 rounded-md px-4 py-2 hover:bg-violet-800 font-semibold text-xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <g
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="m11 9l2-.5l.5 2" />
                      <path d="M13 8.5A6.76 6.76 0 0 1 7 13h0a6 6 0 0 1-5.64-3.95M3 5l-2 .5l-.5-2" />
                      <path d="M1 5.5C1.84 3.2 4.42 1 7 1h0a6 6 0 0 1 5.64 4" />
                    </g>
                  </svg>
                </button>
              </div>
              <div class="api flex flex-col gap-2 w-full">
                <label class="font-semibold text-lg text-center">
                  Response
                </label>
                <p
                  rows="14"
                  class=" h-96 p-4 border-2 text-gray-100 w-80 rounded-lg bg-gray-900 outline-none"
                >
                  <span>
                    {" "}
                    HTTP Status Code : 200 OK <br></br>
                    <br></br>
                    {""}
                  </span>
                  <br></br>
                  <span> application/json </span> <br></br>
                  {""}
                  <span> "status": "success", </span> <br></br>
                  <span> "message": "Sms send successfully", </span> <br></br>
                  <span>
                    {" "}
                    "SmsId": "d20f490a-80f6-4350-b1a5-6abd9fc39441"
                  </span>{" "}
                  <br></br>
                  <span>"createdAt": "2023-08-18T22:27:35.239Z"</span> <br></br>
                </p>
              </div>
            </div>
          </div>
          <div class=" p-6">
            <div class="testapi hidden flex flex-col gap-2">
              <label class="font-semibold text-lg text-center">
                Sms Express
              </label>
              <span className="w-16">
                {" "}
                BaseUrl:{" "}
                {process.env.React_App_base_url + "sendersms/5sender/api"}{" "}
              </span>
              <p
                rows="14"
                class=" h-96 p-4 border-2 text-gray-100 rounded-lg bg-gray-900 outline-none"
              >
                <br></br>
                <br></br>
                {""}
                <span> method: 'post' </span> <br></br>
                <span> Content-Type: application/json </span> <br></br>
                <span> Cache-Control: no-cache </span> <br></br>
                <span>
                  fullnameto:FANGA Félix,<br></br> phoneto: "+22780764524",
                  <br></br>
                  "title": "Rappel",<br></br>
                  "senderkey": "AZPUY2R-Y9U5O-OPONE-EERJQ-GLDY4",<br></br>{" "}
                  "message": "Bonjour !",<br></br> "createdDate": "21/07/2023"
                </span>{" "}
                <br></br>
              </p>
            </div>

            <div class="testapi hidden flex flex-col gap-2">
              <label class="font-semibold text-lg text-center">
                Sms campagne
              </label>
              <span className="container text-sm">
                {" "}
                BaseUrl:{" "}
                {process.env.React_App_base_url +
                  "campagnesms/5sender/api/"}{" "}
              </span>
              <p
                rows="14"
                class=" h-96 p-4 border-2 text-gray-100 rounded-lg bg-gray-900 outline-none"
              >
                <br></br>
                <br></br>
                {""}
                <span> method: 'post' </span> <br></br>
                <span> Content-Type: application/json </span> <br></br>
                <span> Cache-Control: no-cache </span> <br></br>
                <span>
                  fullnameto:FANGA Félix,<br></br> phoneto: "+22780764524;
                  +22801020304",
                  <br></br>
                  "title": "Rappel",<br></br>
                  "senderkey": "AZPUY2R-Y9U5O-OPONE-EERJQ-GLDY4",<br></br>{" "}
                  "message": "Bonjour !",
                  <br></br> "createdDate": "21/07/2023"
                </span>{" "}
                <br></br>
              </p>
            </div>

            <div class="flex flex-wrap items-center w-full max-w-7xl p-5 mx-auto text-left border border-gray-500 rounded lg:flex-nowrap md:p-8 dark:border-gray-700">
              <div class="flex-1 w-full mb-5 ">
                <h3 class="mb-2 text-2xl font-bold text-gray-700 dark:text-gray-200">
                  Token d'accès
                </h3>
                <p class="text-gray-500 dark:text-gray-400 ">
                  Ce token est la clé d'accès au service sms depuis votre
                  application. Vous avez la possiblité de le regénérer en
                  cliquant sur le bouton [GENERER UN TOKEN]
                </p>
              </div>
              <div class="w-full px-1 flex-0 md:w-auto lg:w-2/3">
                <form>
                  <div class="flex flex-col sm:flex-row">
                    <input
                      type="text"
                      id="token"
                      name="token"
                      disabled
                      value={token}
                      class="flex-1 px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md sm:mr-5 focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-800 dark:text-white dark:placeholder-gray-400 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                    />
                    <button
                      type="button"
                      enable={loading}
                      onClick={() => {
                        TokenGenerate();
                      }}
                      class="inline-block mr-4 rounded bg-yellow-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#e4a11b] transition duration-150 ease-in-out hover:bg-warning-600 hover:shadow-[0_8px_9px_-4px_rgba(228,161,27,0.3),0_4px_18px_0_rgba(228,161,27,0.2)] focus:bg-warning-600 focus:shadow-[0_8px_9px_-4px_rgba(228,161,27,0.3),0_4px_18px_0_rgba(228,161,27,0.2)] focus:outline-none focus:ring-0 active:bg-warning-700 active:shadow-[0_8px_9px_-4px_rgba(228,161,27,0.3),0_4px_18px_0_rgba(228,161,27,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(228,161,27,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(228,161,27,0.2),0_4px_18px_0_rgba(228,161,27,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(228,161,27,0.2),0_4px_18px_0_rgba(228,161,27,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(228,161,27,0.2),0_4px_18px_0_rgba(228,161,27,0.1)]"
                    >
                      {" "}
                      {loading ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 animate-spin mx-auto"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                          />
                        </svg>
                      ) : (
                        "Générer un token"
                      )}
                    </button>
                    <button
                      onClick={() => copyToClipboard(token)}
                      type="button"
                      class="w-full px-6 py-4 mt-5 text-white bg-gray-800 rounded-md sm:mt-0 sm:w-auto whitespace-nowrap dark:bg-gray-700"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                      >
                        <path
                          fill="currentColor"
                          fill-rule="evenodd"
                          d="M1 9.5A1.5 1.5 0 0 0 2.5 11H4v-1H2.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5V4H5.5A1.5 1.5 0 0 0 4 5.5v7A1.5 1.5 0 0 0 5.5 14h7a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 12.5 4H11V2.5A1.5 1.5 0 0 0 9.5 1h-7A1.5 1.5 0 0 0 1 2.5v7Zm4-4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-7Z"
                          clip-rule="evenodd"
                        />
                      </svg>{" "}
                    </button>
                    {alert}{" "}
                  </div>
                </form>
                <div className="text-gray-700 p-2">{msg}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dashfooter />
    </>
  );
}

export default Integrationapi;

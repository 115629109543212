import emailjs from "emailjs-com";

const service_id = "service_gmail_2320!";
const template_id = process.env.React_App_Emailjs_templateId;
const user_id = process.env.React_App_Emailjs_userId;

const sendEmail = async (name, email,phone, object, message) => {
  try {
    const response = await emailjs.send(
      service_id,
      template_id,
      { name, email, phone, object, message },
      user_id
    );

    if (response.status === 200) {
      // console.log("Successfully sent message.");
    }
  } catch (error) {
    // console.error("Failed to send email. Error: ", error);
  }
};

export default sendEmail;

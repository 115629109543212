import Header from "./Header";
import Footer from "./Footer";
import { useState } from "react";
import validator from "validator";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Wapassresetsender from "./Dashboard/Wapassresetsender";

function Passrecovery() {
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userMail, setUserMail] = useState("");
  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");
  const [msg, setMsg] = useState("");
  const [userID, setUserID] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState(false);
  const [confirm_password, setConfirm_password] = useState(false);
  const navigate = useNavigate();

  function handleSubmit() {
    if (password === "" && confirm_password === "") {
      setAlert(
        "Veuillez saisir votre mot de passe et le confirmer dans le second champs !"
      );
    }
    if (password === "") {
      setAlert("Veuillez saisir votre mot de passe !");
    }
    if (confirm_password == "") {
      setAlert("Veuillez confirmer votre nouveau mot de passe !");
    }

     if (confirm_password !== password) {
       setAlert("Les deux mots de passe ne sont pas identiques !");
     }
    if(password.length >  12 || password.length < 8) {
      setAlert('Votre mot de passe doit être entre 8 à 12 caractères')
    }
    // console.log(password, confirm_password, userID);

    if(password !== "" && confirm_password !== "" && confirm_password===password)  {
      const data = JSON.stringify({
        password: password,
      });
      try {
        axios
          .patch(
            process.env.React_App_base_url + `users/updatePassword/` + userID,
            data,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            // console.log(res);
            setMsg("Votre mot de passe a été mise à jour avec succès 👌 ! ");

            Wapassresetsender(userPhone, userName, userMail, password);

            setTimeout(() => {
              navigate("../login");
            }, "4000");
          });
      } catch (err) {
        // console.log(err);
      }
    }
    
  }

  async function Onsubmit() {
    if (userEmail === "") {
      setError("Veuillez saisir votre adresse E-mail ⚠️");
    }

    if (userEmail !== "" && validator.isEmail(userEmail)) {
      try {
        //  setEmailError('Valid Email :)')
       await axios
          .get(process.env.React_App_base_url + "users/by/" + userEmail, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              //"Authorization": `Bearer ${localStorage.getItem("user-token")}`,
            },
          })
          .then(function (response) {
            // console.log(response.data);
            //console.log(userEmail);
            if (response.data.userData !==null) {
              let userInfos = response.data.userData;
              setUserName(userInfos.firstname + " " + userInfos.lastname);
              setUserPhone(userInfos.phone);
              setUserMail(userInfos.email);
            }

            let getResponse = response.data;
            if (getResponse.userData) {
              const responseEmail = response.data.userData.email;
              // console.log(responseEmail);
              if (
                getResponse.userData !== null &&
                responseEmail === userEmail
              ) {
                setUserID(getResponse.userData.id);
                setShowModal(true);
                // console.log("yes");
              }
            } else {
              // console.log("No");
              setError("Pas de compte lié à cette adresse email 🤷‍♂️");
            }
          });
      } catch (e) {
        // console.log(e);
      }
    } else {
      setError("Veuillez saisir une adresse E-mail valide 🚫 ");
    }

    // console.log(userEmail);
  }

  return (
    <>
      <Header />
      <main
        id="content"
        role="main"
        class="w-full  max-w-md mx-auto p-6 h-screen -mb-32"
      >
        <div class="mt-7 bg-white mt-48 rounded-xl shadow-lg dark:bg-gray-800 dark:border-gray-700 border-2 border-indigo-300">
          <div class="p-4 sm:p-7 ">
            <div class="text-center">
              <h1 className="font-bold text-xl">Mot de passe oublié ?</h1>
              <h1 class="block text-2xl font-bold text-gray-800 dark:text-white">
                Récupérer son compte
              </h1>
              <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
                Mot de passe retrouvé ?
                <a
                  class="text-blue-600 decoration-2 hover:underline font-medium"
                  href="./login"
                >
                  Se connecter
                </a>
              </p>
            </div>

            <div class="mt-5">
              <div>
                <div class="grid gap-y-4">
                  <div>
                    <p class="text-center text-red-400">{error}</p>

                    <label
                      for="email"
                      class="block text-sm font-bold ml-1 mb-2 dark:text-white"
                    >
                      Addresse E-mail
                    </label>
                    <div class="relative">
                      <input
                        type="email"
                        id="email"
                        value={userEmail}
                        onChange={(e) => {
                          setUserEmail(e.target.value);
                        }}
                        name="email"
                        class="py-3 px-4 block w-full border-2 border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 shadow-sm"
                        required
                        aria-describedby="email-error"
                      />
                    </div>
                    <p
                      class="hidden text-xs text-red-600 mt-2"
                      id="email-error"
                    >
                      E-mail invalide
                    </p>
                  </div>
                  <button
                    onClick={Onsubmit}
                    class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                  >
                    Soumettre
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {showModal ? (
        <div
          class="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      🔐
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <h3
                        class="text-base font-semibold leading-6 text-gray-900"
                        id="modal-title"
                      >
                        Veuillez créer votre nouveau mot de passe
                      </h3>
                      <div class="mt-2">
                        <p class="text-sm text-gray-500">
                          Vous êtes sûr de vouloir changer votre mot de passe ?
                        </p>
                      </div>
                      <p class="text-center text-red-400">{alert}</p>
                      <p class="text-center text-green-400">{msg}</p>
                      <div class="flex items-center mt-4 text-lg mb-6 md:mb-8">
                        <svg
                          class="absolute ml-32"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path d="m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z" />
                        </svg>
                        <labell>Nouveau mot de passe :</labell>
                        <input
                          type="password"
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          name={password}
                          id="password"
                          class="bg-gray-200 pl-12 py-2 rounded-lg md:py-4 focus:outline-none w-full"
                          placeholder="Password"
                        />
                      </div>
                      <div class="flex items-center mt-4 text-lg mb-6 md:mb-8">
                        <svg
                          class="absolute ml-32"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path d="m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z" />
                        </svg>
                        <labell>Répéter le mot de passe :</labell>
                        <input
                          type="text"
                          onChange={(e) => {
                            setConfirm_password(e.target.value);
                          }}
                          name={confirm_password}
                          id="confirm_password"
                          class="bg-gray-200 pl-12 rounded-lg py-2 md:py-4 focus:outline-none w-full"
                          placeholder="Password"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    class="inline-flex w-full justify-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 sm:ml-3 sm:w-auto"
                  >
                    Soumettre
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Footer />
    </>
  );
}

export default Passrecovery;

import Dasheader from "./Dasheader";
import Dashfooter from "./Dashfooter";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";

function Admindash() {
  const [ip, setIP] = useState("");
  const [country, setCountry] = useState("");
  const [userCredit, setUserCredit] = useState("");
  const [freecauris, setUserFreecauris] = useState("");
  const [usersms, setUserSms] = useState("");
  const isAuthenticated = localStorage.getItem("uuid");
  const [dataCamp, setDataCamp] = useState([]);
  const [incomingCamp, setIncomingCamp] = useState("");
  const [userKEY, setUerKEY] = useState("");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    //console.log(res.data);
    setIP(res.data.IPv4);
    setCountry(res.data.country_name);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      axios
        .get(process.env.React_App_base_url + `campagnesms/`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticated}`,
          },
        })
        .then((res) => {
          //console.log(res)
          let NewDate = Date.now();
          let Camp = res.data.Campagne;

          {
            Camp.map((value) => (
              <div key={value}>
                if({value.createdDate < NewDate})
                {setDataCamp(value.createdDate)}
              </div>
            ));
          }

          if (Camp.length > 9) {
            setIncomingCamp(Camp.length);
          }
          if (Camp.length < 9) {
            setIncomingCamp("0" + Camp.length);
          }

          // console.log(dataCamp);
        });
    }, 0);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated) {
        let StateId = atob(localStorage.getItem("isLoggedIn"));
        // console.log(StateId, isAuthenticated);

        if (StateId) {
          axios
            .get(process.env.React_App_base_url + `users/` + StateId, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Content-Type": "application/json",
                Authorization: `Bearer ${isAuthenticated}`,
              },
            })
            .then((res) => {
              // console.log(res);

              setUserCredit(res.data.data.cauris);
              setUserFreecauris(res.data.data.freecauris);
              setRole(res.data.data.role);

              setTimeout(() => {
                if (res.data.data.role !== "admin") {
                  localStorage.clear();
                  navigate("../login");
                }
              }, 500);

              let ActivationCompte = res.data.data.isActive;

              if (ActivationCompte !== true) {
                navigate(".././login");
              }

              //console.log(res.data);
              // let getOpt = res.data.NewData.codeOtp;
              // console.log(getOpt);
            });
        }
      }
    }, 1000);
  }, []);

  return (
    <>
      {/* <Dasheader /> */}
      <div class=" bg-yellow-300 mb-0 -mt-8">
        <Dasheader />
      </div>
      <div class="py-8  bg-yellow-300 mb-0 ">
        <main class="h-full mt-24 overflow-y-auto">
          <div class="container  mx-auto grid">
            <div class="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
              <div class="flex items-center p-4 bg-gray-800  rounded-lg shadow-xs dark:bg-gray-800">
                <div class="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100 dark:bg-orange-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-10 h-10"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7c1.3 3 4.1 4.8 7.3 4.8c66.3 0 116-31.8 140.6-51.4c32.7 12.3 69 19.4 107.4 19.4c141.4 0 256-93.1 256-208S397.4 32 256 32zM128.2 304H116c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h12.3c6 0 10.4-3.5 10.4-6.6c0-1.3-.8-2.7-2.1-3.8l-21.9-18.8c-8.5-7.2-13.3-17.5-13.3-28.1c0-21.3 19-38.6 42.4-38.6H156c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8h-12.3c-6 0-10.4 3.5-10.4 6.6c0 1.3.8 2.7 2.1 3.8l21.9 18.8c8.5 7.2 13.3 17.5 13.3 28.1c.1 21.3-19 38.6-42.4 38.6zm191.8-8c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8v-68.2l-24.8 55.8c-2.9 5.9-11.4 5.9-14.3 0L224 227.8V296c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8V192c0-8.8 7.2-16 16-16h16c6.1 0 11.6 3.4 14.3 8.8l17.7 35.4l17.7-35.4c2.7-5.4 8.3-8.8 14.3-8.8h16c8.8 0 16 7.2 16 16v104zm48.3 8H356c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h12.3c6 0 10.4-3.5 10.4-6.6c0-1.3-.8-2.7-2.1-3.8l-21.9-18.8c-8.5-7.2-13.3-17.5-13.3-28.1c0-21.3 19-38.6 42.4-38.6H396c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8h-12.3c-6 0-10.4 3.5-10.4 6.6c0 1.3.8 2.7 2.1 3.8l21.9 18.8c8.5 7.2 13.3 17.5 13.3 28.1c.1 21.3-18.9 38.6-42.3 38.6z"
                    />
                  </svg>
                </div>
                <div>
                  <p class="mb-2 text-sm font-medium text-white dark:text-gray-400">
                    CREDIT SMS GRATUIT
                  </p>
                  <p class="text-lg font-semibold text-yellow-200 dark:text-gray-200">
                    {freecauris} Cauris
                  </p>
                </div>
              </div>

              <div class="flex items-center p-4 bg-gray-800 rounded-lg shadow-xs dark:bg-gray-800">
                <div class="p-3 mr-4 text-green-500 bg-green-100 rounded-full dark:text-green-100 dark:bg-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-10 h-10"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7c1.3 3 4.1 4.8 7.3 4.8c66.3 0 116-31.8 140.6-51.4c32.7 12.3 69 19.4 107.4 19.4c141.4 0 256-93.1 256-208S397.4 32 256 32zM128.2 304H116c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h12.3c6 0 10.4-3.5 10.4-6.6c0-1.3-.8-2.7-2.1-3.8l-21.9-18.8c-8.5-7.2-13.3-17.5-13.3-28.1c0-21.3 19-38.6 42.4-38.6H156c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8h-12.3c-6 0-10.4 3.5-10.4 6.6c0 1.3.8 2.7 2.1 3.8l21.9 18.8c8.5 7.2 13.3 17.5 13.3 28.1c.1 21.3-19 38.6-42.4 38.6zm191.8-8c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8v-68.2l-24.8 55.8c-2.9 5.9-11.4 5.9-14.3 0L224 227.8V296c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8V192c0-8.8 7.2-16 16-16h16c6.1 0 11.6 3.4 14.3 8.8l17.7 35.4l17.7-35.4c2.7-5.4 8.3-8.8 14.3-8.8h16c8.8 0 16 7.2 16 16v104zm48.3 8H356c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h12.3c6 0 10.4-3.5 10.4-6.6c0-1.3-.8-2.7-2.1-3.8l-21.9-18.8c-8.5-7.2-13.3-17.5-13.3-28.1c0-21.3 19-38.6 42.4-38.6H396c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8h-12.3c-6 0-10.4 3.5-10.4 6.6c0 1.3.8 2.7 2.1 3.8l21.9 18.8c8.5 7.2 13.3 17.5 13.3 28.1c.1 21.3-18.9 38.6-42.3 38.6z"
                    />
                  </svg>
                </div>
                <div>
                  <p class="mb-2 text-sm font-medium text-white dark:text-gray-400">
                    TOTAL CREDIT SMS PREMIUM
                  </p>
                  <p class="text-lg font-semibold text-yellow-200 dark:text-gray-200">
                    {userCredit} Cauris
                  </p>
                </div>
              </div>

              <div class="flex items-center p-4 bg-gray-800 rounded-lg shadow-xs dark:bg-gray-800">
                <div class="p-3 mr-4 text-teal-500 bg-teal-100 rounded-full dark:text-teal-100 dark:bg-teal-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5"
                    viewBox="0 0 2048 2048"
                  >
                    <path
                      fill="currentColor"
                      d="M1792 993q60 41 107 93t81 114t50 131t18 141q0 119-45 224t-124 183t-183 123t-224 46q-91 0-176-27t-156-78t-126-122t-85-157H128V128h256V0h128v128h896V0h128v128h256v865zM256 256v256h1408V256h-128v128h-128V256H512v128H384V256H256zm643 1280q-3-31-3-64q0-86 24-167t73-153h-97v-128h128v86q41-51 91-90t108-67t121-42t128-15q100 0 192 33V640H256v896h643zm573 384q93 0 174-35t142-96t96-142t36-175q0-93-35-174t-96-142t-142-96t-175-36q-93 0-174 35t-142 96t-96 142t-36 175q0 93 35 174t96 142t142 96t175 36zm64-512h192v128h-320v-384h128v256zM384 1024h128v128H384v-128zm256 0h128v128H640v-128zm0-256h128v128H640V768zm-256 512h128v128H384v-128zm256 0h128v128H640v-128zm384-384H896V768h128v128zm256 0h-128V768h128v128zm256 0h-128V768h128v128z"
                    />
                  </svg>
                </div>
                <div>
                  <p class="mb-2 text-sm font-medium text-white dark:text-gray-400">
                    TOTAL CAMPAGNE ENVOYEE
                  </p>
                  <p class="text-lg font-semibold text-yellow-200 dark:text-gray-200">
                    {incomingCamp}
                  </p>
                </div>
              </div>

              <div class="flex items-center p-4 bg-gray-800 rounded-lg shadow-xs dark:bg-gray-800">
                <div class="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-blue-100 dark:bg-blue-500">
                  <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path>
                  </svg>
                </div>
                <div>
                  <p class="mb-2 text-sm font-medium text-white dark:text-gray-400">
                    RECHARGER VOTRE COMPTE
                  </p>
                  <p class="flex  text-lg font-semibold text-yellow-200 dark:text-gray-200">
                    SMS Packs
                    <a
                      href="./updateplan"
                      target="_blank"
                      className="ml-2  inline-block rounded bg-green-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#14a44d] transition duration-150 ease-in-out hover:bg-indigo-500 hover:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(20,164,77,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)]"
                    >
                      Acheter →
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center w-48 mx-auto hover:bg-blue-300 hover:text-white rounded-lg p-4 bg-white">
            Bonjour 👋 {country} !
          </div>
          <div class="container relative z-40 mx-auto mt-12">
            <div class="flex flex-wrap justify-center mx-auto lg:w-full md:w-5/6 xl:shadow-small-blue">
              {/* HJHHJJHJ */}
              <a
                href="./utilisateurs"
                class="block w-1/2 py-10 text-center bg-white hover:bg-blue-300 border lg:w-1/4 border-grey-lighter group category hover:bg-gradient-to-l from-green-bright to-green from relative overflow-hidden"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="block mx-auto icon-small w-24"
                    viewBox="0 0 640 512"
                  >
                    <path
                      fill="currentColor"
                      d="M610.5 341.3c2.6-14.1 2.6-28.5 0-42.6l25.8-14.9c3-1.7 4.3-5.2 3.3-8.5c-6.7-21.6-18.2-41.2-33.2-57.4c-2.3-2.5-6-3.1-9-1.4l-25.8 14.9c-10.9-9.3-23.4-16.5-36.9-21.3v-29.8c0-3.4-2.4-6.4-5.7-7.1c-22.3-5-45-4.8-66.2 0c-3.3.7-5.7 3.7-5.7 7.1v29.8c-13.5 4.8-26 12-36.9 21.3l-25.8-14.9c-2.9-1.7-6.7-1.1-9 1.4c-15 16.2-26.5 35.8-33.2 57.4c-1 3.3.4 6.8 3.3 8.5l25.8 14.9c-2.6 14.1-2.6 28.5 0 42.6l-25.8 14.9c-3 1.7-4.3 5.2-3.3 8.5c6.7 21.6 18.2 41.1 33.2 57.4c2.3 2.5 6 3.1 9 1.4l25.8-14.9c10.9 9.3 23.4 16.5 36.9 21.3v29.8c0 3.4 2.4 6.4 5.7 7.1c22.3 5 45 4.8 66.2 0c3.3-.7 5.7-3.7 5.7-7.1v-29.8c13.5-4.8 26-12 36.9-21.3l25.8 14.9c2.9 1.7 6.7 1.1 9-1.4c15-16.2 26.5-35.8 33.2-57.4c1-3.3-.4-6.8-3.3-8.5l-25.8-14.9zM496 368.5c-26.8 0-48.5-21.8-48.5-48.5s21.8-48.5 48.5-48.5s48.5 21.8 48.5 48.5s-21.7 48.5-48.5 48.5zM96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64zm224 32c1.9 0 3.7-.5 5.6-.6c8.3-21.7 20.5-42.1 36.3-59.2c7.4-8 17.9-12.6 28.9-12.6c6.9 0 13.7 1.8 19.6 5.3l7.9 4.6c.8-.5 1.6-.9 2.4-1.4c7-14.6 11.2-30.8 11.2-48c0-61.9-50.1-112-112-112S208 82.1 208 144c0 61.9 50.1 112 112 112zm105.2 194.5c-2.3-1.2-4.6-2.6-6.8-3.9c-8.2 4.8-15.3 9.8-27.5 9.8c-10.9 0-21.4-4.6-28.9-12.6c-18.3-19.8-32.3-43.9-40.2-69.6c-10.7-34.5 24.9-49.7 25.8-50.3c-.1-2.6-.1-5.2 0-7.8l-7.9-4.6c-3.8-2.2-7-5-9.8-8.1c-3.3.2-6.5.6-9.8.6c-24.6 0-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h255.4c-3.7-6-6.2-12.8-6.2-20.3v-9.2zM173.1 274.6C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"
                    />
                  </svg>
                  <div>
                    <p class="pt-4 text-sm font-medium  font-body text-green-darkest lg:text-lg md:text-base md:pt-6">
                      Utilisateurs
                    </p>
                  </div>
                </div>
              </a>

              <a
                href="./allpayments"
                class="block w-1/2 py-10 text-center bg-white hover:bg-blue-300 border lg:w-1/4 border-grey-lighter group category hover:bg-gradient-to-l from-green-bright to-green from relative overflow-hidden"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="block mx-auto icon-small w-24"
                    viewBox="0 0 14 14"
                  >
                    <g
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M12 7.5v-2a1 1 0 0 0-1-1H1.5a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1H11a1 1 0 0 0 1-1V10M3.84 2L9.51.52a.49.49 0 0 1 .61.36L10.4 2" />
                      <rect width="3.5" height="2.5" x="10" y="7.5" rx=".5" />
                    </g>
                  </svg>
                  <div>
                    <p class="pt-4 text-sm font-medium font-body text-green-darkest lg:text-lg md:text-base md:pt-6">
                      Payments
                    </p>
                  </div>
                </div>
              </a>

              <a
                href="./paymentlink"
                class="block w-1/2 py-10 text-center bg-white hover:bg-blue-300 border lg:w-1/4 border-grey-lighter group category hover:bg-gradient-to-l from-green-bright to-green from relative overflow-hidden"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="block mx-auto icon-small w-24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12 12.5a3.5 3.5 0 1 0 0 7a3.5 3.5 0 0 0 0-7ZM10.5 16a1.5 1.5 0 1 1 3 0a1.5 1.5 0 0 1-3 0Z"
                    />
                    <path
                      fill="currentColor"
                      d="M17.526 5.116L14.347.659L2.658 9.997L2.01 9.99V10H1.5v12h21V10h-.962l-1.914-5.599l-2.098.715ZM19.425 10H9.397l7.469-2.546l1.522-.487L19.425 10ZM15.55 5.79L7.84 8.418l6.106-4.878l1.604 2.25ZM3.5 18.169v-4.34A3.008 3.008 0 0 0 5.33 12h13.34a3.009 3.009 0 0 0 1.83 1.83v4.34A3.009 3.009 0 0 0 18.67 20H5.332A3.01 3.01 0 0 0 3.5 18.169Z"
                    />
                  </svg>
                  <div>
                    <p class="pt-4 text-sm font-medium capitalize font-body text-green-darkest lg:text-lg md:text-base md:pt-6">
                      Générer un lien de paiement
                    </p>
                  </div>
                </div>
              </a>

              <a
                href="./totalcampagnes"
                target="_blank"
                class="block w-1/2 py-10 text-center bg-white hover:bg-blue-300 border lg:w-1/4 border-grey-lighter group category hover:bg-gradient-to-l from-green-bright to-green from relative overflow-hidden"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="block mx-auto icon-small w-24"
                    viewBox="0 0 1024 1024"
                  >
                    <path
                      fill="currentColor"
                      d="M288 384h448v64H288v-64zm96-128h256v64H384v-64zM131.456 512H384v128h256V512h252.544L721.856 192H302.144L131.456 512zM896 576H704v128H320V576H128v256h768V576zM275.776 128h472.448a32 32 0 0 1 28.608 17.664l179.84 359.552A32 32 0 0 1 960 519.552V864a32 32 0 0 1-32 32H96a32 32 0 0 1-32-32V519.552a32 32 0 0 1 3.392-14.336l179.776-359.552A32 32 0 0 1 275.776 128z"
                    />
                  </svg>
                  <div>
                    <p class="pt-4 text-sm font-medium capitalize font-body text-green-darkest lg:text-lg md:text-base md:pt-6">
                      Données sms
                    </p>
                  </div>
                </div>
              </a>

              {/* JHJFJDFHJD */}

              <a
                href="./profil"
                target="_blank"
                class="block w-1/2 py-10 text-center bg-white hover:bg-blue-300 border lg:w-1/4 border-grey-lighter group category hover:bg-gradient-to-l from-green-bright to-green from relative overflow-hidden"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="block mx-auto icon-small w-24"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm95.8 32.6L272 480l-32-136l32-56h-96l32 56l-32 136l-47.8-191.4C56.9 292 0 350.3 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-72.1-56.9-130.4-128.2-133.8z"
                    />
                  </svg>
                  <div>
                    <p class="pt-4 text-sm font-medium capitalize font-body text-green-darkest lg:text-lg md:text-base md:pt-6">
                      Profil
                    </p>
                  </div>
                </div>
              </a>

              <a
                href="./historiquesms"
                target="_blank"
                class="block w-1/2 py-10 text-center bg-white hover:bg-blue-300 border lg:w-1/4 border-grey-lighter group category hover:bg-gradient-to-l from-green-bright to-green from relative overflow-hidden"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="block mx-auto icon-small w-24"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="currentColor"
                      fill-opacity="0"
                      stroke="currentColor"
                      stroke-linecap="round"
                    >
                      <g stroke-dasharray="10" stroke-dashoffset="10">
                        <circle cx="5" cy="5" r="1.5">
                          <animate
                            fill="freeze"
                            attributeName="stroke-dashoffset"
                            dur="0.2s"
                            values="10;0"
                          />
                          <animate
                            fill="freeze"
                            attributeName="fill-opacity"
                            begin="2.0s"
                            dur="0.5s"
                            values="0;1"
                          />
                        </circle>
                        <circle cx="5" cy="12" r="1.5">
                          <animate
                            fill="freeze"
                            attributeName="stroke-dashoffset"
                            begin="0.7s"
                            dur="0.2s"
                            values="10;0"
                          />
                          <animate
                            fill="freeze"
                            attributeName="fill-opacity"
                            begin="2.2s"
                            dur="0.5s"
                            values="0;1"
                          />
                        </circle>
                        <circle cx="5" cy="19" r="1.5">
                          <animate
                            fill="freeze"
                            attributeName="stroke-dashoffset"
                            begin="1.4s"
                            dur="0.2s"
                            values="10;0"
                          />
                          <animate
                            fill="freeze"
                            attributeName="fill-opacity"
                            begin="2.4s"
                            dur="0.5s"
                            values="0;1"
                          />
                        </circle>
                      </g>
                      <g stroke-dasharray="28" stroke-dashoffset="28">
                        <rect width="11" height="3" x="9.5" y="3.5" rx="1.5">
                          <animate
                            fill="freeze"
                            attributeName="stroke-dashoffset"
                            begin="0.1s"
                            dur="0.5s"
                            values="28;0"
                          />
                          <animate
                            fill="freeze"
                            attributeName="fill-opacity"
                            begin="2.0s"
                            dur="0.5s"
                            values="0;1"
                          />
                        </rect>
                        <rect width="11" height="3" x="9.5" y="10.5" rx="1.5">
                          <animate
                            fill="freeze"
                            attributeName="stroke-dashoffset"
                            begin="0.8s"
                            dur="0.5s"
                            values="28;0"
                          />
                          <animate
                            fill="freeze"
                            attributeName="fill-opacity"
                            begin="2.2s"
                            dur="0.5s"
                            values="0;1"
                          />
                        </rect>
                        <rect width="11" height="3" x="9.5" y="17.5" rx="1.5">
                          <animate
                            fill="freeze"
                            attributeName="stroke-dashoffset"
                            begin="1.5s"
                            dur="0.5s"
                            values="28;0"
                          />
                          <animate
                            fill="freeze"
                            attributeName="fill-opacity"
                            begin="2.4s"
                            dur="0.5s"
                            values="0;1"
                          />
                        </rect>
                      </g>
                    </g>
                  </svg>
                  <div>
                    <p class="pt-4 text-sm font-medium capitalize font-body text-green-darkest lg:text-lg md:text-base md:pt-6">
                      Historique des envois sms
                    </p>
                  </div>
                </div>
              </a>

              <a
                href="./integrationwoo"
                target="_blank"
                class="block w-1/2 py-10 text-center bg-white hover:bg-blue-300 border lg:w-1/4 border-grey-lighter group category hover:bg-gradient-to-l from-green-bright to-green from relative overflow-hidden"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="block mx-auto icon-small w-24"
                    viewBox="0 0 128 128"
                  >
                    <path
                      fill="currentColor"
                      d="M12.1 102.4H9.8c-2.9 0-5.2-2.3-5.2-5.2c0-3 2.4-5.4 5.4-5.4h2.1v-4.2H9.3c-5.1 0-9.3 4.2-9.3 9.3c0 5.7 4.6 10.3 10.3 10.3h1.8v-4.8zm103.8 0h-2.3c-2.9 0-5.2-2.3-5.2-5.2c0-3 2.4-5.4 5.4-5.4h2.1v-4.2H113c-5.1 0-9.3 4.2-9.3 9.3c0 5.7 4.6 10.3 10.3 10.3h1.8v-4.8zM22.1 87.6c-4.5 0-8.1 4.4-8.1 9.8s3.6 9.8 8.1 9.8s8.1-4.4 8.1-9.8s-3.6-9.8-8.1-9.8zm0 15.9c-2 0-3.5-2.7-3.5-6s1.6-6 3.5-6c2 0 3.5 2.7 3.5 6s-1.6 6-3.5 6zm8.1 3.7l3.1-19.6h6.5l1.9 9.8l2.5-9.8h6l2.9 19.6h-5l-1.6-13.3l-2.7 13.3h-3.4l-3.7-13.1l-1.7 13.1zm24 0l3.1-19.6h6.5l1.8 9.8l2.5-9.8h6.1l2.9 19.6h-5l-1.7-13.3l-2.7 13.3h-3.3l-3.8-13.1l-1.6 13.1zm23.7 0V87.6h10.2v3.8H83v4.2h4.5v3.6h-5.1v3.8h5.7v4.2zm39.9 0V87.6H128v3.8h-5.1v4.2h4.5v3.6h-5.1v3.8h5.7v4.2z"
                    />
                    <path
                      fill="currentColor"
                      d="M100 98.1c1.6 0 3-1.3 3-3v-1.8c0-3.2-2.6-5.7-5.7-5.7h-7.4v19.6h4.6V98l5 9.2h5.1l-4.6-9.1zm-3.9-1.7h-1.7v-4.8h1.9c1.3 0 2.3 1 2.3 2.3c-.1 1.4-1.2 2.5-2.5 2.5zM74.4 37.7c-1.9-.2-5.4.8-7 8.6c0 4.8 1.1 7.6 2.9 8.6c3.3 1 7.2-3.4 7.2-8.2c.1-3.1.3-7.2-3.1-9zm25.6 0c-1.9-.2-5.4.8-7 8.6c0 4.8 1.1 7.6 2.9 8.6c3.3 1 7.2-3.4 7.2-8.2c.2-3.1.3-7.2-3.1-9z"
                    />
                    <path
                      fill="currentColor"
                      d="M104 20.8H24.3c-6.4 0-11.7 5.2-11.7 11.7v27.4c0 6.4 5.2 11.7 11.7 11.7H104c6.4 0 11.7-5.2 11.7-11.7V32.4c0-6.4-5.3-11.6-11.7-11.6zM48.1 64.2s-5.5-7.3-6.9-12.8s-1.6-3-1.6-3s-4.4 9.9-9 16.2c-4.6 6.3-6.9-3.1-6.9-3.1c-1.6-1.9-6.2-29.9-6.2-29.9c2.6-7.1 7-1.3 7-1.3L29 53.1s6.9-14 9.1-17.5c2.3-3.6 6.2-2.6 6.5 1.1c.3 3.7 4.1 14 4.1 14c.3-10.7 4.7-20.9 5.4-22.6s7.8-3.6 6.5 3.2c-3.1 7.6-6.2 23.7-5.4 32.5c-2 6.7-7.1.4-7.1.4zm28.8-3.6c-2.1 1-9.9 6.3-15.4-5.7c-3.5-12.1 4.2-21 4.2-21s10.1-8.6 17 2.8c5.5 12.5-3.7 22.9-5.8 23.9zm25.7 0c-2.1 1-9.9 6.3-15.4-5.7c-3.5-12.1 4.2-21 4.2-21s10.1-8.6 17 2.8c5.5 12.5-3.7 22.9-5.8 23.9z"
                    />
                    <path
                      fill="currentColor"
                      d="M62 71.5L79.9 82l-3.7-10.5l-10.3-2.9z"
                    />
                  </svg>
                  <div>
                    <p class="pt-4 text-sm font-medium capitalize font-body text-green-darkest lg:text-lg md:text-base md:pt-6">
                      E-commerce
                    </p>
                  </div>
                </div>
              </a>

              <a
                href="./statistiques"
                target="_blank"
                class="block w-1/2 py-10 text-center bg-white hover:bg-blue-300 border lg:w-1/4 border-grey-lighter group category hover:bg-gradient-to-l from-green-bright to-green from relative overflow-hidden"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="block mx-auto icon-small w-24 "
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M6 41a1 1 0 0 0 1 1h34v-2H8v-4.641l6.352-7.713a3.998 3.998 0 0 0 4.976-1.426l4.675 1.948a4 4 0 1 0 7.383-2.3l4.975-6.218A4 4 0 1 0 34.8 18.4l-4.875 6.093a4 4 0 0 0-5.489 1.689l-4.45-1.854a4 4 0 1 0-7.193 2.064L8 32.212V7H6v34Zm12-17a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm10 6a2 2 0 1 0 0-4a2 2 0 0 0 0 4Zm12-14a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <div>
                    <p class="pt-4 text-sm font-medium capitalize font-body text-green-darkest lg:text-lg md:text-base md:pt-6">
                      Statistiques
                    </p>
                  </div>
                </div>
              </a>

              <a
                href="./sendsms"
                target="_blank"
                class="block w-1/2 py-10 text-center bg-white hover:bg-blue-300 border lg:w-1/4 border-grey-lighter group category hover:bg-gradient-to-l from-green-bright to-green from relative overflow-hidden"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="block mx-auto icon-small w-24 "
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M448 0H64C28.6 0 0 28.6 0 64v256c0 35.4 28.6 64 64 64h128l-42.7 128l192-128H448c35.4 0 64-28.6 64-64V64c0-35.4-28.6-64-64-64zM128 234.7c-23.6 0-42.7-19.1-42.7-42.7s19.1-42.7 42.7-42.7s42.7 19.1 42.7 42.7s-19.1 42.7-42.7 42.7zm128 0c-23.6 0-42.7-19.1-42.7-42.7s19.1-42.7 42.7-42.7s42.7 19.1 42.7 42.7s-19.1 42.7-42.7 42.7zm128 0c-23.6 0-42.7-19.1-42.7-42.7s19.1-42.7 42.7-42.7s42.7 19.1 42.7 42.7s-19.1 42.7-42.7 42.7z"
                    />
                  </svg>
                  <div>
                    <p class="pt-4 text-sm font-medium  font-body text-green-darkest lg:text-lg md:text-base md:pt-6">
                      Envoyer un SMS Express
                    </p>
                  </div>
                </div>
              </a>

              <a
                href="./campagnesms"
                target="_blank"
                class="block w-1/2 py-10 text-center bg-white hover:bg-blue-300 border lg:w-1/4 border-grey-lighter group category hover:bg-gradient-to-l from-green-bright to-green from relative overflow-hidden"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="block mx-auto icon-small w-24"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m22.383 28.274l4.223 4.214a3.622 3.622 0 0 0 5.123.002l.001-.002l8.04-8.04"
                    />
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M27.101 22.054a11.026 11.026 0 0 1-10.575 7.916a10.998 10.998 0 0 1-.636-.044v9.277c0 .585.474 1.06 1.06 1.06h24.49a1.06 1.06 0 0 0 1.06-1.06h0v-16.08a1.06 1.06 0 0 0-1.06-1.06H27.1v-.009Z"
                    />
                    <circle
                      cx="16.526"
                      cy="18.945"
                      r="11.026"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.763 13.485a3.448 3.448 0 0 1 5.151-4.586h0m9.55.186a3.445 3.445 0 1 1 4.886 4.788m-9.568-1.528v7.103l3.976 3.384m18.853 15.037l-7.253-5.946m-13.562 5.946l7.254-5.946"
                    />
                  </svg>
                  <div>
                    <p class="pt-4 text-sm font-medium font-body text-green-darkest lg:text-lg md:text-base md:pt-6">
                      Faire une Campagne
                    </p>
                  </div>
                </div>
              </a>

              <a
                href="./integrationapi"
                target="_blank"
                class="block w-1/2 py-10 text-center bg-white hover:bg-blue-300 border lg:w-1/4 border-grey-lighter group category hover:bg-gradient-to-l from-green-bright to-green from relative overflow-hidden"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="block mx-auto icon-small w-24"
                    viewBox="0 0 1024 1024"
                  >
                    <path
                      fill="currentColor"
                      d="m917.7 148.8l-42.4-42.4c-1.6-1.6-3.6-2.3-5.7-2.3s-4.1.8-5.7 2.3l-76.1 76.1a199.27 199.27 0 0 0-112.1-34.3c-51.2 0-102.4 19.5-141.5 58.6L432.3 308.7a8.03 8.03 0 0 0 0 11.3L704 591.7c1.6 1.6 3.6 2.3 5.7 2.3c2 0 4.1-.8 5.7-2.3l101.9-101.9c68.9-69 77-175.7 24.3-253.5l76.1-76.1c3.1-3.2 3.1-8.3 0-11.4zM578.9 546.7a8.03 8.03 0 0 0-11.3 0L501 613.3L410.7 523l66.7-66.7c3.1-3.1 3.1-8.2 0-11.3L441 408.6a8.03 8.03 0 0 0-11.3 0L363 475.3l-43-43a7.85 7.85 0 0 0-5.7-2.3c-2 0-4.1.8-5.7 2.3L206.8 534.2c-68.9 68.9-77 175.7-24.3 253.5l-76.1 76.1a8.03 8.03 0 0 0 0 11.3l42.4 42.4c1.6 1.6 3.6 2.3 5.7 2.3s4.1-.8 5.7-2.3l76.1-76.1c33.7 22.9 72.9 34.3 112.1 34.3c51.2 0 102.4-19.5 141.5-58.6l101.9-101.9c3.1-3.1 3.1-8.2 0-11.3l-43-43l66.7-66.7c3.1-3.1 3.1-8.2 0-11.3l-36.6-36.2z"
                    />
                  </svg>
                  <div>
                    <p class="pt-4 text-sm font-medium capitalize font-body text-green-darkest lg:text-lg md:text-base md:pt-6">
                      Api Rest Connexion
                    </p>
                  </div>
                </div>
              </a>

              <a
                href="./affiliation"
                target="_blank"
                class="block w-1/2 py-10 text-center bg-white hover:bg-blue-300 border lg:w-1/4 border-grey-lighter group category hover:bg-gradient-to-l from-green-bright to-green from relative overflow-hidden"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="block mx-auto icon-small w-24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m5.931 6.936l1.275 4.249m5.607 5.609l4.251 1.275m-5.381-5.752l5.759-5.759M4 5.5a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0-3 0m13 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0-3 0m0 13a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0-3 0m-13-3a4.5 4.5 0 1 0 9 0a4.5 4.5 0 1 0-9 0"
                    />
                  </svg>
                  <div>
                    <p class="pt-4 text-sm font-medium capitalize font-body text-green-darkest lg:text-lg md:text-base md:pt-6">
                      Affiliation
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </main>
      </div>

      <Dashfooter />
    </>
  );
}

export default Admindash;


import Dasheader from "./Dasheader";
import Dashfooter from "./Dashfooter";
import Sidebar from "./Sibebar";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";



function Userupdate() {

  const isAuthenticated = localStorage.getItem("uuid");
   const [userfirstname, setUserfirstname] = useState("");
   const [userlastname, setUserlastname] = useState("");
   const [useremail, setUseremail] = useState("");
   const [userphone, setUserphone] = useState("");
   const [userkey, setUserkey] = useState("");
    const [userCredisms, setUserCredisms] = useState("");
    const [userStatus, setUserStatus] = useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const [note, setNote] = useState("");
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const AuthRef = params.get("regex");    
    const userID = atob(localStorage.getItem("isLoggedIn"));

 

     useEffect(() => {
      setTimeout(() => {
        if (isAuthenticated) {
          let StateId = atob(localStorage.getItem("isLoggedIn"));
          // console.log(StateId, isAuthenticated);

          if (AuthRef) {
            axios
              .get(process.env.React_App_base_url + `users/` + AuthRef, {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Credentials": true,
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${isAuthenticated}`,
                },
              })
              .then((res) => {
                // console.log(res);
                setUserfirstname(res.data.data.firstname);
                setUserlastname(res.data.data.lastname);
                setUseremail(res.data.data.email);
                setUserphone(res.data.data.phone);
                setUserkey(res.data.data.userkey);
                setUserCredisms(res.data.data.cauris);

                if (res.data.data.isActive===true) {
                  setUserStatus("Oui");
                  setNote("Désactiver le compte");
                }
                if (res.data.data.isActive === false) {
                  setUserStatus("Non");
                  setNote("Activer le compte");
                }

                //  console.log(res.data.data.isActive);
                // let getOpt = res.data.NewData.codeOtp;
                // console.log(getOpt);
              });
          }
        }
      },1000);
     }, []);
             function UpdateInfo() {
               setLoading(true);
               // Perform operation and then set loading to false
               setTimeout(() => {
                 setLoading(false);
               }, 500);

             
               if (userStatus === "Oui") {
                  const deconnectdata = JSON.stringify({
                    isActive: false,
                  });
                 
                  //  let userID = atob(localStorage.getItem("isLoggedIn"));
                  axios
                    .patch(
                      process.env.React_App_base_url + `users/` + AuthRef,
                      deconnectdata,
                      {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Access-Control-Allow-Credentials": true,
                          "Content-Type": "application/json",
                          "Authorization": `Bearer ${isAuthenticated}`,
                        },
                      }
                    )
                    .then((res) => {
                      // console.log(res);
                      setMsg("Le compte a été déconnecté avec succès 👌 ! ");
                    });
                    setTimeout(() => {
                      window.location.reload(true);
                    }, "1000"); 
                
               }
               if (userStatus === "Non") {
                 const activedata = JSON.stringify({
                   isActive: true,
                 });
                   //  let userID = atob(localStorage.getItem("isLoggedIn"));
                 axios
                   .patch(
                     process.env.React_App_base_url + `users/` + AuthRef,
                     activedata,
                     {
                       headers: {
                         "Access-Control-Allow-Origin": "*",
                         "Access-Control-Allow-Credentials": true,
                         "Content-Type": "application/json",
                         "Authorization": `Bearer ${isAuthenticated}`,
                       },
                     }
                   )
                   .then((res) => {
                     // console.log(res);
                     setMsg("Le compte a été activé avec succès 👌 ! ");
                   });
                   setTimeout(() => {
                    window.location.reload(true);
                   }, "1000");                  
               }

              
             }



  return (
    <>
      <Dasheader />

      <div className="bg-yellow-400 pt-2  h-sreen mb-60 -mt-8">
        <div class="p-4 mt-24 -mb-60">
          <h1 class="text-4xl text-center w-full px-auto  text-white font-semibold mb-24">
            CONTROLE UTILISATEUR
          </h1>
          <div class="mx-auto -mt-32 ">
            <body class="font-sans antialiased text-gray-900 mb-16 leading-normal tracking-wider bg-cover">
              <div class="max-w-4xl flex items-center h-auto lg:h-screen flex-wrap mx-auto lg:my-0">
                <div
                  id="profile"
                  class="w-full lg:w-5/5 rounded-lg lg:rounded-l-lg lg:rounded-r-none shadow-2xl bg-white opacity-75 mx-6 lg:mx-0"
                >
                  <div class="p-4 md:p-12 text-center  lg:text-left">
                    <div class="flex">
                      <span class="">
                        <input
                          type="text "
                          value={userfirstname}
                          onChange={(e) => setUserfirstname(e.target.value)}
                          class="text-xl font-bold pt-8 lg:pt-0 -mr-32 outline-none"
                        ></input>
                      </span>
                      <span class="lastname">
                        <input
                          type="text"
                          value={userlastname}
                          onChange={(e) => setUserlastname(e.target.value)}
                          class="text-xl font-bold pt-8  border-l pl-4 border-l-2 border-l-gray-700  lg:pt-0 outline-none"
                        ></input>
                      </span>
                    </div>
                    <div class="mx-auto lg:mx-0  pt-3 border-b-2 border-green-500 opacity-25"></div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                      <div class="sm:divide-y sm:divide-gray-200">
                        <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Affiliation Key
                          </dt>
                          <input
                            type="text"
                            disabled
                            value={userkey}
                            class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 outline-none "
                          ></input>
                        </div>
                        <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Addresse Email
                          </dt>
                          <input
                            type="email"
                            disabled
                            value={useremail}
                            class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                          ></input>
                        </div>
                        <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            WhathSApp
                          </dt>
                          <input
                            text="text"
                            value={userphone}
                            onChange={(e) => setUserphone(e.target.value)}
                            class="mt-1 text-sm text-gray-900 sm:mt-0 font-bold sm:col-span-2 outline-none "
                          ></input>
                        </div>
                        <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Crédit sms
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {userCredisms} Cauris
                          </dd>
                        </div>
                        <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Status compte activé
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {userStatus}
                          </dd>
                        </div>
                      </div>
                    </div>
                    <p class="pt-8 text-sm">
                      Contrôle d'accès des utilisateurs !
                    </p>
                    <p className="text-green-600 p-2 text-center">{msg}</p>
                    <div class="pt-12 pb-8">
                      <button
                        enable={loading}
                        onClick={() => {
                          UpdateInfo();
                        }}
                        class="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded-full"
                      >
                        {loading ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 animate-spin mx-auto"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                            />
                          </svg>
                        ) : (
                          note + " →"
                        )}
                      </button>
                    </div>
                    <div className="mt-4 w-32 float-right  sm:ml-16 sm:mt-0 sm:flex-none">
                      <a
                        href="../admindash"
                        type="button"
                        className="block rounded-md bg-indigo-500 px-3 py-2 text-center text-sm font-semibold text-white hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                      >
                        ↲ Retour
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </body>
          </div>
        </div>
      </div>
      <Dashfooter />
    </>
  );
}

export default Userupdate;

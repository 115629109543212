import "./App.css";
import { useEffect } from "react";
import { useState } from "react";

function Header() {
  const [show, setShow] = useState(false);

  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       autoDisplay: false,
  //     },
  //     "google_translate_element"
  //   );
  // };

  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);

  return (
    <>
      <div
        // id="google_translate_element"
        class="px-4 mx-auto max-w-full sm:px-6 bg-blue-300 -mt-8"
      >
        <div class="relative pt-6 pb-4 sm:pb-8">
          <nav
            class="relative flex items-center justify-between sm:h-10 md:justify-center"
            aria-label="Global"
          >
            <div class="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
              <div class="flex items-center justify-between w-full md:w-auto">
                <a href="/">
                  <span class="sr-only">5Sender</span>
                  <img
                    class="w-auto h-16 sm:h-16"
                    src="https://5sender.com/5Logo2.png"
                    loading="lazy"
                    width="202"
                    height="40"
                  />
                </a>
                <div class="flex items-center -mr-2 md:hidden">
                  <button
                    onClick={() => setShow(!show)}
                    class="inline-flex items-center justify-center p-2 text-gray-400 bg-gray-50 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-50"
                    type="button"
                    aria-expanded="false"
                  >
                    <span class="sr-only">Open main menu</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      aria-hidden="true"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="hidden md:flex md:space-x-10 list-none">
              <li>
                <a
                  href="/"
                  class="text-base px-4 py-2 text-base text-gray-900 bg-white border border-transparent rounded-full cursor-pointer font-base hover:bg-yellow-400"
                  target=""
                >
                  Accueil
                </a>
              </li>
              <li>
                <a
                  href="./#tarifs"
                  class="text-base px-4 py-2 text-base text-gray-900 bg-white border border-transparent rounded-full cursor-pointer font-base hover:bg-yellow-400"
                  target=""
                >
                  Tarifs
                </a>
              </li>
              <li>
                <a
                  href="./#fonctionnalites"
                  class="text-base px-4 py-2 text-base text-gray-900 bg-white border border-transparent rounded-full cursor-pointer font-base hover:bg-yellow-400"
                >
                  Fonctionnalités
                </a>
              </li>
              <li>
                <a
                  href="./documentation"
                  class="text-base px-4 py-2 text-base text-gray-900 bg-white border border-transparent rounded-full cursor-pointer font-base hover:bg-yellow-400"
                >
                  Intégration Woocommerce
                </a>
              </li>
              <li>
                <a
                  href="./#faqs"
                  class="text-base px-4 py-2 text-base text-gray-900 bg-white border border-transparent rounded-full cursor-pointer font-base hover:bg-yellow-400"
                >
                  Faqs
                </a>
              </li>
              <li>
                <a
                  href="./#contact"
                  class="text-base px-4 py-2 text-base text-gray-900 bg-white border border-transparent rounded-full cursor-pointer font-base hover:bg-yellow-400"
                >
                  Contact
                </a>
              </li>
            </div>
            <div class="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
              <div class="inline-flex rounded-full shadow">
                <a
                  href="./login"
                  class="inline-flex items-center px-4 py-2 text-base text-gray-900 bg-yellow-400 border border-transparent rounded-full cursor-pointer font-base hover:bg-gray-50 "
                >
                  Connexion
                </a>
              </div>
            </div>
          </nav>
          {show ? (
            <div class="sm:hidden " id="mobile-menu">
              <div class="space-y-1 px-2 pb-3 pt-2">
                <a
                  href="/"
                  class="bg-gray-900 text-white block rounded-md px-3 py-2 text-base font-medium"
                  aria-current="page"
                >
                  Accueil
                </a>
                <a
                  href="#tarifs"
                  class="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                >
                  Tarifs
                </a>
                <a
                  href="./#fonctionnalites"
                  class="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                >
                  Fonctionnalités
                </a>
                <a
                  href="./documentation"
                  class="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                >
                  Intégration Woocommerce
                </a>
                <a
                  href="./#faqs"
                  class="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                >
                  Faqs
                </a>
                <a
                  href="./#contact"
                  class="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                >
                  Contact
                </a>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default Header;

import Dasheader from "./Dasheader";
import Dashfooter from "./Dashfooter";
import { useState, useEffect } from "react";
import Sidebar from "./Sibebar";
import axios from "axios";
import copy from "copy-to-clipboard";

function Affiliation() {
  const [notifications, setNotifications] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const isAuthenticated = localStorage.getItem("uuid");
  let userID = atob(localStorage.getItem("isLoggedIn"));
  const [token, setToken] = useState("");
  const [alert, setAlert] = useState("");
  const [payDataCount, setPayDataCount] = useState("");
  const [usercommission, setUsercommission] = useState("");
  const [userOnecommission, setUserOnecommission] = useState("");
  const [userSmsCredit, setUserSmsCredit] = useState("");
  const [loading, setLoading] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [myKey, setMyKey] = useState("");
  const [myEmail, setMyEmail] = useState("");
  const [myCauris, setMyCauris] = useState("");
  const [moneyCommission, setMoneyCommission] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      axios
        .get(process.env.React_App_base_url + `users/` + userID, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticated}`,
          },
        })
        .then(async (res) => {
          // console.log(res);
          const email = res.data.data.email;
          const userToken = res.data.data.userkey;
          const userMyCommission = res.data.data.commissionsponsor;
          const userCauris = res.data.data.cauris;
          setToken("https://5sender.com/signup/?affiliation=" + userToken);

          if (userToken) {
            axios
              .get(process.env.React_App_base_url + `payments`, {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Credentials": true,
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${isAuthenticated}`,
                },
              })
              .then(async (res) => {
                //  console.log(res);
                const paymentData = res.data.payment;
                // console.log(paymentData);

                //  DataFilter;

                const filteredPayments = paymentData.filter(
                  (payment) =>
                    payment["sponkey"] === userToken &&
                    payment["paymentStatus"] === "confirmed"
                );

                // console.log(filteredPayments);
                let total = filteredPayments.reduce(
                  (a, b) => a + (b["montant"] || 0),
                  0
                );
                // console.log(total);
                let commiCount = filteredPayments.length;
                let comAffiliation = (total * 10) / 100 - userMyCommission;
                // console.log(comAffiliation);
                setUsercommission(comAffiliation);
                if (commiCount < 9) {
                  setPayDataCount("0" + commiCount);
                }
                if (commiCount > 9) {
                  setPayDataCount(commiCount);
                }
                setUserOnecommission(userMyCommission);
                setMyKey(userToken);
                setMyEmail(email);
                setMyCauris(userCauris);
              });
          }
        });
    }
  }, []);

  const copyToClipboard = (e) => {
    copy(e);
    if (e && e === token) {
      //console.log(e);
      setAlert(" ✔️ ");
    }
  };

  function firstDigit(num) {
    // 1: get first digit using regex pattern
    const matches = String(num).match(/\d/);
    // 2: convert matched item to integer
    const digit = Number(matches[0]);
    // 3: add sign back as needed
    return num < 0 ? -digit : digit;
  }

  function CrediSms() {
    if (
      usercommission === 0 ||
      usercommission < 140 ||
      usercommission < userSmsCredit
    ) {
      setErrormsg(
        "Désolé, Votre solde commission est insuffisant pour créditer votre compte !"
      );
    }
    if (userSmsCredit === "") {
      setErrormsg(
        "Veuillez saisir un montant en fonction de votre solde commission !"
      );
    }
    if (userSmsCredit < 140) {
      setErrormsg(
        "Le montant minimun à créditer sur votre compte doit être supérieur ou égal à 140 FCFA"
      );
    }

    if (
      userSmsCredit !== "" &&
      usercommission >= 140 &&
      usercommission > userSmsCredit &&
      userSmsCredit >= 140
    ) {
      let CreditValue = firstDigit(userSmsCredit / 140);
      let NbreCredit = CreditValue * 40;
      let myPaid = CreditValue * 140;
      let SomComPay = myPaid + userOnecommission;
      // let newCommission =   usercommission;
      let MyNewComPay = NbreCredit + myCauris;
      //  console.log(NbreCredit);
      const data = JSON.stringify({
        commissionsponsor: SomComPay,
        cauris: MyNewComPay,
      });
      let userID = atob(localStorage.getItem("isLoggedIn"));
      axios
        .patch(process.env.React_App_base_url + `users/` + userID, data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticated}`,
          },
        })
        .then((res) => {
          // console.log(res);
          if (res) {
            const notifydata = JSON.stringify({
              notificationtitle:
                "Votre compte a été mise à jour de" +
                " " +
                NbreCredit +
                " " +
                "crédit sms",
              lienRef: "../dashboard/homedash",
              useremail: myEmail,
              userkey: myKey,
              refpay: "Pay_Commission",
              viewstatus: "0",
              createdDate: new Date().toLocaleString(),
            });
            axios
              .post(
                process.env.React_App_base_url + `notifications/`,
                notifydata,
                {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": true,
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${isAuthenticated}`,
                  },
                }
              )
              .then((res) => {
                //  console.log(res);
              });

            setSuccessMsg(
              "Votre compte sera mise à jour de" +
                " " +
                NbreCredit +
                " " +
                "crédit sms"
            );
          }
        });
    }
  }

  function MoneyPayCommission() {
    if (
      usercommission === 0 ||
      usercommission < 5000 ||
      usercommission < moneyCommission
    ) {
      setErrormsg(
        "Désolé, Votre solde commission est insuffisant pour effectuer un retrait !"
      );
    }
    if (moneyCommission === "") {
      setErrormsg(
        "Veuillez saisir un montant en fonction de votre solde commission !"
      );
    }
    if (moneyCommission < 5000) {
      setErrormsg(
        "Le montant minimun pour effectuer un retrait doit être supérieur ou égal à 5000 FCFA"
      );
    }

    if (
      moneyCommission !== "" &&
      usercommission >= 5000 &&
      usercommission >= moneyCommission &&
      moneyCommission >= 5000
    ) {
      let SomComPay = parseInt(moneyCommission) + parseInt(userOnecommission);
      // let newCommission =   usercommission;
      //  console.log(NbreCredit);
      const data = JSON.stringify({
        commissionsponsor: SomComPay,
      });
      let userID = atob(localStorage.getItem("isLoggedIn"));
      axios
        .patch(process.env.React_App_base_url + `users/` + userID, data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticated}`,
          },
        })
        .then((res) => {
          // console.log(res);
          if (res) {
            const notifydata = JSON.stringify({
              notificationtitle:
                "Votre compte sera crédité de" +
                " " +
                moneyCommission +
                " " +
                "crédit sms",
              lienRef: "../dashboard/homedash",
              useremail: myEmail,
              userkey: myKey,
              refpay: "Pay_Commission_by_Money",
              viewstatus: "0",
              createdDate: new Date().toLocaleString(),
            });
            axios
              .post(
                process.env.React_App_base_url + `notifications/`,
                notifydata,
                {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": true,
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${isAuthenticated}`,
                  },
                }
              )
              .then((res) => {
                //  console.log(res);
              });

            setSuccessMsg(
              "Votre compte sera crédité de" +
                " " +
                moneyCommission +
                " " +
                "FCFA"
            );
          }
        });
    }
  }

  return (
    <>
      <Dasheader />
      <div className="bg-yellow-400 -mb-60 -mt-8">
        <div class="bg-yellow-400  mb-64  ">
          <div class=" w-1/2   mx-auto  ">
            <Sidebar />
            <p class="text-center p-2 -mb-4 text-green-100"></p>
            <p class="text-center p-2 -mb-4 text-red-700"></p>
            <div class="mx-auto max-w-7xl justify-center sm:px-6 mt-32 lg:px-8">
              <div class="text-4xl text-center text-white font-semibold mb-6">
                Votre lien d'affiliation 10% de commission
              </div>
              <p class="text-center rounded-full p-2  text-red-700">
                {errormsg}
              </p>
              <p class="text-center  p-2 rounded-full  text-green-100">
                {successMsg}{" "}
              </p>
              <div class="mytable grid grid-cols-3 gap-2 w-full p-4 mx-auto max-w-screen-sm">
                <div>
                  <input
                    class="hidden"
                    id="radio_1"
                    type="radio"
                    name="radio"
                    checked
                  />
                  <label
                    class="flex flex-col p-4 border-2 border-gray-400 cursor-pointer"
                    for="radio_1"
                  >
                    <span class="text-xs font-semibold uppercase">Solde</span>
                    <span class="text-xl font-bold mt-2 max-w-16 px-8">
                      {Math.round(usercommission)}/FCFA
                    </span>
                    <ul class="text-sm mt-2">
                      <li>Votre commision de 10%</li>
                      <li className="">
                        Total des achats de vos filleuls :
                        <span className="font-bold"> {payDataCount} </span>
                      </li>
                    </ul>
                  </label>
                </div>

                <div>
                  <input
                    class="hidden"
                    id="radio_2"
                    type="radio"
                    name="radio"
                  />
                  <label
                    class="flex flex-col p-4 border-2 border-gray-400 cursor-pointer"
                    for="radio_2"
                  >
                    <span class="text-xs font-semibold uppercase">
                      TRANSFORMER EN SMS
                    </span>
                    <span class="text-xl font-bold mt-2"></span>
                    <ul class="text-sm mt-2">
                      <li>
                        Montant :{" "}
                        <input
                          type="number"
                          name="userSmsCredit"
                          id="userSmsCredit"
                          onChange={(event) =>
                            setUserSmsCredit(event.target.value)
                          }
                          value={userSmsCredit}
                          placeholder="100"
                          class="w-40 rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                        />
                      </li>
                      <li className="mt-4">
                        {" "}
                        <button
                          type="submit"
                          onClick={CrediSms}
                          className="font-medium text-white bg-black h-8 rounded-lg hover:text-yellow-400 p-2 mt-4"
                        >
                          Soumettre →
                        </button>
                      </li>
                    </ul>
                  </label>
                </div>
                <div>
                  <input
                    class="hidden"
                    id="radio_3"
                    type="radio"
                    name="radio"
                  />
                  <label
                    class="flex flex-col p-4 border-2 border-gray-400 cursor-pointer"
                    for="radio_3"
                  >
                    <span class="text-xs font-semibold uppercase">
                      Demander un paiement
                    </span>
                    <span class="text-xl font-bold mt-2"></span>
                    <ul class="text-sm mt-2">
                      <li>
                        Montant :{" "}
                        <input
                          disabled
                          type="number"
                          name="moneyCommission"
                          id="moneyCommission"
                          value={moneyCommission}
                          onChange={(event) =>
                            setMoneyCommission(event.target.value)
                          }
                          placeholder="100"
                          class="w-40 rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                        />
                        <span class="text-sm text-red-500 font-bold mt-2">
                          Bientôt disponible
                        </span>
                      </li>
                      <li className="mt-4">
                        {" "}
                        <button
                          disabled
                          onClick={MoneyPayCommission}
                          className="font-medium text-white bg-black h-8 rounded-lg hover:text-yellow-400 p-2 mt-4"
                        >
                          Soumettre →
                        </button>
                      </li>
                    </ul>
                  </label>
                </div>
              </div>
              <div class="relative isolate  overflow-hidden bg-white px-6 py-20 text-center sm:px-16 sm:shadow-sm dark:bg-transparent">
                <p class="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-200 sm:text-4xl">
                  Gagner de l'argent avec votre lien sponsorisé 🏅
                </p>

                <div>
                  <label
                    class="mx-auto mt-8 mb-24 relative bg-white min-w-sm max-w-2xl flex flex-col md:flex-row items-center justify-center border py-2 px-2 rounded-2xl gap-2 shadow-2xl focus-within:border-gray-300"
                    for="search-bar"
                  >
                    <input
                      id="search-bar"
                      placeholder=""
                      value={token}
                      name="q"
                      class="px-6 py-2 w-full rounded-md flex-1 outline-none bg-white"
                    />
                    <button
                      type="submit"
                      onClick={() => copyToClipboard(token)}
                      class="w-full md:w-auto px-6 py-3 bg-black border-black text-white fill-white active:scale-95 duration-100 border will-change-transform overflow-hidden relative rounded-xl transition-all"
                    >
                      <div class="flex items-center transition-all opacity-1">
                        <span class="text-sm font-semibold whitespace-nowrap truncate mx-auto">
                          Copier
                        </span>
                      </div>
                    </button>
                    {alert}{" "}
                  </label>
                </div>

                <svg
                  viewBox="0 0 1024 1024"
                  class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
                  aria-hidden="true"
                >
                  <circle
                    cx="512"
                    cy="512"
                    r="512"
                    fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
                    fill-opacity="0.7"
                  ></circle>
                  <defs>
                    <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                      <stop stop-color="#3b82f6"></stop>
                      <stop offset="1" stop-color="#1d4ed8"></stop>
                    </radialGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dashfooter />
    </>
  );
}

export default Affiliation;

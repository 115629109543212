import Dasheader from "./Dasheader";
import Dashfooter from "./Dashfooter";
import { useState, useEffect } from "react";
import Sidebar from "./Sibebar";
import axios from "axios";
import copy from "copy-to-clipboard";

function Integrationwoo() {
  const [msg, setMsg] = useState("");
  const [alert, setAlert] = useState("");
  const [token, setToken] = useState("");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const [loading, setLoading] = useState(false);
  const isAuthenticated = localStorage.getItem("uuid");

  function ShowKeyMarchad() {
    if (isAuthenticated) {
      let StateId = atob(localStorage.getItem("isLoggedIn"));
      // console.log(StateId, isAuthenticated);

      if (StateId) {
        axios
          .get(process.env.React_App_base_url + `users/` + StateId, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              Authorization: `Bearer ${isAuthenticated}`,
            },
          })
          .then((res) => {
            //console.log(res);
            setToken(res.data.data.userkey);
            //console.log(res.data);
            // let getOpt = res.data.NewData.codeOtp;
            // console.log(getOpt);
          });
      }
    }
    //console.log(ExpiredDate);
  }
  const copyToClipboard = (e) => {
    copy(e);
    if (e && e === token) {
      //console.log(e);
      setAlert(" ✔️ ");
    }
  };

  return (
    <>
      <Dasheader />
      <div className="bg-yellow-400 -mt-8">
        <div class="bg-yellow-400  mb-0  ">
          <div class="flex flex-col gap-3 w-1/2   mx-auto  ">
            <Sidebar />
            <div class="text-4xl text-center text-white mt-24 font-semibold mb-6">
              Plugin Woocommerce
            </div>

            <div class="relative border border-white rounded-lg  shadow-lg ">
              <div className="overflow-hidden bg-gray-800 text-white shadow sm:rounded-lg ">
                <div className="px-4 py-6 sm:px-6">
                  <h3 className="text-base font-semibold leading-7 text-white">
                    INSTALLATION DU PLUGIN WOOCOMMERCE
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm leading-6 p-2 text-center w-32 font-bold rounded-full bg-yellow-400  text-white">
                    🛠 Wa5sender
                  </p>
                </div>
                <div className="border-t border-gray-100">
                  <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-white">
                        Nom du Plugin
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-yellow-400 sm:col-span-2 sm:mt-0">
                        5senderWoocoomerce
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-white">Type</dt>
                      <dd className="mt-1 text-sm leading-6 text-yellow-400 sm:col-span-2 sm:mt-0">
                        Woocommerce
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-white">
                        Dépendances du plugin
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-yellow-400 sm:col-span-2 sm:mt-0">
                        Avoir générer un Token + votre clé Marchand + du crédit
                        disponible sur votre tableau de bord
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-white">
                        Disponibilté
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-yellow-400 sm:col-span-2 sm:mt-0">
                        Téléchargeable en bas de page
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-white">
                        A propos
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-yellow-400 sm:col-span-2 sm:mt-0">
                        Nous avons développer cette solution pour une
                        intégration facile dans les boutiques Woocommerce
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium leading-6 text-white">
                        Fichier
                      </dt>
                      <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        <ul
                          role="list"
                          className="divide-y divide-gray-100 rounded-md border border-gray-200"
                        >
                          <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                            <div className="flex w-0 flex-1 items-center">
                              <div
                                className="h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                <span className="truncate font-medium text-yellow-400">
                                  5senderWoocommerce.zip
                                </span>
                                <span className="flex-shrink-0 text-gray-400">
                                  6,03 Ko
                                </span>
                              </div>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <a
                                href=".././woo5Sender.zip"
                                className="font-medium text-indigo-600 hover:text-indigo-500"
                              >
                                Télécharger →
                              </a>
                            </div>
                          </li>
                          {/*  <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                            <div className="flex w-0 flex-1 items-center">
                              <div
                                className="h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                <span className="truncate font-medium">
                                  coverletter_back_end_developer.pdf
                                </span>
                                <span className="flex-shrink-0 text-gray-400">
                                  4.5mb
                                </span>
                              </div>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <a
                                href="#"
                                className="font-medium text-indigo-600 hover:text-indigo-500"
                              >
                                Download
                              </a>
                            </div>
                          </li>*/}
                        </ul>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div class="p-6">
            <div class="flex flex-wrap items-center w-full max-w-7xl p-5 mx-auto text-left border border-gray-500 rounded lg:flex-nowrap md:p-8 dark:border-gray-700">
              <div class="flex-1 w-full mb-5 ">
                <h3 class="mb-2 text-2xl font-bold text-gray-700 dark:text-gray-200">
                  Votre clé Marchand
                </h3>
                <p class="text-gray-500 dark:text-gray-400 text-justify">
                  Votre clé marchand vous permet d'accéder aux seervice de
                  5Sender. Veuillez cliquez sur le bouton noir et le copier.
                  Cette clé est personnelle et confidentiélle.
                </p>
              </div>
              <div class="w-full px-1 flex-0 md:w-auto lg:w-2/3">
                <form>
                  <div class="flex flex-col sm:flex-row text-${}">
                    <input
                      type="text"
                      id="token"
                      name="token"
                      disabled
                      value={token}
                      class="flex-1 px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md sm:mr-5 focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-800 dark:text-white dark:placeholder-gray-400 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        ShowKeyMarchad();
                      }}
                      class="inline-block mr-4 rounded bg-yellow-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#e4a11b] transition duration-150 ease-in-out hover:bg-warning-600 hover:shadow-[0_8px_9px_-4px_rgba(228,161,27,0.3),0_4px_18px_0_rgba(228,161,27,0.2)] focus:bg-warning-600 focus:shadow-[0_8px_9px_-4px_rgba(228,161,27,0.3),0_4px_18px_0_rgba(228,161,27,0.2)] focus:outline-none focus:ring-0 active:bg-warning-700 active:shadow-[0_8px_9px_-4px_rgba(228,161,27,0.3),0_4px_18px_0_rgba(228,161,27,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(228,161,27,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(228,161,27,0.2),0_4px_18px_0_rgba(228,161,27,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(228,161,27,0.2),0_4px_18px_0_rgba(228,161,27,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(228,161,27,0.2),0_4px_18px_0_rgba(228,161,27,0.1)]"
                    >
                      Afficher
                    </button>
                    <button
                      onClick={() => copyToClipboard(token)}
                      type="button"
                      class="w-full px-6 py-4 mt-5 text-white bg-gray-800 rounded-md sm:mt-0 sm:w-auto whitespace-nowrap dark:bg-gray-700"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                      >
                        <path
                          fill="currentColor"
                          fill-rule="evenodd"
                          d="M1 9.5A1.5 1.5 0 0 0 2.5 11H4v-1H2.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5V4H5.5A1.5 1.5 0 0 0 4 5.5v7A1.5 1.5 0 0 0 5.5 14h7a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 12.5 4H11V2.5A1.5 1.5 0 0 0 9.5 1h-7A1.5 1.5 0 0 0 1 2.5v7Zm4-4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-7Z"
                          clip-rule="evenodd"
                        />
                      </svg>{" "}
                    </button>
                    {alert}{" "}
                  </div>
                </form>
                <div className="text-red-700 p-2">{msg}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dashfooter />
    </>
  );
}

export default Integrationwoo;

import Dasheader from "./Dasheader";
import Dashfooter from "./Dashfooter";
import { useState, useEffect } from "react";
import Sidebar from "./Sibebar";
import {  LineChart,  ResponsiveContainer,  Legend,  Tooltip,  Line,  XAxis,  YAxis,  CartesianGrid,} from "recharts";
import axios from "axios";

function Statistiques() {
 const [msgData, setMsgData] = useState([]);
const [notifications, setNotifications] = useState([]);
 const [showModal, setShowModal] = useState(true);

 const [userKEY, setUerKEY] = useState("");
 const isAuthenticated = localStorage.getItem("uuid");
 const userID = atob(localStorage.getItem("isLoggedIn"));

  useEffect(() => {
    
    axios
      .get(process.env.React_App_base_url + `users/` + userID, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${isAuthenticated}`,
        },
      })
      .then(async (res) => {
        //console.log(res);
        const first = res.data.data.firstname;
        const last = res.data.data.lastname;
        const email = res.data.data.email;
        const keyOn = res.data.data.userkey;
        const Credisms = res.data.data.cauris;
        const FreeCauris = res.data.data.freecauris;
        setUerKEY(email);

        // console.log("key", email);
        axios
          .get(process.env.React_App_base_url + `sendersms/`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              Authorization: `Bearer ${isAuthenticated}`,
            },
          })
          .then((res) => {
            const smsData = res.data.sms;
            //  DataFilter;

            const filteredSms = smsData.filter(
              (sms) => sms["outemail"] === email || sms["senderkey"] === keyOn
            );
            setMsgData(filteredSms);

               axios
              .get(process.env.React_App_base_url + `campagnesms/`, {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Credentials": true,
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${isAuthenticated}`,
                },
              })
              .then((res) => {
                // console.log(res);
                const smsData = res.data.Campagne;
                //  DataFilter;
                const filteredSms = smsData.filter(
                  (sms) => sms["outemail"] === email
                );
                //  setMsgData(filteredSms);

                //console.log(filteredCountries, smsData);
                // console.log(filteredSms);
                  // console.log(smsData);
              });

            //console.log(filteredCountries, smsData);
            // console.log(smsData);
          

            //console.log(filteredCountries, smsData);
                // console.log(smsData);
          })
          .finally(() => {
            
          });
      });
  }, []);

  return (
    <>
      <Dasheader />
      <div className="bg-yellow-400 -mt-8">
        <div class="bg-yellow-400 h-screen p-8 mb-0  ">
          <div class="flex flex-col gap-3 w-7/12   mx-auto ">
            <Sidebar />
            <h1 class="text-4xl text-center mt-24 text-white font-semibold mb-6">
              Statistiques des envois
            </h1>
            <div class="relative border border-white bg-white rounded-lg  shadow-lg mb-64">
              <ResponsiveContainer width="130%" aspect={3} className="mx-auto">
                <LineChart data={msgData} margin={{ right: 300 }}>
                  <CartesianGrid />
                  <XAxis dataKey="" interval={"preserveStartEnd"} />
                  <YAxis></YAxis>
                  <Legend />
                  <Tooltip />
                  <Line dataKey="title" stroke="blue" activeDot={{ r: 8 }} />
                  <Line dataKey="phoneto" stroke="red" activeDot={{ r: 8 }} />
                   <Line dataKey="campagnetitle" stroke="black" activeDot={{ r: 8 }} /> 
                   <Line dataKey="programDate" stroke="indigo" activeDot={{ r: 8 }} />
                  // {/* <Line dataKey="createdDate" stroke="black" activeDot={{ r: 8 }} /> */}
                </LineChart>
                
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
      <Dashfooter />
    </>
  );
}

export default Statistiques;

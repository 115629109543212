import Footer from "./Footer";
import Header from "./Header";
import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

function ValidationCode() {
  const [formData, setFormData] = useState({ codeValide: "" });
  const { codeValide } = formData;
  const [errormsg, setErrormsg] = useState("");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const isAuthenticated = localStorage.getItem("uuid");
  const [ip, setIP] = useState("");
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { state } = useLocation();
  //console.log(state.userCall);

  let userNumber = state.userCall;
  let CodeOTP = state.userCodeOTP;
  let userloginID = state.Userid;
  let AuthRole = state.userRole;
  let EmailAuth=state.userEmail;

  //Declaring the onChange function
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  //Get location and country name
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/")
    //console.log(res.data);
    // console.log(res);
    .catch((err) => {
        // console.log(err);
        if (err.message === "Network Error" || res === "") {
          // alert("Please check your internet connection");
          return null;
        } else {
          setIP(res.data.IPv4);
          setCountry(res.data.country_name + "," + res.data.state);
        }
      });   
    // console.log(res.data);
  };

  //console.log(getData);

   useEffect(() => {
    getData();
  }, []);
  function handleClick() {
    setLoading(true);
    // Perform operation and then set loading to false
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }
  //Declaring the onSubmit function
  async function onSubmit(e) {
    e.preventDefault();
    handleClick();

    if (codeValide !== "" && codeValide === CodeOTP) {
      let TokenStatic = "HDHYI67!UY~@LJHF9H9W6L4";
      let loginToken = localStorage.getItem("uuid");

      if (loginToken) {
        let encodeTokenStatic = btoa(TokenStatic);
        localStorage.setItem("reactjs", encodeTokenStatic);
      }

      if (!loginToken) {
        navigate("../login");
      }
      if (AuthRole === "membre") {
        navigate("../dashboard/homedash", {
          state: {
            userPhone: userNumber,
            loginID: userloginID,
          },
        });
      }

      if (AuthRole === "admin") {
        navigate("../dashboard/admindash", {
          state: {
            userPhone: userNumber,
            loginID: userloginID,
          },
        });
      }

      axios
        .get(process.env.React_App_base_url + `users/` + userID, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticated}`,
          },
        })
        .then(async (res) => {
          //console.log(res);

          const email = res.data.data.email;
          const keyOn = res.data.data.userkey;
          const Credisms = res.data.data.cauris;
          const FreeCauris = res.data.data.freecauris;

          var userTrackingsData = JSON.stringify({
            trackTitle: "Connexion au compte depuis: " + country,
            trackLink: window.location.href,
            ipadress: ip,
            userKey: keyOn,
            useremail: email,
            createdDate: new Date().toLocaleString(),
          });
          // console.log(userTrackingsData);
          await axios
            .post(
              process.env.React_App_base_url + `usertrackings/`,
              userTrackingsData,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Credentials": true,
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${isAuthenticated}`,
                },
              }
            )
            .then((res) => {})
            // console.log(r
            .finally(() => {
              setLoading(false);
            });
        });
    } else {
      setErrormsg("Le code OTP de validation est invalide !");
    }

    if (formData.codeValide === "") {
      setErrormsg("Veuillez remplir le champs !");
      return;
    }
  }

  return (
    <>
      <Header />

      <div class="relative flex justify-center mt-8 items-center">
        <div class="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-purple-500"></div>
        <img
          src="https://www.svgrepo.com/show/509001/avatar-thinking-9.svg"
          class="rounded-full h-28 w-28"
        />
      </div>

      <div class="flex flex-1 flex-col mb-48 justify-center space-y-5 max-w-md mx-auto mt-8">
        <div class="flex flex-col space-y-2 text-center">
          <h2 class="text-3xl md:text-4xl font-bold">Confirmez l'OTP</h2>
          <p class="text-md md:text-xl">
            {/* Saisissez le code que nous venons de vous envoyer sur votre numéro
            WhatsApp.. */}
            Saisissez le code que nous venons de vous envoyer à l'adresse
            E-mail...Veuillez verifier aussi votre spam ✋
          </p>
          <p class="text-center p-2 font-bold text-blue-700 bg-yellow-400">
            {/* {userNumber} */}
            {EmailAuth}
            <p class="text-center p-0 text-sm italic text-red-700">
              {" "}
              {/* Veuillez patienter pour recevoir le code si votre numéro est
              correct. Si votre numéro n'est pas correct, veuillez nous
              contacter via le chat ! */}
              Veuillez patienter pour recevoir le code si votre E-mail est
              correcte. Si votre E-mail n'est pas correcte, veuillez nous
              contacter via le chat !
            </p>
          </p>
          <p class="text-center p-2 text-red-700">{errormsg} </p>
        </div>
        <div class="flex flex-col max-w-md space-y-5">
          <input
            id="codeValide"
            value={codeValide}
            onChange={onChange}
            type="number"
            placeholder="6590"
            class="flex px-3 py-2 md:px-4 md:py-3 border-2 border-black rounded-lg font-medium placeholder:font-normal"
          />
          <div class="flex items-center justify-between">
            <button
              type="submit"
              onClick={onSubmit}
              enable={loading}
              class="flex items-center justify-center mx-auto flex-none px-3 py-2 md:px-4 md:py-3 border-2 rounded-lg font-medium  hover:bg-black bg-indigo-500 text-white"
            >
              {loading ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 animate-spin mx-auto"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
              ) : (
                "Valider"
              )}
            </button>
            {/*  <button class="flex items-center justify-center flex-none px-3 py-2 md:px-4 md:py-3 border-2 rounded-lg font-medium hover:bg-indigo-500 bg-black text-white">
              Renvoyer
            </button>*/}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ValidationCode;

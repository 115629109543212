import Dasheader from "./Dasheader";
import Dashfooter from "./Dashfooter";
import Sidebar from "./Sibebar";
import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

function Panier() {
  const [showModal, setShowModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userFisrtname, setUserFisrtname] = useState("");
  const [userLastname, setUserLastname] = useState("");
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("uuid") !== null
  );
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const isAuthenticated = localStorage.getItem("uuid");

  useEffect(() => {
    axios
      .get(process.env.React_App_base_url + `users/` + userID, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${isAuthenticated}`,
        },
      })
      .then(async (res) => {
        //console.log(res);
        setUserEmail(res.data.data.email);
        setUserFisrtname(res.data.data.firstname);
        setUserLastname(res.data.data.lastname);

      });
  }, []);

  const changeHandler = (e) => {
    if (e.target.value === "Mtn Benin" || e.target.value === "Moov Benin") {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <>
      <Dasheader />
      <div className=" h-screen bg-yellow-400 -mb-80">
        <Sidebar />
        <div class=" py-8 bg-yellow-400 w-7/12 mx-auto text-gray-800">
          <div class="container mx-auto px-4">
            <h1 class="text-2xl font-semibold mb-4">Panier</h1>
            <div class="flex flex-col md:flex-row gap-4">
              <div class="md:w-3/4">
                <div class="bg-white rounded-lg shadow-md border-2 p-6 mb-4">
                  <table class="w-full">
                    <thead>
                      <tr>
                        <th class="text-left font-semibold">Produit</th>
                        <th class="text-left font-semibold">Prix</th>
                        <th class="text-left font-semibold">Quantité</th>
                        <th class="text-left font-semibold">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="py-4">
                          <div class="flex items-center">
                            <img
                              class="h-8 w-16 mr-4"
                              src=".././5Logo2.png"
                              alt="Image Produit"
                            />

                            <span class="font-semibold">
                              Assistance et Conseils Marketing
                            </span>
                          </div>
                        </td>
                        <td class="py-4">249.999FCFA HT</td>
                        <td class="py-4">
                          <div class="flex items-center">
                            <button class="border rounded-md py-2 px-4 mr-2">
                              -
                            </button>
                            <span class="text-center w-8">1</span>
                            <button class="border rounded-md py-2 px-4 ml-2">
                              +
                            </button>
                          </div>
                        </td>
                        <td class="py-4">249.999FCFA HT</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="md:w-1/4">
                <div class="bg-white rounded-lg shadow-md border-2 p-6">
                  <h2 class="text-lg font-semibold mb-4">Détails</h2>
                  <div class="flex justify-between mb-2">
                    <span>Sous-total</span>
                    <span>249.999FCFA HT</span>
                  </div>
                  <div class="flex justify-between mb-2">
                    <span>Taxes</span>
                    <span>0FCFA HT</span>
                  </div>
                  <div class="flex justify-between mb-2">
                    <span>Livraison</span>
                    <span>0FCFA HT</span>
                  </div>
                  <hr class="my-2" />
                  <div class="flex justify-between mb-2">
                    <span class="font-semibold">Total</span>
                    <span class="font-semibold">249.999FCFA HT</span>
                  </div>
                  <button
                    onClick={() => setShowModal(true)}
                    class="bg-blue-500 text-white py-2 px-4 rounded-lg mt-4 w-full"
                  >
                    Valider
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl text-gray-700 mx-auto ">
                      PAIEMENT DE LA COMMANDE
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <div class="w-full max-w-lg mx-auto p-8">
                      <div class="bg-white rounded-lg shadow-lg p-6 border-2">
                        <h2 class="text-lg font-medium text-gray-700 mb-6">
                          Informations du client
                        </h2>
                        <form>
                          <p class="w-48 p-4 bg-black text-center hover:bg-black text-white font-medium py-3 rounded-lg focus:outline-none">
                            249.999FCFA HT
                          </p>
                          <div class="grid grid-cols-2 gap-6 mt-4">
                            <div class="col-span-2 sm:col-span-1">
                              <label
                                for="card-number"
                                class="block text-sm font-medium text-gray-700 mb-2"
                              >
                                Nom{" "}
                              </label>
                              <input
                                type="text"
                                name="lastname"
                                id="lastname"
                                value={userLastname}
                                placeholder="ZORO"
                                class="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                              />
                            </div>
                            <div class="col-span-2 sm:col-span-1">
                              <label
                                for="expiration-date"
                                class="block text-sm font-medium text-gray-700 mb-2"
                              >
                                Prénom(s)
                              </label>
                              <input
                                type="text"
                                name="firsttname"
                                value={userFisrtname}
                                id="firsttname"
                                placeholder="Andrey "
                                class="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                              />
                            </div>
                            <div class="col-span-2 sm:col-span-1">
                              <label
                                for="cvv"
                                class="block text-sm font-medium text-gray-700 mb-2"
                              >
                                E-mail
                              </label>
                              <input
                                type="email"
                                name="email"
                                id="email"
                                value={userEmail}
                                placeholder="exemple@exemple.com"
                                class="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                              />
                            </div>
                            <div class="col-span-2 sm:col-span-1">
                              <label
                                for="card-holder"
                                class="block text-sm font-medium text-gray-700 mb-2"
                              >
                                Moyen de paiement
                              </label>
                              <select
                                name="select"
                                id="select"
                                onChange={changeHandler}
                                class="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                              >
                                <option selected>
                                  Sélectionnez le moyen de paiement
                                </option>
                                <option value="Mtn Benin">Mtn Bénin</option>
                                <option value="Moov Benin">Moov Bénin</option>
                                <option value="Visa Carte">Carte Visa</option>
                              </select>
                            </div>
                          </div>
                          {isVisible ? (
                            <div id="box" class="col-span-2 mt-2 sm:col-span-1">
                              <label
                                for="card-holder"
                                class="block text-sm font-medium text-gray-700 mb-2"
                              >
                                Téléphone whatSapp
                              </label>
                              <input
                                type="number"
                                name="number"
                                id="number"
                                placeholder="+22801020304"
                                class="w-full py-3 px-4 border border-gray-400 rounded-lg focus:outline-none focus:border-blue-500"
                              />
                            </div>
                          ) : null}
                          <div class="mt-8">
                            <button
                              type="submit"
                              class="w-full bg-indigo-500 hover:bg-black text-white font-medium py-3 rounded-lg focus:outline-none"
                            >
                              Payer
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Fermer
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </div>
      <Dashfooter />
    </>
  );
}

export default Panier;

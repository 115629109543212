import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import LoginEmail from "./LoginEmail";


function Login() {
  const [loading, setLoading] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  function togglePasswordVisibility() {
    setIsPasswordVisible((password) => !password);
  }
  //Validation Email
  var mailformat =
    /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  // /\S+@\S+\.\S+/
  function isValidEmail(email) {
    return mailformat.test(email);
  }

  function validateForm() {
    setLoading(true);
    // Perform operation and then set loading to false
    setTimeout(() => {
      setLoading(false);
    }, 500);

    if (email === "" || password === "") {
      setErrormsg("Veuillez remplir tous les champs ! *");
    }

    if (!isValidEmail(email)) {
      setErrormsg("Attention, Votre adresse E-mail est invalide !");
      //console.log(isValidEmail(email));
    }

    if (password === "") {
      setErrormsg("Veuillez votre mot de passe *");
    }

    var Emailconfirm = isValidEmail(email);

    if ((email !== "" && password !== "") || Emailconfirm !== false) {
      // const axios = require("axios");
      setLoading(true);

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email: email,
        password: password,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(process.env.React_App_base_url + "auth/login", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          // console.log(result);
          let resdata = JSON.parse(result);
          let resp = resdata["access_token"];
          let userPhone = resdata["userinfos"].phone;
          let userFistname = resdata["userinfos"].firstname;
          let userId = resdata["userinfos"].id;
          let role = resdata["userinfos"].role;
          let userAcive = resdata["userinfos"].isActive;

          //console.log((resp));

          if (resp && userAcive === true) {
            const timestamp = Date.now();
            var updatedDate = new Intl.DateTimeFormat("fr-FR", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }).format(timestamp);

            localStorage.setItem("uuid", resp);

            let isLoginID = btoa(userId);
            localStorage.setItem("isLoggedIn", isLoginID);

            // console.log(userPhone);
            // console.log(userFistname);
            //OTP code générator
            const codeValidator = Math.random().toString().slice(-5);

            var userlog = JSON.stringify({
              codeOtp: codeValidator,
              updatedDate: updatedDate,
            });

            axios
              .patch(
                process.env.React_App_base_url + `users/` + userId,
                userlog,
                {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": true,
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${resp}`,
                  },
                }
              )
              .then((res) => {
                // console.log(res);
                //console.log(res.data);
                // let getOpt = res.data.NewData.codeOtp;
                // console.log(getOpt);
              });

              // Send Mail white code
                const objet=" Votre code de validation de connexion ";
                const message ="Votre code de validation est : " + " " + codeValidator;
              

              const CodeMail = LoginEmail(email, objet,message);
             if(CodeMail){
               navigate("../validationcode", {
                 state: {
                   userCall: userPhone,
                   userCodeOTP: codeValidator,
                   Userid: userId,
                   userRole: role,
                   userEmail: email,
                 },
               });
               // Send Mail white code
             } 
            // console.log(codeValidator);
            //const uri_whatsapp = process.env.React_App_WhatsApp_uri;
            //console.log(uri_whatsapp);
            let data = JSON.stringify({
              messaging_product: "whatsapp",
              recipient_type: "individual",
              to: userPhone,
              type: "template",
              template: {
                name: process.env.React_App_wa_templ_id,
                language: {
                  code: "fr",
                },
                components: [
                  {
                    type: "header",
                  },
                  {
                    type: "body",
                    parameters: [
                      {
                        type: "text",
                        text: codeValidator,
                      },
                    ],
                  },
                  {
                    type: "button",
                    sub_type: "url",
                    index: "0",
                    parameters: [
                      {
                        type: "TEXT",
                        text: codeValidator,
                      },
                    ],
                  },
                ],
              },
            });

            var config = {
              method: "post",
              url: process.env.React_App_wa_url,
              headers: {
                "Authorization": `Bearer ${process.env.React_App_wa_token}`,
                "Content-Type": "application/json",
              },
              data: data,
            };

            axios(config)
              .then(function (response) {
                //console.log(JSON.stringify(response.data));
                const res = response.statusText;
                // console.log(res);
                navigate("../validationcode", {
                  state: {
                    userCall: userPhone,
                    userCodeOTP: codeValidator,
                    Userid: userId,
                    userRole: role,
                    userEmail: email
                  },
                });
              })
              .catch(function (error) {
                // console.log(error);
              });

            //SEND WHATSAPP MSG
          }
          if (resp && userAcive === false) {
            setErrormsg(
              "Désolé 👽, votre compte est bloqué. Veuillez contacter le service technique au support@5sender.com !"
            );
          }
          if (!resp) {
            setErrormsg(
              "Attention 🧐, aucun compte n'est enrégistré avec cette addresse E-mail !"
            );
          }
        })
        .catch((error) => {
          if (error) {
            setErrormsg("Désolé, vos identifiants ne sont pas corrects 👀 !");
          }
          // console.log("error", error);
        });
    }
  }

  return (
    <>
      <Header />

      <div class="py-16 bg-yellow-400 -mb-16">
        <div class="flex bg-white rounded-lg shadow-lg overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
          <div className="relative hidden w-0 flex-1 lg:block">
            <img
              className=" inset-0 h-full w-full object-cover"
              src=".././tourismebj.jpg"
              alt=""
            />
          </div>
          <div class="w-full p-8 lg:w-1/2">
            <h2 class="text-2xl font-semibold text-gray-700 text-center">
              Se connecter
            </h2>
            <p class="text-xl text-gray-600 text-center">
              Bienvenue à nouveau !
            </p>
            <a
              href="#"
              class="flex items-center justify-center mt-4 text-white rounded-lg shadow-md hover:bg-gray-100"
            >
              <div class="px-4 py-3">
                <svg class="h-6 w-6" viewBox="0 0 40 40">
                  <path
                    d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.045 27.2142 24.3525 30 20 30C14.4775 30 10 25.5225 10 20C10 14.4775 14.4775 9.99999 20 9.99999C22.5492 9.99999 24.8683 10.9617 26.6342 12.5325L31.3483 7.81833C28.3717 5.04416 24.39 3.33333 20 3.33333C10.7958 3.33333 3.33335 10.7958 3.33335 20C3.33335 29.2042 10.7958 36.6667 20 36.6667C29.2042 36.6667 36.6667 29.2042 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z"
                    fill="#FFC107"
                  />
                  <path
                    d="M5.25497 12.2425L10.7308 16.2583C12.2125 12.59 15.8008 9.99999 20 9.99999C22.5491 9.99999 24.8683 10.9617 26.6341 12.5325L31.3483 7.81833C28.3716 5.04416 24.39 3.33333 20 3.33333C13.5983 3.33333 8.04663 6.94749 5.25497 12.2425Z"
                    fill="#FF3D00"
                  />
                  <path
                    d="M20 36.6667C24.305 36.6667 28.2167 35.0192 31.1742 32.34L26.0159 27.975C24.3425 29.2425 22.2625 30 20 30C15.665 30 11.9842 27.2359 10.5975 23.3784L5.16254 27.5659C7.92087 32.9634 13.5225 36.6667 20 36.6667Z"
                    fill="#4CAF50"
                  />
                  <path
                    d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.7592 25.1975 27.56 26.805 26.0133 27.9758C26.0142 27.975 26.015 27.975 26.0158 27.9742L31.1742 32.3392C30.8092 32.6708 36.6667 28.3333 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z"
                    fill="#1976D2"
                  />
                </svg>
              </div>
              <h1 class="px-4 py-3 w-5/6 text-center text-gray-600 font-bold">
                Connexion avec Google
              </h1>
            </a>
            <div class="mt-4 flex items-center justify-between">
              <span class="border-b w-1/5 lg:w-1/4"></span>
              <a href="#" class="text-xs text-center text-gray-500 uppercase">
                ou se connecter avec l'email
              </a>
              <span class="border-b w-1/5 lg:w-1/4"></span>
            </div>
            <p class="text-center p-2 -mb-4 text-red-700">{errormsg} </p>
            <div class="mt-4">
              <label class="block text-gray-700 text-sm font-bold mb-2">
                Email Addresse
              </label>
              <input
                class="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div class="mt-4">
              <div class="flex justify-between">
                <label class="block text-gray-700 text-sm font-bold mb-2">
                  Mot de Passe
                </label>
                <a href="./passrecovery" class="text-xs text-gray-500">
                  Mot de passe oublier ?
                </a>
              </div>
              <span class="absolute  mt-4 ml-80 cursor-pointer">
                <img
                  onClick={togglePasswordVisibility}
                  src="https://tuk-cdn.s3.amazonaws.com/can-uploader/sign_in-svg5.svg"
                  alt="viewport"
                />
              </span>
              <input
                class="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                type={isPasswordVisible ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center mt-4">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  // checked="checked"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-3 block text-sm leading-6 text-gray-700"
                >
                  Restez connecté
                </label>
              </div>
            </div>
            <div class="mt-8">
              <button
                enable={loading}
                onClick={() => {
                  validateForm();
                }}
                class="bg-blue-500 text-white font-bold py-2 px-4 w-full rounded hover:bg-yellow-400"
              >
                {loading ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 animate-spin mx-auto"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                ) : (
                  "Valider"
                )}
              </button>
            </div>
            <div className="relative flex justify-center text-sm mt-4 font-medium leading-6">
              <span className="bg-white px-6 text-gray-900">
                Avez-vous un compte déjà ? Non,{" "}
                <a
                  href="./signup"
                  className="font-semibold text-blue-500 hover:text-yellow-400"
                >
                  S'inscrire
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Login;

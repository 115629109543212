import Dasheader from "./Dasheader";
import Dashfooter from "./Dashfooter";
import Sidebar from "./Sibebar";
import axios from "axios";
import { useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import { Link, useNavigate, useLocation } from "react-router-dom";


function Utilisateurs() {
  const [userKEY, setUerKEY] = useState("");
  const [title, setTitle] = useState("");
  const [fullname, setFullname] = useState("");
  const [price, setPrice] = useState("");
  const [phone, setPhone] = useState("");
  const [alert, setAlert] = useState("");
  const [msg, setMsg] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState("");
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));
const navigate = useNavigate();

 useEffect(() => {
   setTimeout(() => {
     if (isAuthenticated) {
       let StateId = atob(localStorage.getItem("isLoggedIn"));
       // console.log(StateId, isAuthenticated);

       if (StateId) {
         axios
           .get(process.env.React_App_base_url + `users/` + StateId, {
             headers: {
               "Access-Control-Allow-Origin": "*",
               "Access-Control-Allow-Credentials": true,
               "Content-Type": "application/json",
               Authorization: `Bearer ${isAuthenticated}`,
             },
           })
           .then((res) => {
             // console.log(res);
             setTimeout(() => {
               if (res.data.data.role !== "admin") {
                 navigate("../login");
               }
             }, 1000);

             let ActivationCompte = res.data.data.isActive;

             if (ActivationCompte !== true) {
               navigate(".././login");
             }

             //console.log(res.data);
             // let getOpt = res.data.NewData.codeOtp;
             // console.log(getOpt);
           });
       }
     }
   }, 1000);
 }, []);

  useEffect(() => {    
    axios
      .get(process.env.React_App_base_url + `users/`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${isAuthenticated}`,
        },
      })
      .then((res) => {
        //  console.log(res.data.users);
        const UtData = res.data.users;

        setUserData(res.data.users);
      });
  }, []);

   const copyToClipboard = (e) => {
     copy(e);
     if (e && e === link) {
       //console.log(e);
       setAlert(" ✔️ ");
     }
   };

  function handleOnclik(){
      setLoading(true);
      // Perform operation and then set loading to false
      setTimeout(() => {
        setLoading(false);
      }, 500);

 console.log(title, fullname, price, phone);
 if (title !== "" && fullname !== "" && price !== "" && phone !== "") {
   axios
     .get(process.env.React_App_base_url + `users/` + userID, {
       headers: {
         "Access-Control-Allow-Origin": "*",
         "Access-Control-Allow-Credentials": true,
         "Content-Type": "application/json",
         Authorization: `Bearer ${isAuthenticated}`,
       },
     })
     .then(async (res) => {
       //console.log(res);
       const first = res.data.data.firstname;
       const last = res.data.data.lastname;
       const email = res.data.data.email;
       const phone = res.data.data.phone;
       const keyOn = res.data.data.userkey;
       const Credisms = res.data.data.cauris;

       //console.log(first, last, keyOn);
       if (keyOn) {
         var paymentData = JSON.stringify({
           firstname: "Client :",
           lastname: fullname,
           useremail: email,
           userkey: keyOn,
           userphone: phone,
           productname: title,
           montant: price,
           userRef: "5Sender-" + Math.random().toString().slice(-5),
           sponkey: keyOn,
           paymentStatus: "PENDING",
           paymentMode: "Visa Carte/Mobile Money",
           createdDte: new Date().toLocaleString(),
         });
         //console.log(paymentData);

         await axios
           .post(
             process.env.React_App_base_url + `payments/visacard`,
             paymentData,
             {
               headers: {
                 "Access-Control-Allow-Origin": "*",
                 "Access-Control-Allow-Credentials": true,
                 "Content-Type": "application/json",
                 Authorization: `Bearer ${isAuthenticated}`,
               },
             }
           )
           .then((res) => {
            //  console.log(res);
             let respData = res.data.message;
             let PaymentUrl = res.data.paymentRediref.url;
             // const NewData = JSON.parse(res.config.data);
             if (respData) {
               setMsg("Votre lien de paiement a été générer avec succès ⛓ !");
               
               setLink(PaymentUrl);
               
             }

             if (!respData) {
               setErrormsg(
                 "Veuillez remplir tous les champs et réessayez !"
               );
               //navigate("./dashboard/updateplan");
               return null;
             }
           });
       }
     });
 }

  }

  return (
    <>
      <Dasheader />
      <div class="p-4 bg-yellow-400  text-white -mt-8">
        {/* <Sidebar /> */}
        <h1 class="flex text-4xl mt-24 mx-auto justify-center text-center font-semibold mb-2">
          RECEVOIR UN PAIEMENT VIA UN LIEN{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 256 256"
          >
            <path
              fill="white"
              d="M136.37 187.53a12 12 0 0 1 0 17l-5.94 5.94a60 60 0 0 1-84.88-84.88l24.12-24.11A60 60 0 0 1 152 99a12 12 0 1 1-16 18a36 36 0 0 0-49.37 1.47l-24.1 24.08a36 36 0 0 0 50.92 50.92l5.94-5.94a12 12 0 0 1 16.98 0Zm74.08-142a60.09 60.09 0 0 0-84.88 0l-5.94 5.94a12 12 0 0 0 17 17l5.94-5.94a36 36 0 0 1 50.92 50.92l-24.11 24.12A36 36 0 0 1 120 139a12 12 0 1 0-16 18a60 60 0 0 0 82.3-2.43l24.12-24.11a60.09 60.09 0 0 0 .03-84.91Z"
            />
          </svg>
        </h1>

        <div class="bg-yellow-400 -mt-24 min-h-screen text-gray-800 flex items-center">
          <div class="bg-white p-10 md:w-2/3 lg:w-1/2 mx-auto rounded">
            <div className="mt-4 sm:ml-16 sm:mt-0 float-right sm:flex-none">
              <a
                href="./admindash"
                type="button"
                className="block rounded-md bg-indigo-500 px-3 py-2 text-center text-sm font-semibold text-white hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                ↲ Retour
              </a>
            </div>
            <div>
              <div class="flex items-center mb-5  mt-24">
                <label
                  for="title"
                  class="w-20 inline-block text-right mr-4 text-gray-500 text-gray-500"
                >
                  Titre:
                </label>
                <input
                  name="title"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="2000 crédits sms"
                  class="border-b-2 border-gray-400 flex-1 py-2 placeholder-gray-300 outline-none focus:border-green-400"
                />
              </div>
              <div class="flex items-center mb-5 ">
                <label
                  for="fullname"
                  class="w-20 inline-block text-right mr-4 text-gray-500 text-gray-500"
                >
                  Nom et prénoms
                </label>
                <input
                  name="fullname"
                  id="fullname"
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                  placeholder="Kelly SORIGI"
                  class="border-b-2 border-gray-400 flex-1 py-2 placeholder-gray-300 outline-none focus:border-green-400"
                />
              </div>
              <div class="flex items-center mb-5 ">
                <label
                  for="phone"
                  class="w-20 inline-block text-right mr-4 text-gray-500 text-gray-500"
                >
                  Tél.:
                </label>
                <input
                  name="phone"
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  class="border-b-2 border-gray-400 flex-1 py-2 placeholder-gray-300 outline-none focus:border-green-400"
                />
              </div>

              <div class="flex items-center mb-10">
                <label
                  for="price"
                  class="w-20 inline-block text-right mr-4 text-gray-500 text-gray-500"
                >
                  Montant
                </label>
                <input
                  type="number"
                  name="price"
                  id="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  class="border-b-2 border-gray-400 flex-1 py-2 placeholder-gray-300 outline-none focus:border-green-400"
                />
              </div>
              <div class="text-right">
                <button
                  type="button"
                  enable={loading}
                  onClick={() => {
                    handleOnclik();
                  }}
                  class="py-3 px-8 bg-blue-400 text-green-100 font-bold rounded"
                >
                  {loading ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 animate-spin mx-auto"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                      />
                    </svg>
                  ) : (
                    "Générer le lien"
                  )}
                </button>
              </div>
              <div className="text-green-500 text-2xl p-2">{msg}</div>
              <div className="text-red-700 text-center text-2xl  mx-auto p-2">
                {errormsg}
              </div>
              <div class="mx-auto mt-32 max-w-7xl px-6 sm:mt-8 lg:px-8"></div>
            </div>
          </div>
          <div class="relative isolate overflow-hidden bg-gray-900 px-6 shadow-2xl rounded-2xl sm:rounded-3xl sm:px-24 xl:py-4">
            <h2 class="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
              LIEN DE PAIEMENT
            </h2>
            <p class="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-300">
              Recupérer le lien de paiement et partager avec le client
            </p>
            <div class="mx-auto mt-10 flex max-w-md gap-x-4">
              <label for="email-address" class="sr-only">
                Simple et sécurisé
              </label>
              {alert}{" "}
              <input
                id="link"
                name="link"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                disabled
                class="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                // placeholder="https://www.google.com/"
              />
              <button
                onClick={() => copyToClipboard(link)}
                type="button"
                class="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Copier
              </button>
            </div>

            <svg
              viewBox="0 0 1024 1024"
              class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
              aria-hidden="true"
            >
              <circle
                cx="512"
                cy="512"
                r="512"
                fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                fill-opacity="0.7"
              ></circle>
              <defs>
                <radialGradient
                  id="759c1415-0410-454c-8f7c-9a820de03641"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(512 512) rotate(90) scale(512)"
                >
                  <stop stop-color="#7775D6"></stop>
                  <stop offset="1" stop-color="#7ED321" stop-opacity="0"></stop>
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>

      <Dashfooter />
    </>
  );
}

export default Utilisateurs;

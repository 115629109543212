
import Header from "./Header";
import Footer from "./Footer";

function Documentation() {

  return (
    <>
      <Header />
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-20 -mt-16 pb-16 text-center lg:pt-32">
        <p class="mx-auto -mt-4 max-w-2xl text-lg tracking-tight text-slate-700 sm:mt-6">
          <span class="border-b border-dotted text-yellow-400 mr-2 font-bold border-slate-300">
            #FiveSENDER
          </span>
          <span class="border-b border-dotted border-slate-300">
            INSTALLATION
          </span>
        </p>

        <h1 class="mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-7xl">
          <span class="inline-block">
            Suivez le
            <span class="relative whitespace-nowrap text-blue-600">
              <svg
                aria-hidden="true"
                viewBox="0 0 418 42"
                class="absolute top-2/3 left-0 h-[0.58em] w-full fill-blue-300/70"
                preserveAspectRatio="none"
              >
                <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
              </svg>
              <span class="relative">Process</span>
            </span>
          </span>
          <span class="inline-block">d'Intégration </span>
        </h1>

        <p class="mx-auto mt-9 max-w-2xl text-lg tracking-tight text-slate-700 sm:mt-6">
          <span class="inline-block">
            Rassurez vous que Woocommerce est installé et
          </span>
          <span class="inline-block">
            d'avoir téléchargé le Plugin dans votre tableau de bord
          </span>
        </p>

        <div class="mt-12 flex flex-col justify-center gap-y-5 sm:mt-10 sm:flex-row sm:gap-y-0 sm:gap-x-6">
          <a
            class="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900 animate-fade-in-left"
            href="#"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g
                id="feWordpressAlt0"
                fill="none"
                fill-rule="evenodd"
                stroke="none"
                stroke-width="1"
              >
                <g id="feWordpressAlt1" fill="white">
                  <path
                    id="feWordpressAlt2"
                    d="M19.89 7.686A8.947 8.947 0 0 1 20.99 12a8.986 8.986 0 0 1-4.47 7.77l2.746-7.939c.513-1.282.684-2.308.684-3.22c0-.331-.022-.638-.06-.925Zm-6.651.098c.541-.028 1.029-.085 1.029-.085c.484-.057.427-.77-.057-.741c0 0-1.457.114-2.397.114c-.883 0-2.368-.114-2.368-.114c-.485-.028-.541.712-.057.74c0 0 .459.058.943.086l1.401 3.838l-1.968 5.901l-3.274-9.739c.542-.028 1.03-.085 1.03-.085c.483-.057.426-.77-.058-.741c0 0-1.456.114-2.396.114c-.17 0-.368-.004-.58-.01A8.98 8.98 0 0 1 12 3.008c2.34 0 4.472.895 6.071 2.36c-.039-.002-.076-.007-.116-.007c-.884 0-1.51.77-1.51 1.596c0 .74.427 1.368.883 2.109c.342.598.741 1.368.741 2.48c0 .769-.296 1.662-.684 2.906l-.897 2.996l-3.25-9.665Zm-1.24 13.207a9.03 9.03 0 0 1-2.54-.366l2.699-7.839l2.763 7.571c.018.045.04.086.064.124a8.972 8.972 0 0 1-2.985.51ZM3.01 12c0-1.304.28-2.541.779-3.66l4.288 11.751A8.991 8.991 0 0 1 3.01 12ZM12 2C6.487 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2Z"
                  />
                </g>
              </g>
            </svg>
            <span class="ml-3">Wordpress</span>
          </a>
          <div
            class="relative flex flex-1 flex-col items-stretch sm:flex-none"
            data-headlessui-state=""
          >
            <button
              class="group inline-flex ring-1 items-center justify-center rounded-full py-2 px-4 text-sm focus:outline-none ring-slate-200 text-slate-700 hover:text-slate-900 hover:ring-slate-300 active:bg-slate-100 active:text-slate-600 focus-visible:outline-blue-600 focus-visible:ring-slate-300 animate-fade-in-right"
              id="headlessui-menu-button-:r4:"
              aria-haspopup="true"
              aria-expanded="false"
              data-headlessui-state=""
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 128 128"
              >
                <path
                  fill="#7f54b3"
                  d="M116.3 89.1H11.7C5.2 89.1 0 83.9 0 77.4v-40C0 31 5.2 25.8 11.7 25.8h104.7c6.4 0 11.7 5.2 11.7 11.7v40c-.1 6.4-5.3 11.6-11.8 11.6z"
                />
                <path
                  fill="#FFF"
                  d="M13.8 76.7s2.8 11.8 8.5 3.9s11.2-20.3 11.2-20.3s.4-3.1 2 3.7S44 80 44 80s6.3 7.9 8.9-.4c-1-11 2.8-31 6.7-40.6c1.6-8.5-7.3-6.1-8.1-4.1s-6.3 14.8-6.7 28.2c0 0-4.7-12.8-5.1-17.4c-.4-4.7-5.3-5.9-8.1-1.4S20.3 66.2 20.3 66.2l-5.5-28.4s-5.5-7.3-8.7 1.6c0 0 5.7 34.9 7.7 37.3zm73.2-31c-8.5-14.2-21.1-3.4-21.1-3.4s-9.6 11.1-5.3 26.2c6.9 14.9 16.6 8.3 19.2 7.1c2.7-1.3 14.1-14.3 7.2-29.9zm-6.5 12.5c0 5.9-4.9 11.4-8.9 10.2c-2.2-1.3-3.6-4.8-3.6-10.8c2-9.7 6.4-11 8.7-10.8c4.3 2.3 4.1 7.4 3.8 11.4zm38.4-12.5c-8.5-14.2-21.1-3.4-21.1-3.4s-9.6 11.1-5.3 26.2c6.9 14.9 16.6 8.3 19.2 7.1c2.6-1.3 14.1-14.3 7.2-29.9zm-6.5 12.5c0 5.9-4.9 11.4-8.9 10.2c-2.2-1.3-3.6-4.8-3.6-10.8c2-9.7 6.4-11 8.7-10.8c4.2 2.3 4 7.4 3.8 11.4z"
                />
                <path
                  fill="#7f54b3"
                  d="m61.3 89.1l22.3 13.1l-4.7-13.1l-12.8-3.6z"
                />
              </svg>
              <span class="ml-3">Woocommerce</span>
            </button>
          </div>
        </div>
      </div>
      <div class="flex justify-center mb-4">
        <div class="rounded-lg shadow-lg bg-white max-w-1/2">
          <a href="#!">
            <video
              width="1000"
              height="540"
              controls
              class="items-center text-center mx-auto rounded-t-lg"
            >
              <source src=".././Intégration_5sender_Woo.mp4" ype="video/mp4" />
              <source src="movie.ogg" type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          </a>
          <div class="p-6">
            <h5 class="text-gray-900 text-xl font-medium mb-2 text-center">
              Documentation d'installation
            </h5>
            <p class="text-gray-700 text-base mb-4">
              Veuillez suivre les étapes décrites dans la video pour faire une
              intégration parfaite du plugin. En cas de difficultés, contacter
              nous pour vous accompagner.
            </p>
            <a
              href="https://wa.me/22943747378"
              target="_blank"
              class=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out hover:bg-yellow-400"
            >
              Besoin d'aide ? →
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Documentation;

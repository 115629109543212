import Dasheader from "./Dasheader";
import Dashfooter from "./Dashfooter";
import Sidebar from "./Sibebar";
import axios from "axios";
import { useEffect, useState } from "react";

function Activityhistory() {
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const [trackData, setTrackData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [totalConnexion, setTotalConnexion] = useState("");

  useEffect(() => {
    setLoading(true);
    axios
      .get(process.env.React_App_base_url + `users/` + userID, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${isAuthenticated}`,
        },
      })
      .then(async (res) => {
        const email = res.data.data.email;
        const keyOn = res.data.data.userkey;

        // console.log("key", email);
        axios
          .get(process.env.React_App_base_url + `usertrackings/`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              Authorization: `Bearer ${isAuthenticated}`,
            },
          })
          .then((res) => {
            // console.log(res );
            const trackData = res.data.usertrack;
            //  DataFilter;

            const filteredTrack = trackData
              .filter((track) => track["useremail"] === email)
              .sort();

            setTrackData(filteredTrack);
            let userTrack = filteredTrack.length;

            if (filteredTrack.length <= 9) {
              setTotalConnexion("0" + userTrack);
            }
            if (filteredTrack.length > 9) {
              setTotalConnexion(userTrack);
            }

            //  console.log(trackData);
            //  console.log(filtered);
          })
          .finally(() => {
            setLoading(false);
          });
      });
  }, []);

  return (
    <>
      <Dasheader />

      <div className="bg-yellow-400  pt-2 -mt-8  ">
        <Sidebar />
        <div class="p-4 mt-24 mb-80">
          <h1 class="text-4xl text-center text-white font-semibold  ">
            Historiques de connexion
          </h1>
          <div class="container ">
            <div class=" md:grid grid-cols-12 text-gray-50 mb-64">
              <div class="md:contents">
                <div class="col-start-2 col-end-4 mr-10 md:mx-auto relative">
                  <div class="h-full w-6 flex items-center justify-center">
                    <div class="h-full w-1 bg-indigo-500 pointer-events-none"></div>
                  </div>
                  <div class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-indigo-500 shadow text-center">
                    <i class="fas fa-check-circle text-white">
                      {totalConnexion}
                    </i>
                  </div>
                </div>
                {trackData
                  .slice(0, limit ? limit : trackData.length)
                  .map((track) => (
                    <ol
                      key={track.id}
                      class="bg-indigo-500 col-start-4 col-end-12 p-4 rounded-xl my-4 mr-auto shadow-md w-full"
                    >
                      <h3 class="font-semibold text-lg mb-1">
                        {track.trackTitle}
                      </h3>
                      <p class="leading-tight text-justify w-full">
                        {track.trackLink}
                      </p>
                      <p class="leading-tight text-justify w-full">
                        {track.createdDate}
                      </p>
                    </ol>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <Dashfooter />
      </div>
    </>
  );
}

export default Activityhistory;
